import React from "react";


import { Card, Typography } from "antd";
import Icon from "@ant-design/icons";
import config from "../../config";

import CourseIcon from "../antDesignCourseIcon/CourseIcon";
import tech_learning_group from "../../assets/icons/tech_learning_group.svg";
import { ReactComponent as add_course } from "../../assets/icons/add_course.svg";
import { ReactComponent as select_course } from "../../assets/icons/selected.svg";

import { ReactComponent as add_course_orange } from "../../assets/icons/add_course_orange.svg";
import { ReactComponent as select_course_orange } from "../../assets/icons/selected_orange.svg";

import TimeFormatter from "../../utils/timeFormatter";
import { getEllipsis } from "../../utils/stringFormatter";

import style from "./CourseCard.module.css";
import "./CourseCard.css";

const INSTRUCTOR_LED = config.INSTRUCTOR_LED;

function CourseCard({
    dataElement,
    isChecked,
    cardClicked,
    courseType,
}) {
    let {
        name,
        course_name: courseName,
        private: isPrivate,
        courseProvider,
        courseProviderDetails,
        duration,
        language,
        languages,
        course_provider: iLcourseProvider,
        isSearchResultPage,
    } = dataElement || {};



    let languagesSearchResult = isSearchResultPage
        ? languages?.map((language) => language.name)
        : [];

    let ElLanguages = "";
    if (isSearchResultPage) {
        ElLanguages =
            languagesSearchResult &&
            getEllipsis(languagesSearchResult?.join(","), 20);
    } else {
        ElLanguages = languages && getEllipsis(languages?.join(","), 20);
    }


    const handleCardClick = (e) => {
        e.preventDefault();
        cardClicked(dataElement);
        return;
    };
    function TeamTag({ team }) {
        return (
            <div>
                <img width="16px" height="16px" src={tech_learning_group}></img>
                <span style={{ marginLeft: "8px" }}>{team}</span>
            </div>
        );
    }
    function CourseChecked({ checked, color }) {
        return (
            <div>
                <Icon component={
                    color === "BLUE" ? checked ? select_course : add_course : checked
                        ? select_course_orange : add_course_orange}
                    style={{ backgroundColor: color === "ORANGE" && "transparent" }} />
            </div>
        );
    }
    const RenderCourseChecked = ({ isChecked }) => {
        return <CourseChecked
            className={style.courseCheckIcon}
            checked={isChecked}
            color={isPrivate ? "ORANGE" : "BLUE"}
        ></CourseChecked>
    };

    return (
        <Card
            hoverable
            cover={
                <img
                    src={!dataElement.image
                        ? !dataElement.imageUrl
                            ? "/default.png"
                            : dataElement.imageUrl
                        : dataElement.image}
                    className={style.courseCardImage}
                    style={{
                        border: isChecked ? "2px solid #00a0f0" : "",
                        borderBottom: "",
                    }}
                    alt="/img"
                ></img>
            }
            className={style.courseCard}
            onClick={handleCardClick}
            style={{
                border: isChecked ? "2px solid #00a0f0" : "",
            }}
        >
            <div className={`${style.courseCardCover}`}>
                <CourseIcon courseType={courseType} color={"white"} />
            </div>
            <Typography.Paragraph
                className={style.courseTitle}
                ellipsis={{ rows: 2 }}
            >
                {name || courseName}
            </Typography.Paragraph>
            <div className={style.courseCardContent}>
                <div className={style.courseDetailsFlex}>
                    <div className={style.courseDetails}>
                        {courseType == INSTRUCTOR_LED ?
                            <div className={style.courseDetail}>
                                <div>
                                    {duration &&
                                        TimeFormatter.decimalHrsToHrsMin(
                                            duration,
                                            "detailed"
                                        )}{" "}
                                    / {language}
                                </div>
                            </div>
                            :
                            <div className={style.courseDetail}>
                                <div>
                                    {duration}hrs / {ElLanguages}{" "}
                                </div>
                            </div>
                        }
                        <div>
                            <TeamTag
                                team={
                                    courseType == INSTRUCTOR_LED
                                        ? courseProvider?.name || iLcourseProvider
                                        : courseProviderDetails?.CourseProviderName ||
                                        courseProvider
                                }
                            ></TeamTag>
                        </div>
                    </div>
                </div>
                <div className={style.courseCheckIcon}>
                    <RenderCourseChecked isChecked={isChecked} />
                </div>
            </div>
        </Card>
    );
}

export default CourseCard;
