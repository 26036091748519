import { useEffect, useState, useContext } from "react";
import axios from 'axios';
import { UserContext } from "../../../context/UserContext";
import config from "../../../config";

const useThirdPartyApiKeyManagement = () => {

    const { tenantID } = useContext(UserContext);
    const [udemyApiKeys, setUdemyApiKeys] = useState({ accountId: "", accountName: "", enabled: false, clientId: "", clientSecret: "" })
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState("");
    const updateApiKeys = async () => {

        try {
            const response = await axios.put(`/api/tenants/${tenantID}/apiKeys/udemy`, { ...udemyApiKeys }, {
                baseURL: config.endPoint.configManager,
            });

            if (response.data) {
                setAlertType("success")
                setAlert("Update successful")
            }

        } catch (err) {
            setAlertType("danger");
            setAlert("Something went Wrong");
        }
    }
    useEffect(() => {
        const fetchUdemyKeys = async () => {
            try {
                const response = await axios.get(`/api/tenants/${tenantID}/apiKeys/udemy`, {
                    baseURL: config.endPoint.configManager,
                });

                if (response.data) {
                    setUdemyApiKeys({
                        accountId: response.data?.data?.accountId,
                        accountName: response.data?.data?.accountName,
                        enabled: response.data?.data?.enabled,
                        clientId: response.data?.data?.clientId,
                        clientSecret: response.data?.data?.clientSecret
                    })
                }

            } catch (err) {
                setAlertType("danger");
                setAlert("Something went Wrong");
            }
        };
        fetchUdemyKeys();
    }, []);
    return { udemyApiKeys, setUdemyApiKeys, alert, alertType, updateApiKeys, setAlert }
}

export default useThirdPartyApiKeyManagement;