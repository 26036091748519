import React, { useContext } from "react";
import Card from "react-bootstrap/Card";
import style from "./viewAllCourseTile.module.css";
import { Link } from "react-router-dom";
import { ReactComponent as RightArrow } from "../../../../assets/icons/rightArrowWhite.svg";
import { UserContext } from "../../../../context/UserContext";

function ViewAllCourseTile(props) {
  let { path } = props;
  path = path ? path : "/Classroom_training/all/all";
  const { userConfig } = useContext(UserContext);

  return (
    <>
      <Card
        style={{ marginTop: "0rem", marginLeft: "0rem" }}
        className={style.courseCard}
      >
        <Link
          to={path}
          className={style.link}
          style={{ background: userConfig.primary }}
        >
          <div className={style.text}>View All Courses</div>
          <div className={style.arrow}>
            <RightArrow />
          </div>
        </Link>
      </Card>
    </>
  );
}

export default ViewAllCourseTile;
