import React, { useContext } from "react";
import { getDynamicFilterList, getUserCourseListColumns } from "./learnerHistoryUtils";
import { getEmployeeCourseDetails } from "../../../api/learnerHistory";
import { UserContext } from "../../../context/UserContext";
import { Table } from "antd";

export const LearnerHistoryDetails = ({ record, getColumnSearchProps }) => {

    const [userCourseDetails, setUserCourseDetails] = React.useState([])
    const userCourseNameInput = React.useRef(null);
    const { userConfig } = useContext(UserContext)
    const { id } = record;
    const courseTypeFilter = getDynamicFilterList("type", userCourseDetails);
    const statusFilter = getDynamicFilterList("status", userCourseDetails);
    const categoryFilter = getDynamicFilterList(
        "categoryName",
        userCourseDetails
    );
    const subCategoryFilter = getDynamicFilterList(
        "subCategoryName",
        userCourseDetails
    );


    React.useEffect(() => {
        (async function getUserCourseDetails() {
            if (id) {
                const courseDetails = await getEmployeeCourseDetails(id);
                const updatedCourseDetails = courseDetails.map((course) => {
                    const { categoryName, subCategoryName } = course;
                    if (categoryName === null || subCategoryName === null) {
                        return {
                            ...course,
                            categoryName: "",
                            subCategoryName: "",
                        };
                    }
                    return course;
                });

                setUserCourseDetails(updatedCourseDetails);
            }
        })()
    }, [id]);
    const userCourseListColumns = getUserCourseListColumns(
        courseTypeFilter,
        categoryFilter,
        subCategoryFilter,
        statusFilter,
        getColumnSearchProps,
        userCourseNameInput,
        userConfig,
        id
    );
    React.useEffect(() => {

    }, [userCourseDetails])
    return (
        <Table
            className="inner-table-content"
            columns={userCourseListColumns}
            dataSource={userCourseDetails}
            rowClassName={(record) =>
                record.type === "EL"
                    ? "table-row-EL"
                    : record.type === "UDEMY" ? "table-row-UDEMY"
                        : record.isPrivate
                            ? "table-row-private-IL"
                            : "table-row-IL"
            }
            pagination={{
                position: ["bottomCenter"],
            }}
        />
    );
}

export default LearnerHistoryDetails;