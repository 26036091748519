import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/UserContext";
import config from "../../../config";
import { Link } from "react-router-dom";
import { Breadcrumb, Card, Typography } from "antd";
import "./AntdManagerDashboard.scss";

const crumbs = [
  {
    title: (
      <a href="/home" className="common-breadcrumbs-color">
        Home
      </a>
    ),
  },
  {
    title: "Manager Dashboard",
  },
];

const tileDetails = [
  {
    title: config.managerDashboardTiles.team,
    description: "View and Track my team members",
    to: "/Manager/MyTeam",
    access: [config.userRoleNames.manager],
  },
  {
    title: config.managerDashboardTiles.approval,
    description: "View all your Notification",
    to: "/Manager/MyApprovals",
    access: [config.userRoleNames.manager],
  },
  {
    title: config.managerDashboardTiles.recommendations,
    description: "View and Assign the user to the course",
    // TODO: Add the URL for MY Recommendations screen
    to: "/Manager/ViewYourRecommendation",
    access: [config.userRoleNames.manager],
  },
  {
    title: config.managerDashboardTiles.reports,
    description: "View, Share and Download reports",
    to: "/Manager/TeamReports",
    access: [config.userRoleNames.manager],
  },
];
function ManagerDashboard() {
  const { userRoles } = useContext(UserContext);
  const [tiles, setTiles] = useState(tileDetails);

  useEffect(() => {
    const filteredTiles = tileDetails.filter((tile) => {
      if (tile.access.some((item) => userRoles.includes(item))) {
        return tile;
      } else {
        return null;
      }
    });
    setTiles(filteredTiles);
  }, [userRoles]);

  const renderManagerCard = (current) => (
    <Link to={current.to} className="manager-dashboard-card">
      <Card style={{ position: "relative" }}>
        <Typography className="manager-dashboard-card-title">
          {current.title.toUpperCase()}
        </Typography>
        <Typography className="manager-dashboard-card-description">
          {current.description}
        </Typography>
      </Card>
    </Link>
  );

  return (
    <div className="manager-dashboard-container">
      <Breadcrumb className="manager-dashboard-breadcumb" items={crumbs} />
      <Typography role="heading" className="manager-page-title">
        Manager Dashboard
      </Typography>

      <div className="manager-dashboard">
        {tiles
          .map((current) => renderManagerCard(current))
          .sort((a, b) => a.index - b.index)}
      </div>
    </div>
  );
}

export default ManagerDashboard;
