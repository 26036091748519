import React, { useState, useContext } from "react";
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Typography from "antd/es/typography/Typography";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import axios from "axios";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import elearningVideoWhite from "./../../../../assets/icons/elearningVideoWhite.svg";
import { ReactComponent as CourseProvider } from "./../../../../assets/icons/courseProviderIcon.svg";
import add_course from "../../../../assets/icons/add_course.svg";
import selected from "../../../../assets/icons/selected.svg";
import { decodeHtmlEntities } from "../../../../utils/commonUtility";
import BookmarkIcon from "../../../../assets/icons/bookmark_icon_white.png";
import WhiteBookmarkIcon from "../../../../assets/icons/bookmark_icon_white_filled.png";
import { UserContext } from "../../../../context/UserContext";
import config from "../../../../config";
import "./udemyCourseTile.scss";
import SelectedCourseIcon from "../../../../components/common/SelectedCourseIcon";
import AddCourseIcon from "../../../../components/common/AddCourseIcon";



const { Text } = Typography
function UdemyCourseTile({
  dataElement,
  index,
  tileType,
  checkedState,
  cardClicked,
  isSearchResultPage,
  isFavorite,
  reload,
}) {
  let {
    name,
    courseProvider,
    courseProviderDetails,
    id,
    courseId,
    isFavourite: isFav,
    duration,
    languages,
    usersCompleted,
    usersStarted,
    userCourseData,
    courseStatus,
    expectedCompletionTimestamp,
    instructor
  } = dataElement || {};
  const { userId, userConfig } = useContext(UserContext);
  const [isFavourite, setIsFavourite] = useState(isFav || isFavorite);
  usersStarted = usersStarted ? usersStarted : 0;
  usersCompleted = usersCompleted ? usersCompleted : 0;
  const totalUsers = usersStarted + usersCompleted;

  let imageUrl = !dataElement.image
    ? !dataElement.imageUrl
      ? "/default.png"
      : dataElement.imageUrl
    : dataElement.image;

  let showCourseStatus = userCourseData?.courseStatus
    ? userCourseData.courseStatus.toLowerCase()
    : courseStatus
      ? courseStatus
      : "";
  let roundProgressBarColor = "#D1D1D1";
  if (showCourseStatus === "completed") {
    showCourseStatus = "Completed";
    roundProgressBarColor = "#00B900";
  } else if (showCourseStatus === "started") {
    showCourseStatus = "In Progress";
    roundProgressBarColor = "#F59600";
  } else {
    showCourseStatus = "Get Started";
    roundProgressBarColor = "#D1D1D1";
  }

  const addFavourite = async (userId, courseId, type) => {
    const endPoint = `${config.endPoint.commonService}/favourite`;
    await axios
      .post(endPoint, [
        {
          userId: userId,
          entityType: type,
          entityId: courseId,
          subEntityType: 'udemy'
        },
      ])
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        if (reload) reload();
      });
  };
  const deleteFavourite = async (userId, type, courseId) => {
    const endPoint = `${config.endPoint.commonService}/favourite?userId=${userId}&courseType=${type}&courseId=${courseId}`;
    await axios
      .delete(endPoint)
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        if (reload) reload();
      });
  };
  const changeStatus = (type) => {
    if (isFavourite) {
      setIsFavourite(false);
      deleteFavourite(userId, type, id || courseId);
    } else {
      setIsFavourite(true);
      addFavourite(userId, id || courseId, type);
    }
  };
  return (
    <div>
      <Card className={"card elearning-card"} key={index} onClick={tileType === "learningPath" ? (e) => { e.preventDefault() } : cardClicked}>
        <div className={"imageDiv"}>
          <Card.Img variant="top" src={imageUrl} className={"image"} />
          <Card.ImgOverlay className={"elearningOverlay"}>
            <div className={"elearningCardOverlayText"}>
              <div className={"elearningOverlayText"}>
                <img alt="video" src={elearningVideoWhite} /> UDEMY
              </div>
              <div className={"rightContent"}>
                {userCourseData?.expectedCompletionTime ||
                  expectedCompletionTimestamp ? (
                  <span className={"assignedStatus"}> Assigned </span>
                ) : (
                  ""
                )}
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    changeStatus(config.elearningCourseList.alias);
                  }}
                  className={`${"wishlistImageEL"} ${userCourseData?.expectedCompletionTime ||
                    expectedCompletionTimestamp
                    ? ""
                    : "extraMargin"
                    }`}
                >
                  <img
                    src={isFavourite ? WhiteBookmarkIcon : BookmarkIcon}
                    alt={isFavourite ? "Bookmarked" : "Not Bookmarked"}
                  />
                </span>
              </div>
            </div>
          </Card.ImgOverlay>
        </div>
        <Card.Body className={"udemyBody"}>
          <Row>
            <Col className="body-content" span={24}>
              <Row>
                <div className={"cardTitle"}>{decodeHtmlEntities(name)}</div>
              </Row>
              <Row align={"middle"}>
                <Col span={16}>
                  <Row>
                    <Col>
                      <Row>

                        <Col>
                          <Text style={{ fontFamily: 'RakutenRoundRegular', fontSize: '12px' }}>{instructor.name}</Text>
                        </Col>
                        <Col>
                          <Text style={{ fontFamily: 'RakutenRoundRegular', fontSize: '12px' }}>{duration}</Text>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col>
                      <CourseProvider className={"courseProviderIcon"} />
                    </Col>
                    <Col>
                      <Text style={{ fontFamily: 'RakutenRoundRegular', fontSize: '12px', fontColor: 'grey' }}>
                        {courseProviderDetails?.CourseProviderName?.toUpperCase() ||
                          courseProvider?.CourseProviderName?.toUpperCase() ||
                          courseProvider?.toUpperCase() ||
                          "N/A"}
                      </Text>
                    </Col>
                  </Row>

                </Col>
                <Col span={8}>
                  {tileType === "learningPath" ?
                    <div className={"addSelectCourse"}>
                      {checkedState &&
                        checkedState?.length &&
                        checkedState?.includes(dataElement.id) ? (
                        <span
                          className="plusCircleStyle"
                          onClick={() => cardClicked(dataElement.id)}
                        >
                          <SelectedCourseIcon
                            strokeColor={userConfig.primary}
                            fillColor={userConfig.primary}
                          />
                        </span>
                      ) : (
                        <span
                          onClick={() => cardClicked(dataElement.id)}
                          className="plusCircleStyle"
                        >
                          <AddCourseIcon
                            strokeColor={userConfig.primary}
                            fillColor={userConfig.primary}
                          />
                        </span>
                      )}
                    </div> : <div className={`${"circleProgressBar"}`}>
                      <CircularProgressbarWithChildren
                        key="1"
                        value="100"
                        styles={buildStyles({
                          pathColor: roundProgressBarColor,
                        })}
                        strokeWidth={5}
                      >
                        <div className={"textInside"}>{showCourseStatus}</div>
                      </CircularProgressbarWithChildren>
                    </div>}
                </Col>



              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div >
  );
}

UdemyCourseTile.propTypes = {
  dataElement: PropTypes.any.isRequired,
  index: PropTypes.number,
  coursePath: PropTypes.string,
};

export default UdemyCourseTile;
