import React, { useState, useEffect } from "react";
import { TopContainer, AdminPageTitle } from "../../../component-library";
import Avatar from "react-avatar";
import { Row, Col, Layout } from "antd";
// import style from "./Profile.module.css";
import axios from "axios";
import { Breadcrumb } from "antd";
import config from "../../../config";
import "./Profile.scss";

const crumbs = [
  {
    title: <a href="/">Home</a>,
  },
  { title: "My Profile" },
];

function Profile() {
  const [pageLoading, setPageLoading] = useState(true); // Can be used for showing loader
  const [profileData, setProfileData] = useState(null);
  const isMobile = window.innerWidth < 786;
  async function fetchProfileData() {
    try {
      const result = await axios.get(
        `${config.endPoint.userService}/api/user/profile`
      );
      if (result.data.success) {
        setProfileData(result.data.data);
        setPageLoading(false);
      }
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
    if (pageLoading) {
      // To avoid duplicate network call
      fetchProfileData();
    }
  }, [pageLoading]);

  return (
    <div className={"profileContainer"} data-testid="profile-container">
      <Layout>
        <Row style={{ margin: "auto", width: "84rem" }}>
          <Col lg={24}>
            <Breadcrumb items={crumbs} className={"profileBreadcrumb"} />
          </Col>
        </Row>

        <AdminPageTitle pageTitle="My Profile" />
        <Row style={{ width: isMobile ? "" : "83rem", margin: "auto" }}>
          <Col>
            <Row>
              <Col lg={24}>
                <Avatar
                  size={isMobile ? "3.75rem" : "11.25rem"}
                  name={
                    profileData &&
                    `${profileData.firstName} ${profileData.lastName}`
                  }
                  round={true}
                  textSizeRatio={2}
                  className={"avatar"}
                  data-testid="avatar"
                />
              </Col>
              <Col>
                <div className={"employeeName"} data-testid="employee-name">
                  {profileData &&
                    `${profileData.firstName} ${profileData.lastName}`}
                </div>
                <div className={"employeeId"}>
                  <span className={"employeeIdLabel"}> EMP ID: </span>
                  <span className={"employeeIdValue"} data-testid="employee-id">
                    {profileData && profileData.employeeId}
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
          <Col lg={16}>
            <Row>
              <Col lg={12} xs={24}>
                <div className={"label"}>Role</div>
                <div className={"employeeInfo"} data-testid="employee-role">
                  {profileData && profileData.title}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12} xs={24}>
                <div className={"label"}>Email</div>
                <div className={"employeeInfo"} data-testid="employee-email">
                  {profileData && profileData.email}
                </div>
              </Col>
              <Col lg={12} xs={24}>
                <div className={"label"}>Department</div>
                <div
                  className={"employeeInfo"}
                  data-testid="employee-department"
                >
                  {profileData && profileData?.Department?.name}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12} xs={24}>
                <div className={"label"}>Company</div>
                <div className={"employeeInfo"} data-testid="employee-company">
                  {profileData && profileData?.Department?.CompanyDetail?.name}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout>
    </div>
  );
}

export default Profile;
