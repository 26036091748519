import React, { useContext } from "react";
import {
  AdminPageTitle,
  Alert
} from "../../../component-library";
import {
  Breadcrumb,
  Button,
  Input,
  Typography,
  Row,
  Col
} from "antd";
import Switch from 'antd/es/switch';
import "./antdAdminPrivileges.scss";
import { UdemyContext } from "../../../context/UdemyContext";


function ThirdPartyApiKeyManagement() {

  const { udemyApiKeys, setUdemyApiKeys, updateApiKeys, setAlert, alert, alertType } = useContext(UdemyContext)
  const crumbs = [
    {
      title: (
        <a href="/Admin" style={{ color: "#00a0f0" }}>
          Admin Dashboard
        </a>
      ),
    },
    { title: "3rd Party API Keys" },
  ];

  const renderTitle = () => {
    return <AdminPageTitle pageTitle="3rd Party API Keys" />;
  };

  const renderMenu = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>{renderTitle()}</div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
        </div>
      </div>
    );
  };


  return (
    <Row className="w-full pt-36 px-16 pb-16">
      <Col span={22}>
        <Alert
          message={alert}
          type={alertType}
          onCloseAlert={() => setAlert("")}
        />
        <Breadcrumb
          items={crumbs}
          style={{ fontFamily: "RakutenRoundRegular" }}
        />
        {renderMenu()}
        <Row className="w-full">
          <Col className="space-y-8" span={24}>
            <Row gutter={8} >
              <Col span={4}>
                <Typography.Title level={5} className="font-rakutenRoundSemiBold">Enable Keys</Typography.Title>
              </Col>
              <Col span={20}>
                <Switch
                  onChange={(value) => setUdemyApiKeys(prevState => ({ ...prevState, enabled: value }))}
                  value={udemyApiKeys.enabled} />
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={4}>
                <Typography.Title level={5} className="font-rakutenRoundSemiBold">Account ID:</Typography.Title>
              </Col>
              <Col span={20}>      <Input
                disabled={!udemyApiKeys.enabled}
                placeholder={udemyApiKeys.accountId || 'Enter Account ID'}
                style={{ width: "20rem" }}
                value={udemyApiKeys.accountId}
                onChange={(e) => setUdemyApiKeys(prevState => ({ ...prevState, accountId: e.target.value }))}
              />
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={4}>
                <Typography.Title level={5} className="font-rakutenRoundSemiBold">Account Name:</Typography.Title>
              </Col>
              <Col span={20}>  <Input
                disabled={!udemyApiKeys.enabled}
                placeholder={udemyApiKeys.accountName || 'Enter Account Name '}
                style={{ width: "20rem" }}
                value={udemyApiKeys.accountName}
                onChange={(e) => setUdemyApiKeys(prevState => ({ ...prevState, accountName: e.target.value }))}
              />
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={4}>
                <Typography.Title level={5} className="font-rakutenRoundSemiBold">Client ID:</Typography.Title>
              </Col>
              <Col span={20}>
                <Input.Password
                  disabled={!udemyApiKeys.enabled}
                  placeholder={udemyApiKeys.clientId || 'Enter Client Id'}
                  style={{ width: "20rem" }}
                  value={udemyApiKeys.clientId}
                  onChange={(e) => setUdemyApiKeys(prevState => ({ ...prevState, clientId: e.target.value }))}
                />
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={4}>
                <Typography.Title level={5} className="font-rakutenRoundSemiBold">Secret Key:</Typography.Title>
              </Col>
              <Col span={20}>
                <Input.Password
                  disabled={!udemyApiKeys.enabled}
                  placeholder={udemyApiKeys.clientSecret || 'Enter Secret Key'}
                  style={{ width: "20rem" }}
                  value={udemyApiKeys.clientSecret}
                  onChange={(e) => setUdemyApiKeys(prevState => ({ ...prevState, clientSecret: e.target.value }))}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  type="primary"
                  size={"large"}
                  style={{ paddingLeft: 20, paddingRight: 20 }}
                  onClick={updateApiKeys}
                >
                  {udemyApiKeys.clientId ? "Update" : 'Add'}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default ThirdPartyApiKeyManagement;