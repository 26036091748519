import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  Form,
  Row,
  Col,
  Image,
  Input,
  Checkbox,
  Typography,
  Select,
  Radio,
  TreeSelect,
  Button,
  Modal,
} from "antd";

import { CourseContext } from "../../../../context/CourseContext";
import {
  formatCountryList,
  validateLearningPathDetails,
} from "./learningPathAddEdit.lib";

import "./learningPathAddEdit.css";
import style from "./learningPathsAddEdit.module.css";
import uploadImage from "../../../../assets/icons/upload_image.svg";
import archiveIcon from "../../../../assets/icons/courseArchive.svg";
import activeIcon from "../../../../assets/icons/courseActive.svg";
import config from "../../../../config";
import edit from "../../../../assets/icons/edit.svg";
import add_course from "../../../../assets/icons/add_course.svg";
import add_course_disable from "../../../../assets/icons/add_course_disable.svg";

import {
  TopContainer,
  Label,
  Alert,
  TextEditor,
  PrimaryButton,
  SecondaryButton,
  SubcategoryTags,
  ScrollToTop,
  DraggableList,
  CustomToggle,
} from "../../../../../src/component-library";
import ImageSelectionModal from "../../../../component-library/imageSelectionModal/AntdImageSelectionModal.js";
import Validation from "../../../../../src/component-library/validation/antDesignValidation";

import formatCountriesByRegion from "../utils/learningPathUtils";
import SelectCourseFromList from "../selectCourseFromList/SelectCourseFromList";
import { AntDesignBreadCrumb } from "../../../../component-library";

import { modifyImageTags } from "../../../../utils/textEditor";
import LearningPathBodyContents from "./LearningPathBodyContents";
import flatten from "lodash/flatten";
import { InfoCircleOutlined } from "@ant-design/icons";
import { serializeParentCategory } from "../../../../serializer/parentCategorySerializer";
import DraggableItem from "../../../../component-library/draggableList/DraggableItem";
import { SELECTION_ALL } from "antd/es/table/hooks/useSelection";
import { RiInboxUnarchiveLine, RiInboxUnarchiveFill } from "react-icons/ri";

const { SHOW_PARENT } = TreeSelect;

export default function LearningPathsAddEdit() {
  // TODO : use formdata object for state values instead of individual states
  // remove unnecessary states and variables

  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const pathId = query.get("pathId");
  const { languageList, countryList: countries } = useContext(CourseContext);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [errorMessage, setErrorMessage] = useState({});
  const [pathTitle, setPathTitle] = useState("");
  const [lenthh, setLenthh] = useState(null);
  const [country, setCountry] = useState([]);
  const [selectedCounties, setSelectedCountries] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [newCountry, setNewCountry] = useState([]);
  const [unformattedCountries, setUnformattedList] = useState([]);
  const [parentCategory, setParentCategory] = useState([]);
  const [selectedParentCategory, setSelectedParentCategory] = useState([]);
  const [initialTags, setInitialTags] = useState([]);
  const [finalTags, setFinalTags] = useState([]);
  const [adminComment, setAdminComment] = useState("");
  const [objectives, setObjectives] = useState("");
  const [targetedAudience, setTargetedAudience] = useState("");
  const [preRequisites, setPreRequisites] = useState("");
  const [description, setDescription] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageName, setImageName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [learningPathStatus, setLearningPathStatus] = useState("active");
  const [archived, setArchived] = useState(false);
  const [showSelectCourseModal, setShowSelectCourseModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [selectedELCourseList, setselectedELCourseList] = useState([]);
  const [selectedILCourse, setSelectedILCourse] = useState([]);
  const [selectedILCourseList, setSelectedILCourseList] = useState([]);
  const [pathData, setPathData] = useState([]);
  const [showAddCourseDiv, setShowAddCourseDiv] = useState(true);
  const [pathInitialEditData, setPathInitialEditData] = useState();
  const [pathIdentityId, setPathIdentityId] = useState("");
  const [finalCourseList, setFinalCourseList] = useState([]);
  const [allSelectedCourses, setAllSelectedCourses] = useState([]);
  const [courseOrderArray, setCourseOrderArray] = useState([]);
  const [isSequential, setIsSequential] = useState(false);
  const textEditorRef = useRef([]);
  const handleClose = () => setShowSelectCourseModal(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [privateBackgroundColor, setPrivateBackgroundColor] =
    useState("#EBEBEB");
  const [publicBackgroundColor, setPublicBackgroundColor] = useState("#00A0F0");
  const [countriesList, setCountriesList] = useState([]);
  const [isFormEdited, setIsFormEdited] = useState(false);
  const [
    showRemoveCourseConfirmationModal,
    setShowRemoveCourseConfirmationModal,
  ] = useState(false);
  const [tempCountryList, setTempCountryList] = useState([]);
  const [showPrivateCourseModal, setShowPrivateCourseModal] = useState(false);

  useEffect(() => {
    if (isPrivate) {
      setPublicBackgroundColor("#EBEBEB");
      setPrivateBackgroundColor("#F59600");
    } else {
      setPublicBackgroundColor("#00A0F0");
      setPrivateBackgroundColor("#EBEBEB");
    }
  }, [isPrivate]);

  useEffect(() => {
    setUnformattedList(countries);
    const formattedCountryList = formatCountryList(countries);
    setCountryList(formattedCountryList);
    const languagesListFormatted = formattedDataForCustomSelect(languageList);
    setLanguages(languagesListFormatted);
  }, [countries, languageList]);

  useEffect(() => {
    const finalArr = [...selectedILCourseList, ...selectedELCourseList];

    const finalSortedArray = courseOrderArray
      ? finalArr.sort((a, b) => {
        const order =
          courseOrderArray.findIndex(
            (course) =>
              course.id === a.id && course.courseType === a.courseType
          ) -
          courseOrderArray.findIndex(
            (course) =>
              course.id === b.id && course.courseType === b.courseType
          );
        return order;
      })
      : finalArr;

    setFinalCourseList(finalSortedArray);

    //ToDo resolve dependency array issue
  }, [selectedILCourseList, selectedELCourseList]);

  useEffect(() => {
    if (imageName) {
      let imageUrl = `${config.endPoint.commonService}/image/imageName/course/${imageName}`;

      axios
        .get(imageUrl)
        .then((response) => {
          const { data } = response || {};
          if (data.data && data.data.imageLink)
            setImageUrl(data.data.imageLink);
          setIsFormEdited(true);
        })
        .catch((error) => {
          //TODO use usenotification after merging PR -RGROW-4849_code_refactoring_and_known_bug_fixes
        });
    }
  }, [imageName]);

  useEffect(() => {
    if (selectedCourse?.length > 0) {
      setShowAddCourseDiv(false);
      const updatedCourseArr = selectedCourse?.map((course) => ({
        ...course,
        courseType: config.elearningCourseList.alias,
      }));
      setselectedELCourseList([...updatedCourseArr]);
    }
    if (selectedILCourse?.length > 0) {
      setShowAddCourseDiv(false);
      const updatedCourseArr = selectedILCourse?.map((course) => ({
        ...course,
        courseType: config.instructorLed.alias,
      }));
      setSelectedILCourseList([...updatedCourseArr]);
      //ToDo calling APIs from a separate API file
    }

    selectedCourse?.length === 0 && setselectedELCourseList([]);
    selectedILCourse?.length === 0 && setSelectedILCourseList([]);

    if (selectedCourse?.length === 0 && selectedILCourse?.length === 0) {
      setShowAddCourseDiv(true);
      setFinalCourseList([]);
    }
  }, [selectedCourse, selectedILCourse]);

  const getPathData = () => {
    const endPoint = `${config.endPoint.commonService}/learningpath/${pathId}?edit=true`;

    axios
      .get(endPoint)
      .then((response) => {
        if (!response.data.success) {
          setAlertType("danger");
          setAlert(response.data.message);
          const timer = setTimeout(() => {
            history.push("/Admin/learning-path");
          }, config.expectedTimeOuts.tenSeconds);

          return () => clearTimeout(timer);
        }
        const {
          data: { data },
        } = response;
        setPathData(data);
        setIsFormEdited(false);
      })
      .catch((error) => {
        //TODO use usenotification after merging PR -RGROW-4849_code_refactoring_and_known_bug_fixes
        setAlert(error?.response?.message || "Something went wrong.");
        setAlertType("danger");
      });
  };

  useEffect(() => {
    if (pathId) {
      //ToDo calling APIs from a separate API file
      getPathData();
    }
  }, [pathId]);

  useEffect(() => {
    const parentCategoryEndpoint = `${config.endPoint.commonService}/parentCategory`;
    axios.get(parentCategoryEndpoint).then((response) => {
      const formattedCategoryList = formatParentCategoryList(
        serializeParentCategory(response)
      );

      setParentCategory(() => [...formattedCategoryList]);
    }, []);
  }, []);

  useEffect(() => {
    if (pathData && Object.keys(pathData).length) {
      setInitialEditState(pathData);
    }
  }, [pathData]);

  const crumbs = [
    {
      title: "Admin Dashboard",
      to: "/Admin",
    },
    {
      title: "Learning Paths",
      to: "/Admin/learning-path/paths",
    },
    {
      title: pathId ? "Edit Learning Path" : "Add Learning Path",
    },
  ];

  const formattedCountriesList = formatCountriesByRegion(countryList);
  const allCountries = formattedCountriesList.map((country) => country.value);

  const setInitialEditState = async (data) => {
    const result = data;
    const {
      countries = [],
      identityId,
      title = "",
      parentCategories = [],
      prerequisites = "",
      description = "",
      adminComments = "",
      objectives = "",
      targetedAudience = "",
      search_tags = [],
      image = null,
      courses = [],
      learningpathStatus,
      languageId = 1,
      isPublic,
      isSequential,
    } = result || {};
    setIsPrivate(!isPublic);
    setIsSequential(isSequential);
    setSelectedLanguage(languageId);
    setCountriesList(countries);
    const modifiedData = await modifyImageTags({
      description: description,
      targetedAudience: targetedAudience,
      objectives: objectives,
      prerequisites: prerequisites,
      adminComments: adminComments,
      parentCategoryId: result?.parent_category?.id,
    });
    setPathInitialEditData({ ...data, ...modifiedData });

    if (learningpathStatus === "archived") {
      setArchived(true);
    }

    const countryArray = result?.countries || [];
    if (unformattedCountries?.length) {
      const formattedSelectedCountries = countryArray
        .map((current) => {
          return unformattedCountries.find((curr) => curr.code === current);
        })
        .map((curr) => {
          return { id: curr.code, value: curr.country };
        });
      setSelectedCountries(formattedSelectedCountries);
      setCountry(countries);
    }
    setPathIdentityId(identityId);
    setPathTitle(title);
    setPreRequisites(modifiedData?.prerequisites);
    setAdminComment(modifiedData?.adminComments);
    setObjectives(modifiedData?.objectives);
    setTargetedAudience(modifiedData?.targetedAudience);
    if (learningpathStatus === "active") {
      setLearningPathStatus("active");
    } else if (learningpathStatus === "archived") {
      setLearningPathStatus("archived");
    } else {
      setLearningPathStatus("pending");
    }

    setDescription(modifiedData?.description);

    setSelectedParentCategory(formatParentCategoryList(result.parentCategory));

    if (courses && courses?.length > 0) {
      setShowAddCourseDiv(false);

      const orderArray = courses.map((course) => ({
        id: course.id,
        courseType: course.courseType,
        courseProviderType: course.courseProvider === "udemy" ? "udemy" : "rlms"
      }));
      setCourseOrderArray(orderArray);

      const ELCourses = courses?.filter(
        (course) => course.courseType === config.elearningCourseList.alias
      );
      const ILCourses = courses?.filter(
        (course) => course.courseType === config.instructorLed.alias
      );
      setAllSelectedCourses(
        courses.map((course) => ({
          courseId: course.id,
          courseType: course.courseType,
          courseProviderType: course.courseProvider === "udemy" ? "udemy" : "rlms"
        }))
      );
      setSelectedCourse(ELCourses);
      setselectedELCourseList(ELCourses);
      setSelectedILCourse(ILCourses);
      setSelectedILCourseList(ILCourses);
    } else {
      setShowAddCourseDiv(true);
      setselectedELCourseList([]);
      setSelectedCourse([]);
      setSelectedILCourseList([]);
      setSelectedILCourse([]);
    }

    setInitialTags(
      search_tags &&
      search_tags.map((current, i) => {
        return {
          value: current,
          label: current,
        };
      })
    );

    if (image) setImageUrl(image);
  };
  console.log("Corse order", courseOrderArray)
  const submitHandler = async (e) => {
    //TODO on submit validate form data and make api call
    window.scrollTo(0, 0);
    e.preventDefault();
    const learningPathData = {
      title: pathTitle,
      parentCategories: selectedParentCategory.map((category) => {
        return typeof category === "number" ? category : category.value;
      }),
      countries: getCountryCodes(
        countriesList.filter((item) => item !== "selectAll")
      ),
      targetedAudience: targetedAudience,
      objectives: objectives,
      prerequisites: preRequisites,
      adminComments: adminComment,
      description: description,
      searchTags: initialTags.map((current) => {
        return current?.value;
      }),
      courses: allSelectedCourses,
      isSequential: isSequential ? 1 : 0,
      isPublic: isPrivate ? 0 : 1,
      languageId: selectedLanguage,
      learningpathStatus: learningPathStatus,
      language: selectedLanguage,
    };

    const endPoint = `${config.endPoint.commonService}/learningpath`;
    if (imageName) {
      learningPathData.image = imageName;
    } else {
      learningPathData.image = "";
    }

    if (learningPathStatus !== "pending") {
      learningPathData.learningpathStatus = learningPathStatus;
    }

    if (pathId) {
      learningPathData.id = +pathId;

      const errors = validateLearningPathDetails(learningPathData);
      if (errors) {
        setErrorMessage({ ...errorMessage, ...errors });
      }

      if (!errors || Object.keys(errors).length === 0) {
        await Promise.all(
          textEditorRef.current.map(
            async (ref) => await ref.deleteOrphanedImages()
          )
        );
        // Move api call to api folder
        axios
          .patch(endPoint, learningPathData)
          .then((response) => {
            history.push({
              pathname: "/Admin/learning-path/paths",
              state: {
                alertType: "success",
                alertMessage: response.data.message,
                pathState: learningPathStatus,
              },
            });
          })
          .catch((error) => {
            setAlert(error?.response?.data?.message || "Something went wrong.");
            setAlertType("danger");
          });
      }
    } else {
      learningPathData.image = imageName;
      const errors = validateLearningPathDetails(learningPathData);
      if (errors) {
        setErrorMessage({ ...errorMessage, ...errors });
      }
      if (!errors || Object.keys(errors).length === 0) {
        await Promise.all(
          textEditorRef.current.map(
            async (ref) => await ref.deleteOrphanedImages()
          )
        );

        //TODO move api call to api folder
        axios
          .post(endPoint, learningPathData)
          .then((response) => {
            history.push({
              pathname: "/Admin/learning-path/paths",
              state: {
                alertType: "success",
                alertMessage: response.data.message,
                pathState: learningPathStatus ? "active" : "pending",
              },
            });
          })
          .catch((error) => {
            setAlert(error?.response?.data?.message || "Something went wrong.");
            setAlertType("danger");
          });
      }
    }
  };

  const resetHandler = () => {
    //Todo reset form data to initial state
    //Todo use useeffect to compare formdata prev state and current state for isFormEditted
    setIsFormEdited(false);
    if (pathId) {
      setSelectedCourse([]);
      setSelectedILCourse([]);
      setInitialEditState(pathData);
      if (!pathData.image) {
        setImageName("");
        setImageUrl("");
      }
    } else {
      setPathTitle("");
      setFinalCourseList([]);
      setCountriesList([]);
      setObjectives("");
      setTargetedAudience("");
      setPreRequisites("");
      setDescription("");
      setInitialTags([]);
      setAdminComment("");
      setImageName("");
      setImageUrl("");
      setIsSequential(false);
      setIsPrivate(false);
      setLearningPathStatus("active");
      setSelectedLanguage([]);
      setSelectedParentCategory([]);
      setShowAddCourseDiv(true);
    }
  };

  const handlePathTitle = (pathTitle) => {
    setPathTitle(pathTitle);
    setIsFormEdited(true);
    if (!pathTitle) {
      const error = { pathTitle: `Please enter Path Title` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { pathTitle: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleSkillType = (currentParentCategory) => {
    setIsFormEdited(true);
    setSelectedParentCategory(currentParentCategory);
    if (currentParentCategory.length === 0) {
      const error = { selectedParentCategory: `Please select Learning Skill` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { selectedParentCategory: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleLanguage = (language) => {
    if (language !== selectedLanguage) {
      setIsFormEdited(true);
    }
    setSelectedLanguage(language);
    if (!language) {
      const error = {
        language: `Please Select Course Language`,
      };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { language: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handlePathObjective = (objectivesText) => {
    if (objectivesText !== objectives) {
      setIsFormEdited(true);
    }
    setObjectives(objectivesText);
    if (!objectivesText) {
      const error = { objectives: `Please enter Path Objectives` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { objectives: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleTargetedAudience = (targetedAudienceText) => {
    if (targetedAudienceText !== targetedAudience) {
      setIsFormEdited(true);
    }
    setTargetedAudience(targetedAudienceText);
    if (!targetedAudienceText) {
      const error = {
        targetedAudience: `Please enter Path Targeted Audience`,
      };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { targetedAudience: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handlePreRequisites = (preRequisites) => {
    setIsFormEdited(true);
    setPreRequisites(preRequisites);
    if (!preRequisites) {
      const error = {
        preRequisites: `Please enter Path Prerequisites`,
      };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { preRequisites: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleDescription = (description) => {
    setIsFormEdited(true);
    setDescription(description);
    if (!description) {
      const error = {
        description: `Please enter Path Description`,
      };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { description: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };
  const validateAllSelectedCourse = () => {
    if (allSelectedCourses || allSelectedCourses.length > 0) {
      const error = { allSelectedCourses: "" };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { allSelectedCourses: `Please select at least one Course` };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };
  const onELCourseSelect = (selectedCourse) => {
    setIsFormEdited(true);
    const selectedELCourseArr = selectedCourse?.map((course) => ({
      courseId: course.id,
      courseType: config.elearningCourseList.alias,
      courseProviderType: course.courseProvider === "udemy" ? "udemy" : "rlms"
    }));
    setSelectedCourse(selectedCourse);
    setAllSelectedCourses(selectedELCourseArr || []);
    validateAllSelectedCourse();
  };

  const onILCourseSelect = (selectedILCourse) => {
    setIsFormEdited(true);
    const selectedILCourseArr = selectedILCourse?.map((course) => ({
      courseId: course.id,
      courseType: config.instructorLed.alias,
      courseProviderType: course.courseProvider === "udemy" ? "udemy" : "rlms"
    }));
    setSelectedILCourse(selectedILCourse);
    setAllSelectedCourses((allSelectedCourses) => [
      ...allSelectedCourses,
      ...selectedILCourseArr,
    ]);
    validateAllSelectedCourse();
  };

  const updateSelectedCourse = (courseList) => {
    setIsFormEdited(true);
    const allSelectedCourseId = courseList?.map((course) => ({
      courseId: course.id,
      courseType: course.courseType,
      courseProviderType: course.courseProvider === "udemy" ? "udemy" : "rlms"
    }));

    const orderArray = courseList?.map((course) => ({
      id: course.id,
      courseType: course.courseType,
      courseProviderType: course.courseProvider === "udemy" ? "udemy" : "rlms"
    }));

    const ELCourses = courseList?.filter(
      (course) => course.courseType === config.elearningCourseList.alias
    );
    const ILCourses = courseList?.filter(
      (course) => course.courseType === config.instructorLed.alias
    );
    const updatedCourseIds = ELCourses.map((course) => course.id);
    const updatedILCourseIds = ILCourses.map((course) => course.id);

    setCourseOrderArray(orderArray);
    setAllSelectedCourses(allSelectedCourseId);
    setSelectedCourse(ELCourses);
    setSelectedILCourse(ILCourses);
    if (!allSelectedCourseId || allSelectedCourseId?.length === 0) {
      const error = { allSelectedCourses: `Please select at least one Course` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      setErrorMessage({ allSelectedCourses: "" });
    }
  };

  function formatParentCategoryList(data) {
    return data.map((current) => {
      return { label: current.parentCategoryName, value: current.id };
    });
  }

  function formattedDataForCustomSelect(data) {
    if (!data || data.length === 0) return [];

    return data.map((current) => {
      return { value: current.id, label: current.title };
    });
  }

  const toggleSequential = (e) => {
    setIsFormEdited(true);
    setIsSequential(e.target.checked);
  };

  const getCountryCodes = (countriesList) => {
    const countries = countriesList.map((countryCode) => {
      if (["APAC", "Americas", "EU", "Other Regions"].includes(countryCode)) {
        const selectedRegion = formattedCountriesList.find(
          (region) => region?.value === countryCode
        );

        const regions = selectedRegion.children?.map(
          (countries) => countries.value
        );
        return regions;
      } else {
        return countryCode;
      }
    });

    return flatten(countries);
  };

  const handleCountryChange = (countries) => {
    setIsFormEdited(true);
    if (
      finalSelectedCourses(filterCourseByRegion(finalCourseList)).length > 0
    ) {
      setShowAddCourseDiv(false);
    } else {
      setShowAddCourseDiv(true);
    }
    if (countries.includes("selectAll")) {
      if (
        countries.length >= formattedCountriesList.length &&
        countries[countries.length - 1] !== "selectAll"
      ) {
        setCountriesList(countries.filter((item) => item !== "selectAll"));
      } else {
        setCountriesList(["selectAll", ...allCountries]);
      }
    } else {
      setCountriesList(
        countriesList.includes("selectAll") ? [] : [...countries]
      );
    }
    if (countries.length === 0) {
      const error = { country: `Please select at least one Country` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { country: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const renderPathCourses = () => {
    const dynamicHeight = showAddCourseDiv ? "242px" : "832px";

    return (
      <div>
        <div style={{ margin: 8 }}>
          <Label title="Path Courses" required />
          <div
            className={style.pathCoursesBox}
            style={{ height: dynamicHeight }}
          >
            {showAddCourseDiv ? (
              <div className={style.pathCoursesInternalBox}>
                <div
                  className={style.addCourse}
                  onClick={() =>
                    countriesList?.length > 0
                      ? setShowSelectCourseModal(true)
                      : setShowSelectCourseModal(false)
                  }
                >
                  <img
                    src={
                      countriesList?.length > 0
                        ? add_course
                        : add_course_disable
                    }
                    alt="add courses icon"
                  />
                </div>
                <span style={{ marginLeft: 16 }}>Add Courses</span>
              </div>
            ) : (
              <>
                <DraggableList
                  list={finalCourseList}
                  updateSelectedCourse={updateSelectedCourse}
                  disable={archived}
                />
                <div className={style.addPathCourses}>
                  <div
                    className={style.addCourse}
                    onClick={() =>
                      !archived && countriesList?.length > 0
                        ? setShowSelectCourseModal(true)
                        : setShowSelectCourseModal(false)
                    }
                  >
                    <img
                      src={
                        !archived && countriesList?.length > 0
                          ? add_course
                          : add_course_disable
                      }
                      alt="add courses icon"
                    />
                    <span className={style.addCourseText}> Add Courses</span>
                  </div>
                </div>
              </>
            )}
          </div>
          {(errorMessage.allSelectedCourses && (
            <Validation message={errorMessage.allSelectedCourses} />
          )) ||
            ""}
        </div>
      </div>
    );
  };

  const renderPathObjectives = () => {
    return (
      <div style={{ margin: 8 }}>
        <Label title="Learning Objectives" required />
        <TextEditor
          value={objectives}
          readOnly={archived}
          ref={(el) => (textEditorRef.current[1] = el)}
          getText={(text) => {
            handlePathObjective(text);
          }}
        />
        {(errorMessage.objectives && (
          <Validation message={errorMessage.objectives} />
        )) ||
          ""}
      </div>
    );
  };

  const renderPathDescription = () => {
    return (
      <div style={{ margin: 8 }}>
        <Label title="Path Description" required />
        <TextEditor
          value={description}
          readOnly={archived}
          ref={(el) => (textEditorRef.current[0] = el)}
          getText={(text) => {
            handleDescription(text);
          }}
        />
        {(errorMessage.description && (
          <Validation message={errorMessage.description} />
        )) ||
          ""}
      </div>
    );
  };

  const renderTargetedAudience = () => {
    return (
      <div style={{ margin: 8 }}>
        <Label title="Targeted Audience" required />
        <TextEditor
          value={targetedAudience}
          readOnly={archived}
          ref={(el) => (textEditorRef.current[2] = el)}
          getText={(text) => {
            handleTargetedAudience(text);
          }}
        />
        {(errorMessage.targetedAudience && (
          <Validation message={errorMessage.targetedAudience} />
        )) ||
          ""}
      </div>
    );
  };

  const renderPreRequisites = () => {
    return (
      <div style={{ margin: 8 }}>
        <Label title="Prerequisites" required />
        <TextEditor
          value={preRequisites}
          readOnly={archived}
          ref={(el) => (textEditorRef.current[3] = el)}
          getText={(text) => {
            handlePreRequisites(text);
          }}
        />
        {(errorMessage.preRequisites && (
          <Validation message={errorMessage.preRequisites} />
        )) ||
          ""}
      </div>
    );
  };

  const renderSearchTags = () => {
    return (
      <div style={{ margin: 8 }}>
        <Label title="Search Tags" />
        <Select
          value={initialTags}
          mode="tags"
          // allowClear
          style={{
            width: "100%",
          }}
          placeholder="Select the learning skill path"
          onChange={(tags) => {
            tags = tags.filter((tag) => tag.trim().length > 0);
            const formattedSearchTags = tags.map((tag) => ({
              value: tag,
              label: tag,
            }));
            setIsFormEdited(true);
            setInitialTags(formattedSearchTags);
            setFinalTags(formattedSearchTags);
          }}
          options={initialTags}
        />
      </div>
    );
  };

  const renderAddCourseButtonHandlers = () => {
    return (
      <Row gutter={[24, 40]} style={{ marginLeft: 8 }}>
        <Col span={4}>
          <SecondaryButton
            otherStyle={style.secButton}
            label="Reset"
            handleClick={resetHandler}
          />
        </Col>
        <Col span={4}>
          <div style={{ marginTop: "-1rem" }}>
            <PrimaryButton
              otherStyle={style.primrySubButton}
              label={pathId ? "Update" : "Submit"}
              handleClick={submitHandler}
              disabled
              isDisabled={!isFormEdited}
            />
          </div>
        </Col>
      </Row>
    );
  };

  const renderAdminNotes = () => {
    return (
      <Row>
        <Col span={24}>
          <div className={style.textBoxContent}>
            <Label title="Admin Notes" />
            <TextEditor
              customClass={style.adminNotes}
              readOnly={archived}
              ref={(el) => (textEditorRef.current[4] = el)}
              value={adminComment}
              getText={(text) => {
                setAdminComment(text);
                if (text !== adminComment) {
                  setIsFormEdited(true);
                }
              }}
            />
          </div>
        </Col>
      </Row>
    );
  };

  const filterCourseByRegion = (courseList) => {
    // filters the course which includes the selected countries
    const filteredCourse = courseList.filter((course) => {
      return tempCountryList
        .filter((country) => country !== "selectAll")
        .every(
          (country) =>
            course?.availableInCountries?.includes(country) ||
            course?.countries?.includes(country)
        );
    });
    return filteredCourse;
  };

  const filterCourse = (finalCourseList) => {
    let filteredCourse = finalCourseList.filter((course) => {
      if (tempCountryList.length < 1) {
        return true;
      }
      return !filterCourseByRegion(finalCourseList).includes(course);
    });
    if (filteredCourse.length == 0) {
      handleCountryChange(tempCountryList);
      setShowRemoveCourseConfirmationModal(false);
    }
    return filteredCourse;
  };

  const filterPrivateCourse = (finalCourseList, courseType = true) => {
    return finalCourseList.filter((course) => course.private !== courseType);
  };

  const finalSelectedCourses = (courses) =>
    courses.map((course) => ({
      courseId: course.id,
      courseType: course.courseType,
      courseProviderType: course.courseProvider === "udemy" ? "udemy" : "rlms"
    }));

  return (
    <>
      <div style={{ marginTop: "3rem" }}>
        {showSelectCourseModal ? (
          <SelectCourseFromList
            show={showSelectCourseModal}
            handleClose={handleClose}
            onELCourseSelect={onELCourseSelect}
            onILCourseSelect={onILCourseSelect}
            selectedELCourseNos={selectedELCourseList}
            selectedILCourseNos={selectedILCourseList}
            selectedContries={getCountryCodes(countriesList)}
            onlyPublicCourses={!isPrivate}
            pathId={pathId}
          />
        ) : (
          <div className="antdlearningpathcontainer">
            <ScrollToTop />
            <Alert
              message={alert}
              type={alertType}
              onCloseAlert={() => setAlert("")}
            />
            <AntDesignBreadCrumb crumbs={crumbs} />
            <Row gutter={[24, 40]} align="middle" style={{ marginTop: "46px" }}>
              <Col span={12}>
                <Typography.Title
                  style={{ fontFamily: "RakutenRoundSemiBold" }}
                >
                  {(pathId && `Edit Learning Path - ${pathIdentityId}`) ||
                    "Create Learning Path"}
                </Typography.Title>
              </Col>
              <Col span={4}>
                <Checkbox
                  checked={isSequential}
                  onChange={toggleSequential}
                  style={{ fontFamily: "RakutenRoundSemiBold" }}
                >
                  COURSE SEQUENCE
                </Checkbox>
              </Col>
              <Col span={4}>
                {pathId && learningPathStatus !== "pending" ? (
                  <CustomToggle
                    isImage={false}
                    toggleTitle={["Path Active", "Path Archived"]}
                    value={learningPathStatus === "active" ? true : false}
                    icons={[RiInboxUnarchiveLine, RiInboxUnarchiveFill]}
                    customToggleCss={style.customToggleCss}
                    onChange={(value) => {
                      if (value) {
                        setLearningPathStatus("active");
                        setArchived(false);
                      } else {
                        setLearningPathStatus("archived");
                        setArchived(true);
                      }
                      setIsFormEdited(true);
                    }}
                  />
                ) : (
                  <CustomToggle
                    toggleTitle={["Path Active", "Path Pending"]}
                    value={learningPathStatus === "active" ? true : false}
                    icons={[activeIcon, archiveIcon]}
                    customToggleCss={style.customToggleCss}
                    toggleTitleCss={style.toggleTitle}
                    disabled={true}
                    onChange={(value) => {
                      setIsFormEdited(true);
                      if (value) {
                        setLearningPathStatus("active");
                      } else {
                        setLearningPathStatus("archived");
                      }
                    }}
                  />
                )}
              </Col>
              <Col span={4}>
                <Radio.Group
                  defaultValue="a"
                  buttonStyle="solid"
                  style={{
                    borderColor: "red",
                  }}
                >
                  <Radio.Button
                    style={{
                      background: publicBackgroundColor,
                      borderColor: publicBackgroundColor,
                    }}
                    onClick={() => {
                      setIsFormEdited(true);
                      if (finalCourseList.length > 0) {
                        const filteredCourse = filterPrivateCourse(
                          finalCourseList,
                          false
                        );
                        if (filteredCourse.length === 0) {
                          setIsPrivate(false);
                        }
                        setShowPrivateCourseModal(true);
                      } else {
                        setIsPrivate(false);
                      }
                    }}
                    value="a"
                  >
                    Public
                  </Radio.Button>
                  <Radio.Button
                    value="b"
                    style={{
                      background: privateBackgroundColor,
                      borderColor: privateBackgroundColor,
                      "&:before": {
                        background: privateBackgroundColor,
                      },
                    }}
                    onClick={() => {
                      setIsFormEdited(true);
                      setIsPrivate(true);
                    }}
                    className="private-button"
                  >
                    Private
                  </Radio.Button>
                </Radio.Group>
              </Col>
            </Row>

            <Form onFinish={submitHandler} layout="vertical">
              <Row gutter={[24, 40]}>
                <Col span={18}>
                  <Row gutter={[40, 40]}>
                    {/* -----------------------------Path Title Input-------------------------------- */}
                    <Col span={12}>
                      <Form.Item
                        label={
                          <label
                            style={{
                              fontFamily: "RakutenRoundRegular",
                              fontSize: "14px",
                            }}
                          >
                            Path Title
                          </label>
                        }
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                        required
                        requiredMark
                      >
                        <Input
                          value={pathTitle}
                          onChange={(e) => {
                            handlePathTitle(e.target.value);
                          }}
                          placeholder="Enter the name path title"
                          disabled={archived}
                          style={{
                            fontFamily: "RakutenRoundRegular",
                            fontSize: "14px",
                            borderRadius: 0,
                          }}
                        ></Input>
                        {(errorMessage.pathTitle && (
                          <Validation message={errorMessage.pathTitle} />
                        )) ||
                          ""}
                      </Form.Item>
                    </Col>
                    {/* -----------------------------Select Country Input-------------------------------- */}
                    <Col span={12}>
                      <Form.Item
                        className="learning-path-create"
                        tooltip={{
                          title:
                            "Selected courses will be removed if countries selected for path are changed",
                          icon: <InfoCircleOutlined id="custom-info-icon" />,
                          placement: "right",
                        }}
                        label={
                          <label
                            style={{
                              fontFamily: "RakutenRoundRegular",
                              fontSize: "14px",
                            }}
                          >
                            Country
                          </label>
                        }
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                        required
                        requiredMark
                      >
                        <TreeSelect
                          value={countriesList}
                          onChange={(values, label, extra) => {
                            const formattedCountryList =
                              getCountryCodes(values);

                            if (
                              extra.checked === false &&
                              extra.triggerValue === "selectAll"
                            ) {
                              setTempCountryList([]);
                              setShowRemoveCourseConfirmationModal(true);
                            } else if (finalCourseList.length > 0) {
                              setTempCountryList(formattedCountryList);
                              setShowRemoveCourseConfirmationModal(true);
                            } else {
                              handleCountryChange(formattedCountryList);
                            }
                          }}
                          treeData={[
                            {
                              title: "Select All",
                              value: "selectAll",
                            },
                            ...formattedCountriesList,
                          ]}
                          treeCheckable={true}
                          showCheckedStrategy={SHOW_PARENT}
                          placeholder="Country"
                          style={style.countryListTree}
                        />
                        {(errorMessage.country && (
                          <Validation message={errorMessage.country} />
                        )) ||
                          ""}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[40, 40]}>
                    <Col span={12}>
                      {/* -----------------------------Select Language Input-------------------------------- */}
                      <Form.Item
                        className="learning-path-create"
                        label={
                          <label
                            style={{
                              fontFamily: "RakutenRoundRegular",
                              fontSize: "14px",
                            }}
                          >
                            Language
                          </label>
                        }
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                        required
                        requiredMark
                      >
                        <Select
                          value={selectedLanguage || null}
                          allowClear
                          style={{
                            width: "100%",
                          }}
                          placeholder="Coures language"
                          onChange={(value) => {
                            handleLanguage(value);
                          }}
                          options={languages}
                        />
                        {(errorMessage.language && (
                          <Validation message={errorMessage.language} />
                        )) ||
                          ""}
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      {/* -----------------------------Select catagory Input-------------------------------- */}
                      <Form.Item
                        className="learning-path-create"
                        label={
                          <label
                            style={{
                              fontFamily: "RakutenRoundRegular",
                              fontSize: "14px",
                            }}
                          >
                            Learning Skills
                          </label>
                        }
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                        required
                        requiredMark
                      >
                        <Select
                          value={selectedParentCategory}
                          mode="multiple"
                          allowClear
                          style={{
                            width: "100%",
                          }}
                          placeholder="Select the learning skill path"
                          onChange={(selectedParentCategory) => {
                            handleSkillType(selectedParentCategory);
                          }}
                          options={parentCategory}
                        />
                        {(errorMessage.selectedParentCategory && (
                          <Validation
                            message={errorMessage.selectedParentCategory}
                          />
                        )) ||
                          ""}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col
                  span={6}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {(imageUrl && (
                    <>
                      {(!archived && (
                        <span
                          className={style.editButton}
                          onClick={() => setShowImageModal(true)}
                        >
                          <img
                            src={edit}
                            alt=""
                            style={{
                              height: "1.2rem",
                              marginLeft: "0.25rem",
                            }}
                          />
                        </span>
                      )) ||
                        ""}
                      <Image
                        className={style.biggerSvgBox}
                        src={imageUrl}
                        preview={false}
                        onClick={() => setShowImageModal(true)}
                      />
                    </>
                  )) || (
                      <Row
                        className={style.biggerSvgBox}
                        onClick={() => setShowImageModal(true)}
                      >
                        <Image
                          src={uploadImage}
                          alt=""
                          className={style.biggerSvg}
                          preview={false}
                        />
                        <p className={style.imageText}>
                          The Image will add choice of Image tags
                        </p>
                      </Row>
                    )}

                  {showImageModal && (
                    <ImageSelectionModal
                      showModal={showImageModal}
                      handleClose={() => setShowImageModal(false)}
                      fetchImageName={(imageName) => {
                        setImageName(imageName);
                        setShowImageModal(false);
                      }}
                    />
                  )}
                </Col>
              </Row>
              {showAddCourseDiv ? (
                <LearningPathBodyContents
                  leftpane={
                    <>
                      {renderPathCourses()}
                      {renderPathDescription()}
                      {renderPreRequisites()}
                    </>
                  }
                  rightpane={
                    <>
                      {renderPathObjectives()}
                      {renderTargetedAudience()}
                      {renderSearchTags()}
                    </>
                  }
                />
              ) : (
                <LearningPathBodyContents
                  leftpane={<>{renderPathCourses()}</>}
                  rightpane={
                    <>
                      {renderPathObjectives()}
                      {renderPathDescription()}
                      {renderTargetedAudience()}
                    </>
                  }
                  footer={
                    <>
                      {renderPreRequisites()}
                      {renderSearchTags()}
                    </>
                  }
                />
              )}
              {renderAdminNotes()}
              {renderAddCourseButtonHandlers()}
            </Form>
          </div>
        )}
        {showRemoveCourseConfirmationModal &&
          filterCourse(finalCourseList).length > 0 && (
            <Modal
              title="Delete confirmation"
              open={true}
              onOk={() => { }}
              onCancel={() => setShowRemoveCourseConfirmationModal(false)}
              footer={false}
            >
              <p style={{ fontFamily: "RakutenRoundSemiBold" }}>
                Following courses will be deleted by this change.
              </p>
              {filterCourse(finalCourseList).map((course) => (
                <div
                  style={{
                    border: "1px solid lightgray",
                    marginTop: "1rem",
                    padding: "1rem",
                    borderRadius: "4px",
                  }}
                >
                  <DraggableItem item={course} />
                </div>
              ))}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "1rem",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  onClick={() => setShowRemoveCourseConfirmationModal(false)}
                  style={{
                    backgroundColor: "#828282",
                    borderRadius: "0.25rem",
                    fontFamily: "RakutenRoundSemiBold",
                    fontSize: "1rem",
                    color: "#ffffff",
                    padding: "0.7rem 1.5rem",
                    border: "none",
                    textTransform: "uppercase",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "2rem",
                  }}
                >
                  Reject
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    if (tempCountryList.length < 1) {
                      setShowRemoveCourseConfirmationModal(false);
                      handleCountryChange(tempCountryList);
                      setFinalCourseList([]);
                      setSelectedILCourseList([]);
                      setselectedELCourseList([]);
                      setAllSelectedCourses([]);
                      setShowAddCourseDiv(true);
                    } else {
                      handleCountryChange(tempCountryList);
                      setFinalCourseList(filterCourseByRegion(finalCourseList));
                      setSelectedILCourseList(
                        filterCourseByRegion(finalCourseList).filter(
                          (course) => course.courseType === "IL"
                        )
                      );
                      setselectedELCourseList(
                        filterCourseByRegion(finalCourseList).filter(
                          (course) => course.courseType === "EL"
                        )
                      );
                      setAllSelectedCourses(
                        finalSelectedCourses(
                          filterCourseByRegion(finalCourseList)
                        )
                      );
                      setShowRemoveCourseConfirmationModal(false);
                    }
                  }}
                  style={{
                    backgroundColor: "#00a0f0",
                    borderRadius: "0.25rem",
                    fontFamily: "RakutenRoundSemiBold",
                    fontSize: "1rem",
                    color: "#ffffff",
                    padding: "0.7rem 1.5rem",
                    border: "none",
                    textTransform: "uppercase",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Confirm
                </Button>
              </div>
            </Modal>
          )}

        {showPrivateCourseModal &&
          filterPrivateCourse(finalCourseList, false).length > 0 && (
            <Modal
              title="Delete confirmation"
              open={true}
              onOk={() => { }}
              onCancel={() => {
                setIsPrivate(true);
                setShowPrivateCourseModal(false);
              }}
              footer={false}
            >
              <p style={{ fontFamily: "RakutenRoundSemiBold" }}>
                Following courses will be deleted by this change.
              </p>
              {filterPrivateCourse(finalCourseList, false).map((course) => (
                <div
                  style={{
                    border: "1px solid lightgray",
                    marginTop: "1rem",
                    padding: "1rem",
                    borderRadius: "4px",
                  }}
                >
                  <DraggableItem item={course} />
                </div>
              ))}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "1rem",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  onClick={() => {
                    setIsPrivate(true);
                    setShowPrivateCourseModal(false);
                  }}
                  style={{
                    backgroundColor: "#828282",
                    borderRadius: "0.25rem",
                    fontFamily: "RakutenRoundSemiBold",
                    fontSize: "1rem",
                    color: "#ffffff",
                    padding: "0.7rem 1.5rem",
                    border: "none",
                    textTransform: "uppercase",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "2rem",
                  }}
                >
                  Reject
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    setFinalCourseList(filterPrivateCourse(finalCourseList));
                    setSelectedILCourseList(
                      filterPrivateCourse(finalCourseList).filter(
                        (course) => course.courseType === "IL"
                      )
                    );
                    setselectedELCourseList(
                      filterPrivateCourse(finalCourseList).filter(
                        (course) => course.courseType === "EL"
                      )
                    );
                    setAllSelectedCourses(
                      finalSelectedCourses(filterPrivateCourse(finalCourseList))
                    );
                    setIsPrivate(false);
                    setShowPrivateCourseModal(false);
                    // }
                  }}
                  style={{
                    backgroundColor: "#00a0f0",
                    borderRadius: "0.25rem",
                    fontFamily: "RakutenRoundSemiBold",
                    fontSize: "1rem",
                    color: "#ffffff",
                    padding: "0.7rem 1.5rem",
                    border: "none",
                    textTransform: "uppercase",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Confirm
                </Button>
              </div>
            </Modal>
          )}
      </div>
    </>
  );
}
