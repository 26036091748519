import React, { useState, useCallback, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import style from "./userSearchBar.module.css";
import "./UserSearchBar.css";
import searchButton from "../../assets/icons/searchIcon.svg";
import closeButton from "../../assets/icons/x.svg";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import UtilityFunctions from "../../utils/utility";
import { decodeHtmlEntities } from "../../utils/commonUtility";
import config from "../../config";
import { UdemyContext } from "../../context/UdemyContext";

export default function UserSearchBar({ hasImage }) {

  const { isEnabled } = useContext(UdemyContext)
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const [globalSearchKey, setGlobalSearchKey] = useState(
    query.get("course_name") || ""
  );
  const [ilSearchResults, setIlSearchResults] = useState([]);
  const [elSearchResults, setElSearchResults] = useState([]);
  const [lpSearchResults, setLpSearchResults] = useState([]);
  const [udemySearchResults, setUdemySearchResults] = useState([]);
  const [showGlobalSearchDropdown, setShowGlobalSearchDropdown] =
    useState(false);
  const inputRef = useRef();
  const searchResultsContainerRef = useRef();
  const searchBoxWrapperRef = useRef();
  let isSearchPage = false;

  if (document?.location?.pathname !== "/home") hasImage = false;
  if (document?.location?.pathname === "/Search") isSearchPage = true;

  useEffect(() => {
    if (!isSearchPage) setGlobalSearchKey("");
  }, [isSearchPage]);

  const globalSearchChangeHandler = (event) => {
    if (event?.target?.value) {
      const instance = axios.create();
      //ToDo call APIs from a separate API file
      instance
        .get("course/search", {
          params: { course_name: event?.target?.value },
        })
        .then((res) => {
          if (res?.data?.data?.length > 0) {
            const elCourses = [];
            const ilCourses = [];
            const learningPathlist = [];
            res?.data?.data.forEach((current) => {
              if (current?.type === "instructorLed") {
                ilCourses.push(current);
              } else if (current?.type === "eLearning") {
                elCourses.push(current);
              } else if (current?.type === "learningPath") {
                learningPathlist.push(current);
              }
            });
            if (elCourses?.length > 0) {
              setElSearchResults(elCourses);
            } else {
              setElSearchResults([{ name: "No courses found!" }]);
            }
            if (ilCourses?.length > 0) {
              setIlSearchResults(ilCourses);
            } else {
              setIlSearchResults([{ course_name: "No courses found!" }]);
            }
            if (learningPathlist?.length > 0) {
              setLpSearchResults(learningPathlist);
            } else {
              setLpSearchResults([{ name: "No learning paths found!" }]);
            }
          } else {
            setLpSearchResults([{ name: "No learning paths found!" }]);
            setElSearchResults([{ name: "No courses found!" }]);
            setIlSearchResults([{ course_name: "No courses found!" }]);
          }
        })
        .catch(function (error) {
          setLpSearchResults([{ name: "No learning paths found!" }]);
          setElSearchResults([{ name: "No courses found!" }]);
          setIlSearchResults([{ course_name: "No courses found!" }]);
        });
      if (isEnabled) {
        setUdemySearchResults([{ course_name: "Fetching results..." }])
        axios.get(`${config.endPoint.elearning}/course?courseProviderName=udemy&courseName=${event?.target?.value}`)
          .then((res) => {
            if (res?.data?.data && res.data.data.length > 0) {
              const updatedUdemyCourses = res.data.data.map((val) => ({ id: val.id, course_name: val.name, type: 'udemy' }))
              setUdemySearchResults(updatedUdemyCourses);
            }
            else {
              setUdemySearchResults([{ course_name: 'No courses found!' }])
            }
          })
          .catch((err) => setUdemySearchResults([{ course_name: 'No courses found' }]))
      }
      setShowGlobalSearchDropdown(true);
    } else {
      setShowGlobalSearchDropdown(false);
    }
  };

  const emptySearch = () => {
    setGlobalSearchKey("");
    setShowGlobalSearchDropdown(false);
  };

  //ToDo resolve dependency issue
  const optimizedSearch = useCallback(
    UtilityFunctions.debounce(globalSearchChangeHandler, 1000),
    [isEnabled]
  );

  useEffect(() => {
    const handleClickOutsideBox = (event) => {
      if (
        !(
          searchResultsContainerRef.current?.contains(event.target) ||
          searchBoxWrapperRef.current?.contains(event.target)
        )
      ) {
        setShowGlobalSearchDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutsideBox);
    setTimeout(() => {
      inputRef?.current?.focus();
    }, config.expectedTimeOuts.halfSecond);
    return () => document.removeEventListener("click", handleClickOutsideBox);
  }, []);

  return (
    <>
      <div
        className={[
          `${hasImage ? style.searchActiveTransparent : style.searchActive
          } searchResultContainer`,
        ]}
        ref={searchBoxWrapperRef}
      >
        <div className={style.searchIcon}>
          <img
            src={searchButton}
            alt="Search Key"
            className={style.searchButtonIcon}
          />
        </div>
        <span className={style.searchInput}>
          <input
            ref={inputRef}
            id="searchCourses"
            className={style.searchInputText}
            type="text"
            value={globalSearchKey}
            placeholder="What do you want to learn?"
            autoComplete="off"
            onChange={(e) => {
              setGlobalSearchKey(e.target.value);
              optimizedSearch(e);
            }}
            onKeyDown={(e) => {
              if (globalSearchKey && e.key === "Enter") {
                history.push(`/Search?course_name=${e.target.value}`);
                setShowGlobalSearchDropdown(false);
              }
            }}
          />
        </span>
        {globalSearchKey?.length > 0 && (
          <span>
            <img
              alt=""
              src={closeButton}
              className={`${style.clearInput} ${style.closeButton}`}
              onClick={() => {
                emptySearch();
              }}
            ></img>
          </span>
        )}
      </div>
      <div ref={searchResultsContainerRef}>
        <div
          className={`searchResults ${showGlobalSearchDropdown ? "searchResultsLP" : "searchResultsHide"
            }`}
        >
          <h6 className="courseHead">LEARNING PATHS</h6>
          <hr />
          {globalSearchKey &&
            lpSearchResults.map((item) => {
              return (
                <div key={item.id}>
                  <Link
                    //ToDo check logic for having item.id condition as id will always be present
                    to={{
                      pathname: item.id ? `/learning-paths/${item.id}` : "#",
                    }}
                    className={`${style.pathLinkStyle} ${item.id ? style.cursorStyle : style.cursorDefault
                      }`}
                    onClick={(e) => {
                      if (item.id) {
                        emptySearch();
                      } else {
                        e.preventDefault();
                      }
                    }}
                  >
                    <p className={item.id ? "dataItem" : "dataItemDisabled"}>
                      {decodeHtmlEntities(item.name)}
                    </p>
                  </Link>
                </div>
              );
            })}
        </div>
        <div
          className={`searchResults ${showGlobalSearchDropdown ? "searchResultsEL" : "searchResultsHide"
            }`}
        >
          <h6 className="courseHead">E-LEARNING COURSES</h6>
          <hr />
          {globalSearchKey &&
            elSearchResults.map((item) => {
              return (
                <div key={item.id}>
                  <Link
                    //ToDo check logic for having item.id condition as id will always be present
                    to={{
                      pathname: item.id
                        ? `/e-learning-courses/${item.id}`
                        : "#",
                    }}
                    className={`${style.pathLinkStyle} ${item.id ? style.cursorStyle : style.cursorDefault
                      }`}
                    onClick={(e) => {
                      if (item.id) {
                        emptySearch();
                      } else {
                        e.preventDefault();
                      }
                    }}
                  >
                    <p className={item.id ? "dataItem" : "dataItemDisabled"}>
                      {decodeHtmlEntities(item.name)}
                    </p>
                  </Link>
                </div>
              );
            })}
        </div>
        <div
          className={`searchResults ${showGlobalSearchDropdown ? "searchResultsIL" : "searchResultsHide"
            }`}
        >
          <h6 className="courseHead">COURSES</h6>
          <hr />
          {globalSearchKey &&
            ilSearchResults.map((item) => {
              return (
                <div key={item.id}>
                  <Link
                    //ToDo check logic for having item.id condition as id will always be present
                    to={{
                      pathname: item.id ? `/course-details/${item.id}` : "#",
                    }}
                    className={`${style.pathLinkStyle} ${item.id ? style.cursorStyle : style.cursorDefault
                      }`}
                    onClick={(e) => {
                      if (item.id) {
                        emptySearch();
                      } else {
                        e.preventDefault();
                      }
                    }}
                  >
                    <p className={item.id ? "dataItem" : "dataItemDisabled"}>
                      {decodeHtmlEntities(item.course_name)}
                    </p>
                  </Link>
                </div>
              );
            })}
        </div>
        {isEnabled ? <div
          className={`searchResults ${showGlobalSearchDropdown ? "searchResultsUdemy" : "searchResultsHide"
            }`}
        >
          <h6 className="courseHead">UDEMY</h6>
          <hr />
          {globalSearchKey && udemySearchResults &&
            udemySearchResults.map((item) => {
              return (
                <div key={item.id}>
                  <Link
                    //ToDo check logic for having item.id condition as id will always be present
                    to={{
                      pathname: item.id ? `/e-learning-courses/udemy/${item.id}` : "#",
                    }}
                    className={`${style.pathLinkStyle} ${item.id ? style.cursorStyle : style.cursorDefault
                      }`}
                    onClick={(e) => {
                      if (item.id) {
                        emptySearch();
                      } else {
                        e.preventDefault();
                      }
                    }}
                  >
                    <p className={item.id ? "dataItem" : "dataItemDisabled"}>
                      {decodeHtmlEntities(item.course_name)}
                    </p>
                  </Link>
                </div>
              );
            })}
        </div> : null}
      </div>
    </>
  );
}

UserSearchBar.propTypes = {
  hasImage: PropTypes.bool,
};
