/**
 * Opens a given URL in a new browser tab.
 * @param {string} url - The URL to open.
 */
function openLinkInNewTab(url) {
    if (typeof url === 'string' && url.trim() !== '') {
        window.open(url, '_blank');
    } else {
        console.error('Invalid URL provided');
    }
}
export default openLinkInNewTab;