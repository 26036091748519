import React, { useState, useEffect } from "react";
import { Button, Col, Input, Modal, Table } from "antd";
import "./AntdgetMyTeamModal.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import config from "../../../config";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { SearchOutlined } from "@ant-design/icons";

function GetMyTeamModal({
  show,
  handleClose,
  showModal,
  setShowModal,
  recommendedData,
  teamData,
  setAlert,
  setAlertType,
}) {
  const teamUsersColumns = [
    {
      dataIndex: "id",
      title: "Employee Id",
      width: "2rem",
      ellipsis: "true",
      sorter: (a, b) => a.id - b.id,
    },
    {
      dataIndex: "firstName",
      title: "Name",
      width: "3rem",
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      render: (cell, row) => {
        return <>{`${row.firstName} ${row.lastName}`}</>;
      },
    },
    {
      dataIndex: "primaryEmail",
      title: "Email",
      width: "4rem",
      sorter: (a, b) => a.primaryEmail.localeCompare(b.primaryEmail),
    },
    {
      dataIndex: "designationTitle",
      title: "Role",
      width: "3rem",
      sorter: (a, b) => a.designationTitle.localeCompare(b.designationTitle),
    },
  ];
  const defaultSorted = [
    {
      dataIndex: "timeStamp",
      order: "asc",
    },
  ];
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [recommendedObj, setRecommendedObj] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(showModal);
  const [tableData, setTableData] = useState([]);
  const [bottom, setBottom] = useState("bottomCenter");
  const [listData, setListData] = useState([]);
  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    headerColumnStyle: {
      width: ".5rem",
      textAlign: "center",
    },
    onSelect: (row, isSelect, rowIndex) => {
      if (isSelect) {
        setSelectedUsers([...selectedUsers, row.id]);
      } else {
        const unSelectedUser = selectedUsers.filter((user) => user !== row.id);
        setSelectedUsers(unSelectedUser);
      }
    },
    getCheckboxProps: (record) => {
      if (recommendedData.some((data) => data.courseProvider === "udemy"))
        return {
          disabled: !record.enabled,
        }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        setSelectedUsers([...selectedUsers, ...rows?.map((row) => row.id)]);
      } else {
        const updateUsersList = selectedUsers.filter((user) => {
          return (
            rows.findIndex((row) => row.userEmail === user.userEmail) === -1
          );
        });
        setSelectedUsers(updateUsersList);
      }
    },
  };
  const [tableColumns] = useState(teamUsersColumns);

  useEffect(() => {
    updateRecommendedObj();
    // eslint-disable-next-line
  }, [selectedUsers]);

  const updateRecommendedObj = () => {
    const updatedData = recommendedData?.map((data) => ({
      ...data,
      recommendedTo: selectedUsers,
    }));
    setRecommendedObj(updatedData);
  };
  const history = useHistory();
  const handleClick = () => {
    const endPoint = `${config.endPoint.commonService}/recommendation`;
    axios
      .post(endPoint, recommendedObj)
      .then((res) => {
        history.push("/Manager/ViewYourRecommendation");
        setAlert("Recommendation has been added successfully");
        setAlertType("success");
      })
      .catch((error) => {
        setAlert(
          error.response.data.message || "Unable to create recommendation"
        );
        setAlertType("error");
      });
    setIsModalOpen(false);
  };
  const searchStyle = {
    position: "relative",
    top: "-3.25rem",
    left: "52.5rem",
    width: "16rem",
  };
  const handleSearch = (searchText) => {
    const filteredData = tableData.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchText.toLowerCase())
      )
    );
    if (searchText === "") {
      setListData(tableData);
    } else {
      setListData(filteredData);
    }
  };

  const downloadsearch = () => {
    return (
      <Col>
        <Col
          style={{
            paddingLeft: "50rem",
            marginBottom: "3rem",
            marginTop: "-1rem",
          }}
        >
          <Input
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search"
            suffix={<SearchOutlined />}
            className={"getmyteam-modal-search"}
            style={{
              marginTop: "1rem",
              width: " 10rem",
              fontSize: "1rem",
              fontFamily: "RakutenRoundRegular",
              borderRadius: "4px",
            }}
          />
        </Col>
      </Col>
    );
  };

  return (
    <div className="getmyteam-container">
      <Modal
        className="getmyteam-modal"
        title="Select your team members"
        centered
        width={"50rem"}
        open={isModalOpen}
        footer={null}
        onCancel={handleClose}
      >
        <div> {downloadsearch()}</div>
        <Table
          rowKey="id"
          //   loading={showLoader}
          columns={tableColumns}
          dataSource={teamData}
          rowSelection={selectRow}
          pagination={{
            position: [bottom],
            showQuickJumper: true,
          }}
        // rowClassName={(record, index) => {
        //   return record?.issues?.length > 0 ? "row-color" : null;
        // }}
        />
        <div>
          <Button
            className="recommendto-button"
            disabled={selectedUsers.length === 0}
            onClick={handleClick}
            type="button"
          >
            Recommend
          </Button>
        </div>
      </Modal>
    </div>
  );
}
export default GetMyTeamModal;
