import { useContext, useEffect, useState } from "react";
import { Col, Typography, Button, Select, DatePicker } from "antd";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { CourseContext } from "../../../context/CourseContext";
import "./reportsfilter.scss";
import {
  DownloadOutlined,
  SyncOutlined,
  CheckOutlined,
} from "@ant-design/icons";

const ReportsFilter = ({
  startDate,
  endDate,
  region,
  onStartDateChange,
  onEndDateChange,
  onRegionChange,
  onApply,
  onReset,
  onDownloadReport,
}) => {
  const { countryList } = useContext(CourseContext);
  const [countries, setCountries] = useState([]);
  const { RangePicker } = DatePicker;
  const defaultDates = [dayjs().subtract(30, "day"), dayjs()];

  useEffect(() => {
    let countryOptions = [];
    if (countryList && Array.isArray(countryList)) {
      countryOptions = countryList.map((country, index) => {
        return (countryOptions[index] = {
          value: country.code,
          label: country.country,
        });
      });
    }
    setCountries([{ label: "All region", value: "" }, ...countryOptions]);
  }, [countryList]);

  const handleDateChange = (date, dateString) => {
    onStartDateChange(dateString[0]);
    onEndDateChange(dateString[1]);
  };

  return (
    <div className={"teamreports-header"}>
      <div className={"teamreports-header2"}>
        <div className="reportfilter-Schedule-Container">
          <Col lg={3} xs={3}>
            <Typography data-testid="report" className="teamreports-pagetitle">
              Reports
            </Typography>
          </Col>
          <Col lg={7} xs={3}></Col>
          <Col lg={6} xs={4}>
            <div className="reportfilter-scheduleTime">
              <div style={{ width: "6rem" }}>Schedule Report:</div>
              <RangePicker
                defaultValue={defaultDates}
                format="YYYY-MM-DD"
                placeholder={["From", "To"]}
                onChange={handleDateChange}
                className="reportfilter-datePicker"
              />
            </div>
          </Col>
          <Col lg={3} xs={4}>
            <div className="reportfilter-region">
              <Select
                showSearch
                options={countries}
                value={region}
                placeholder="All Region"
                onChange={onRegionChange}
              />
            </div>
          </Col>
          <Col lg={2} xs={4}>
            <Button
              type="primary"
              icon={<CheckOutlined />}
              className="reportfilter-applyButton"
              onClick={onApply}
            >
              Apply
            </Button>
          </Col>
          <Col lg={2} xs={4}>
            <Button
              type="primary"
              icon={<SyncOutlined />}
              className="reportfilter-resetButton"
              onClick={onReset}
            >
              Reset
            </Button>
          </Col>
          <Col lg={2} xs={3}>
            <Button
              className="reportfilter-downloadCSVButton"
              type="primary"
              shape="circle"
              onClick={onDownloadReport}
              icon={<DownloadOutlined />}
              size="large"
            />
          </Col>
        </div>
      </div>
    </div>
  );
};

ReportsFilter.propTypes = {
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  region: PropTypes.any,
  onStartDateChange: PropTypes.func,
  onEndDateChange: PropTypes.func,
  onApply: PropTypes.func,
  onReset: PropTypes.func,
};

export default ReportsFilter;
