import React, { useEffect, useState } from "react";
import "./employeeManagement.scss";
import { Alert, Breadcrumb, Button, Modal } from "antd";
import EmployeeManagementTable from "./EmployeeManagementTable";
import {
  getCompanyList,
  getDepartmentList,
  getUserList,
} from "./employeeManagementApis";
import {
  companyTableColumns,
  departmentTableColumns,
  employeeTableColumns,
} from "./employeeTableColumns";
import { RiDeleteBin6Line } from "react-icons/ri";

import { getModalConfig } from "./modalConfig";
import EditModalBody from "./EditModalBody";
import config from "../../../config";
import axios from "axios";
import { writeFileXLSX, utils } from "xlsx";

import { IoMdDownload } from "react-icons/io";
import { startCase } from "lodash";

const crumbs = [
  {
    title: (
      <a href="/Admin" className="promotional-banner-crumbs">
        Admin Dashboard
      </a>
    ),
    to: "/Admin",
  },
  { title: "Employee Management" },
];

function EmployeeManagement() {
  const [tabItem, setTabItem] = useState("emp");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [userList, setUserList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [modifiedSelectedEmployee, setModifiedSelectedEmployee] =
    useState(null);
  const [deleteModalData, setDeleteModalData] = useState({ endpoint: null });
  const [searchPhrase, setSearchPhrase] = useState(null);
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [filteredDeptList, setFilteredDeptList] = useState([]);
  const [filteredCompanyList, setFilteredCompanyList] = useState([]);
  const [dataToDownload, setDataToDownload] = useState([]);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(false);
  const [alertMsg, setAlertMsg] = useState(null);

  useEffect(() => {
    if (tabItem === "emp" && searchPhrase) {
      const userListData = userList.filter((user) =>
        user.name.toLowerCase().includes(searchPhrase)
      );
      setFilteredUserList(userListData);
    } else {
      setFilteredUserList(userList);
    }

    if (tabItem === "department" && searchPhrase) {
      const deptListData = departmentList.filter((user) =>
        user.name.toLowerCase().includes(searchPhrase)
      );
      setFilteredDeptList(deptListData);
    } else {
      setFilteredDeptList(departmentList);
    }

    if (tabItem === "companyList" && searchPhrase) {
      const companyListData = companyList.filter((user) =>
        user.name.toLowerCase().includes(searchPhrase)
      );
      setFilteredCompanyList(companyListData);
    } else {
      setFilteredCompanyList(companyList);
    }
  }, [searchPhrase, userList, departmentList, companyList]);

  const getData = (forceUpdate) => {
    if (tabItem === "emp" && (userList.length < 1 || forceUpdate)) {
      getUserList().then((newUserList) => {
        setUserList(newUserList.data);
      });
    }

    if (
      tabItem === "department" &&
      (departmentList.length < 1 || forceUpdate)
    ) {
      getDepartmentList().then((newDepartmentList) => {
        setDepartmentList(newDepartmentList.data);
      });
    }

    if (tabItem === "companyList" && (companyList.length < 1 || forceUpdate)) {
      getCompanyList().then((newCompanyList) => {
        setCompanyList(newCompanyList.data);
      });
    }
  };

  useEffect(() => {
    getData();
    setDataToDownload([]);
    setSearchPhrase("");
  }, [tabItem]);

  const formatXlxsData = (list) => {
    if (tabItem === "emp") {
      return list.map((item) => {
        const [firstName, lastName] = item.name.split(" ");

        return {
          Emp_ID: item.id,
          "First Name": firstName,
          "Last Name": lastName || "",
          "Email Id": item.email,
          Company: item.company,
          Country: item.country,
          Department: item.department,
          "Joined Date": item.joinedDate,
          "Emp Type": item.empType,
          "Emp status": item.empStatus,
          "EMP Role": item.designation,
          "Manager Email": item.managerEmail,
        };
      });
    }

    if (tabItem === "department") {
      return list.map((item) => {
        return {
          Name: item.name,
          "Department Head Name": item.departmentheadName,
          "Department Head Email": item.departmentheadEmail,
          company: item.company,
          "company ID": item.companyID,
        };
      });
    }

    if (tabItem === "companyList") {
      return list.map((item) => {
        return {
          Name: item.name,
          Status: item.status,
          Country: item.country,
          Departments: item.departments
            .map((department) => department?.name)
            .join(", "),
        };
      });
    }
  };

  const renderBreadCrumbs = () => (
    <div style={{ marginTop: "8rem", marginBottom: "1rem" }}>
      <Breadcrumb items={crumbs} />
    </div>
  );

  const renderHeading = () => (
    <span className="em-heading">Employee Management</span>
  );

  const renderButtonBasedOnTabItem = () => {
    let buttonLabel;
    switch (tabItem) {
      case "emp":
        buttonLabel = "Add Employee";
        break;
      case "department":
        buttonLabel = "Add Department";
        break;
      case "companyList":
        buttonLabel = "Add Company";
        break;
      default:
        break;
    }

    return (
      <div>
        <Button
          className="em-tab-menu em-tab-button"
          onClick={() => setIsModalOpen(true)}
          type="primary"
          style={{ borderRadius: 0 }}
        >
          {buttonLabel}
        </Button>
      </div>
    );
  };

  const renderTabs = () => {
    return (
      <div className="em-tab-menu-container">
        {[
          { key: "emp", value: "Employee" },
          { key: "department", value: "Department" },
          { key: "companyList", value: "CompanyList" },
        ].map((item) => {
          const tabItemClassname = `em-tab-menu ${
            tabItem === item.key && "em-selected-tab-menu"
          }`;

          return (
            <span
              className={tabItemClassname}
              onClick={() => {
                setTabItem(item.key);
                setCurrentPage(1);
              }}
            >
              {item.value}
            </span>
          );
        })}
        <input
          placeholder="Search..."
          className="em-tab-menu"
          value={searchPhrase}
          onChange={(e) => setSearchPhrase(e.target.value.toLowerCase())}
          style={{ cursor: "text" }}
        />
        {renderButtonBasedOnTabItem()}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {dataToDownload.length > 0 && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                icon={<IoMdDownload />}
                style={{ borderRadius: 0, marginRight: "0.25rem" }}
                onClick={() => {
                  const wbName =
                    tabItem === "emp"
                      ? "user_list"
                      : tabItem === "department"
                      ? "department_list"
                      : "company_list";
                  let ws = utils.json_to_sheet(formatXlxsData(dataToDownload));
                  let wb = utils.book_new();
                  utils.book_append_sheet(wb, ws, "list");
                  writeFileXLSX(wb, `${wbName}.xlsx`);
                }}
              >
                Download
              </Button>
              {tabItem === "emp" && (
                <Button
                  icon={<RiDeleteBin6Line />}
                  style={{ borderRadius: 0 }}
                  onClick={() => setShowBulkDeleteModal(true)}
                >
                  Delete
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const onEdit = (row) => {
    setShowEditModal(true);
    setSelectedEmployee(row);
    setModifiedSelectedEmployee(row);
  };

  const getDeleteEndpoint = (row, tableName) => {
    let endpoint = config.endPoint.userService;
    const { id } = row;
    switch (tableName) {
      case "user":
        endpoint = endpoint + "/api/user/" + id;
        break;
      case "dept":
        endpoint = endpoint + "/api/departments/" + id;
        break;
      case "company":
        endpoint = endpoint + "/api/companies/" + id;
        break;
      default:
        break;
    }
    return endpoint;
  };

  const onDelete = (row, tableName) => {
    const deleteEndpoint = getDeleteEndpoint(row, tableName);
    setDeleteModalData({ endpoint: deleteEndpoint });
  };

  //   move this logic to renderButtonBasedOnTabItem fn and update fn name
  const getTableDatasource = () => {
    let tableData = [];
    let tableColumns = [];

    switch (tabItem) {
      case "emp":
        tableData = filteredUserList;
        tableColumns = employeeTableColumns(
          onEdit,
          onDelete,
          filteredUserList,
          updateTableData
        );
        break;
      case "department":
        tableData = filteredDeptList;
        tableColumns = departmentTableColumns(
          onEdit,
          onDelete,
          filteredDeptList,
          updateTableData
        );
        break;
      case "companyList":
        tableData = filteredCompanyList;
        tableColumns = companyTableColumns(
          onEdit,
          onDelete,
          filteredCompanyList,
          updateTableData
        );
        break;
      default:
        break;
    }
    return { tableData, tableColumns };
  };

  const renderTableData = () => {
    const { tableData, tableColumns } = getTableDatasource();

    return (
      <EmployeeManagementTable
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        tableData={tableData}
        tableColumns={tableColumns}
        setDataToDownload={setDataToDownload}
      />
    );
  };

  const updateTableData = () => {
    if (tabItem === "emp") {
      getUserList().then((newUserList) => {
        setUserList(newUserList.data);
      });
    }

    if (tabItem === "department") {
      getDepartmentList().then((newDepartmentList) => {
        setDepartmentList(newDepartmentList.data);
      });
      getUserList().then((newUserList) => {
        setUserList(newUserList.data);
      });
    }

    if (tabItem === "companyList") {
      getCompanyList().then((newCompanyList) => {
        setCompanyList(newCompanyList.data);
      });
      getDepartmentList().then((newDepartmentList) => {
        setDepartmentList(newDepartmentList.data);
      });
      getUserList().then((newUserList) => {
        setUserList(newUserList.data);
      });
    }
  };

  const renderAddModal = () => {
    const { title, body } = getModalConfig(
      tabItem,
      setIsModalOpen,
      updateTableData,
      isModalOpen
    );

    return (
      <Modal
        title={title}
        open={isModalOpen}
        onOk={() => {
          setIsModalOpen(false);
        }}
        onCancel={() => setIsModalOpen(false)}
        width={tabItem === "emp" ? 1100 : 700}
        footer={false}
      >
        {body}
      </Modal>
    );
  };

  const renderEditModal = () => {
    return (
      <Modal
        title={"Edit Modal"}
        open={showEditModal}
        width={600}
        onOk={() => {
          setShowEditModal(false);
          setModifiedSelectedEmployee(null);
        }}
        onCancel={() => {
          setShowEditModal(false);
        }}
        footer={false}
      >
        <EditModalBody
          tabItem={tabItem}
          row={selectedEmployee}
          setShowEditModal={setShowEditModal}
          updateTableData={updateTableData}
        />
      </Modal>
    );
  };

  const renderDeleteModal = () => {
    return (
      <Modal
        title="Delete Confirmation"
        open={deleteModalData.endpoint}
        onCancel={() => setDeleteModalData({ endpoint: null })}
        footer={false}
      >
        <span>Do you want to delete this record?</span>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
          }}
        >
          <Button
            style={{
              margin: 8,
              borderRadius: 0,
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
            onClick={() => setDeleteModalData({ endpoint: null })}
          >
            Cancel
          </Button>
          <Button
            style={{
              margin: 8,
              borderRadius: 0,
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
            type="primary"
            className="common-button-background-color"
            onClick={async () => {
              try {
                const deleteResponse = await axios.delete(
                  deleteModalData.endpoint
                );

                if (deleteResponse.data.success) {
                  // Add success notification
                  getData(true);
                }
              } catch (e) {
                if (e.response) {
                  setAlertMsg(e.response?.data?.message);
                } else {
                  setAlertMsg("Something went wrong.");
                }
              }
              setDeleteModalData({ endpoint: null });
            }}
          >
            Confirm
          </Button>
        </div>
      </Modal>
    );
  };

  const renderBulkDeleteModal = () => {
    return (
      <Modal
        title="Delete Confirmation"
        open={showBulkDeleteModal && tabItem === "emp"}
        onCancel={() => setShowBulkDeleteModal(false)}
        footer={false}
      >
        <span>Do you want to delete below records?</span>
        <div style={{ maxHeight: 320, overflow: "scroll" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    width: "50px",
                    textAlign: "left",
                  }}
                >
                  ID
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    width: "100px",
                    textAlign: "left",
                  }}
                >
                  Name
                </th>
              </tr>
            </thead>
            <tbody>
              {dataToDownload.map(({ id, name }) => (
                <tr key={id}>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      width: "50px",
                      textAlign: "left",
                    }}
                  >
                    {id}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      width: "100px",
                      textAlign: "left",
                    }}
                  >
                    {name}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
          }}
        >
          <Button
            style={{
              margin: 8,
              borderRadius: 0,
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
            onClick={() => setShowBulkDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button
            style={{
              margin: 8,
              borderRadius: 0,
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
            type="primary"
            className="common-button-background-color"
            onClick={async () => {
              const deleteEndpoint = `${config.endPoint.userService}/api/user`;
              const deleteIds = dataToDownload.map((user) => user.id);
              const payload = {
                ids: deleteIds,
              };
              const deleteResponse = await axios.delete(deleteEndpoint, {
                data: payload,
              });
              if (deleteResponse.data.status) {
                // Add success notification
                getData(true);
              }
              setShowBulkDeleteModal(false);
            }}
          >
            Confirm
          </Button>
        </div>
      </Modal>
    );
  };

  const renderAlertMessage = () => {
    return (
      alertMsg && (
        <Alert
          closable
          message={alertMsg}
          type="error"
          onCloseAlert={() => setAlertMsg(null)}
        />
      )
    );
  };

  return (
    <div className="parent-container">
      {renderBreadCrumbs()}
      {renderAlertMessage()}
      {renderHeading()}
      {renderTabs()}
      {renderTableData()}
      {renderAddModal()}
      {showEditModal && renderEditModal()}
      {deleteModalData.endpoint && renderDeleteModal()}
      {showBulkDeleteModal && tabItem === "emp" && renderBulkDeleteModal()}
    </div>
  );
}

export default EmployeeManagement;
