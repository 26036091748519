import React from "react";
import Alert from "antd/es/alert";
import Layout from "antd/es/layout/layout";
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import { useHistory } from 'react-router-dom'
import InfiniteScroll from "react-infinite-scroll-component";

import { CourseFilter, CustomSelect, HeroBannerCousre, LearningBySkills, Quotation } from "../../../component-library";
import UdemyCourseTile from "../home/udemyCourseTile/UdemyCourseTile";
import ElearningCourseTile from "../home/elearningCourseTile/ElearningCourseTile";

import config from "../../../config";

import "./ElearningUserCourseList.scss";
import useElearningUserCourseList from "./useElearningUserCourseList";
import PageFooter from "../common/PageFooter";
import CustomSearch from "../../../component-library/customSearch/CustomSearch";



const crumbs = [
    {
        title: "Home",
        to: "/home",
    },
    { title: "E-Learning Courses" },
];


const UdemyCourseFilter = ({ handleFilter }) => {
    const inlineStyleSelect = {
        control: (base) => ({
            ...base,
            borderColor: "#000",
            borderRadius: "2px",
            height: "2.5rem",
            fontSize: ".8125rem",
            width: "200px",
        }),
    };
    return <Row justify={"space-between"} align={"middle"}>
        <Col span={4}>
            <CustomSearch handleSearch={(value) => handleFilter({ "search": value })} />
        </Col>
        <Col span={4}>
            <CustomSelect
                options={[{ label: 'NEWEST', value: 'newest' }, { label: 'RELEVANT', value: 'relevance' }]}
                placeholder="SORT"
                selected={{ label: 'NEWEST', value: 'newest' }}
                handleDropdown={(values) => handleFilter({ "ordering": values.value })}
                inlineStyleSelect={inlineStyleSelect}
            />
        </Col>
    </Row>
}

const Filters = ({ handleFilter, filters, reset, isInstructorLed, parentCategoryId }) => {
    switch (parentCategoryId) {
        case "udemy":
            return <UdemyCourseFilter handleFilter={handleFilter} />
        default:
            return <CourseFilter
                upliftFilters={handleFilter}
                filters={filters}
                reset={reset}
                isInstuctorLed={isInstructorLed}
            />
    }
}

const TypographyTypes = {
    loading: "loading", endOfList: "endOfList", unavailable: "unavailable",
    totalCount: 'totalCount'
}

const PageTypography = ({ feedbackType, text, isMobile }) => {
    switch (feedbackType) {
        case TypographyTypes.loading:
            return <div
                style={{
                    fontFamily: "RakutenRoundRegular",
                    fontSize: 24,
                    margin: isMobile ? "1rem" : "",
                    textAlign: "center",
                }}
            >
                <b>{text}</b>
            </div>
        case TypographyTypes.endOfList:
            return <span
                style={{
                    fontFamily: "RakutenRoundRegular",
                    fontSize: 24,
                    textAlign: "center",
                }}
            >
                <b>
                    {text}
                </b>
            </span >
        case TypographyTypes.totalCount:
            return <div style={{ marginBottom: "2rem" }} className="el-filter-container">
                <div style={{ marginBottom: "2rem", paddingTop: "1rem" }}>
                    <span className={"countText"} dangerouslySetInnerHTML={{ __html: text }}>
                    </span>
                </div>
            </div>
        case TypographyTypes.unavailable:
        default:
            return <div>
                {text}
            </div>


    }
}

const CourseTypes = {
    "udemy": "udemy",
    "rlms": "rlms"
}

const CourseList = ({ courseList, onCardClicked }) => {
    const history = useHistory();
    return courseList.map((current, index) => (
        <div
            style={{
                width: "19.5rem",
                marginBottom: "1.5rem",
                marginRight: (index + 1) % 4 === 0 ? 0 : "1.2rem",
            }}
            key={index}
        >
            {current.courseProvider === CourseTypes.udemy ? <UdemyCourseTile
                dataElement={current}
                index={index} cardClicked={() => history.push(`/e-learning-courses/udemy/${current.id}`)} /> :
                <ElearningCourseTile
                    dataElement={current}
                    index={index}
                    coursePath={`/e-learning-courses/${current.id}`}
                />
            }
        </div>
    ))
}


const ElearningUserCourseList = () => {
    const { handleFilter, handleNext, setIsModalVisible, setAlert, constants, courseList, isMobile, isModalVisible
        , mobileStyles, page, reset, hasMoreCourses, updateSuperCatergory, parentCategoryId, loading } = useElearningUserCourseList()
    return (
        <React.Fragment>
            <HeroBannerCousre
                crumbs={crumbs}
                heroBanner={config.elearningCourseList.heroBanner}
                title={config.elearningCourseList.title}
                description={config.elearningCourseList.description}
            />

            <div className="elearning-common-container">
                <Alert
                    message={alert}
                    type={"danger"}
                    onCloseAlert={() => setAlert("")}
                />
                <PageTypography feedbackType={TypographyTypes.totalCount} text={constants.totalCount((courseList && Array.isArray(courseList) ? courseList.length : 0).toString())} isMobile={isMobile} />
                <div style={{ marginBottom: "2rem" }} className="el-filter-container">
                    <Filters filters={page.filters} handleFilter={handleFilter} reset={reset} isInstructorLed={false} parentCategoryId={parentCategoryId} />
                </div>
                {(loading && !hasMoreCourses) ? <PageTypography feedbackType={TypographyTypes.loading} text={constants.loading} /> :
                    <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            width: "84rem",
                            margin: "auto",
                            ...mobileStyles,
                        }}
                    >
                        {courseList.length > 0 ?
                            <InfiniteScroll
                                className={"el-list-InfiniteScroll"}
                                dataLength={courseList.length}
                                next={handleNext}
                                hasMore={hasMoreCourses}
                                style={{
                                    ...mobileStyles,
                                }}
                                scrollThreshold={0.4}
                            >
                                {courseList && courseList.length ? (
                                    <CourseList courseList={courseList} onCardClicked={() => setIsModalVisible(true)} />
                                ) : (
                                    <PageTypography feedbackType={TypographyTypes.unavailable} text={constants.courseUnavailable} />
                                )}
                            </InfiniteScroll> : <React.Fragment></React.Fragment>
                        }
                        <PageTypography feedbackType={(hasMoreCourses) ? TypographyTypes.loading : TypographyTypes.endOfList} text={hasMoreCourses ? constants.loading : constants.endOfList} />
                    </div>}
                <div
                    style={{
                        backgroundColor: "white",
                        margintop: "120px",
                    }}
                >
                    <Layout
                        bsPrefix={`container ${"container"}`}
                        style={{
                            backgroundColor: "white",
                            marginLeft: window.innerWidth > 786 ? "3.5rem" : "0.5rem",
                        }}
                    >
                        <div
                            className={"el-secondContainer"}
                            style={{
                                marginTop: "120px",
                            }}
                        >
                            <LearningBySkills
                                changeSuperCatergory={(id) => updateSuperCatergory(id)}
                            />
                            <div className={"el-quotation"}>
                                <Quotation />
                            </div>
                        </div>
                    </Layout>
                </div>
                {window.innerWidth < 786 && <PageFooter />}
            </div>
        </React.Fragment >
    )
}
export default ElearningUserCourseList;