import { useEffect, useState } from "react";
import { Row } from "antd";
import axios from "axios";
import StatsTile from "../../../component-library/StatsTile";
import config from "../../../config";
import "./coursedetail.scss";

export default function UserCourseInsights({
  courseType,
  userId,
  setAlert,
  setAlertType,
}) {
  const [allCourseStats, setAllCourseStats] = useState({});
  const [ILCourseStats, setILCourseStats] = useState({});
  const [ELCourseStats, setELCourseStats] = useState({});

  useEffect(() => {
    getUserCourseInsight();
    // eslint-disable-next-line
  }, []);

  const getUserCourseInsight = async () => {
    try {
      let { data } = await axios(
        `${config.endPoint.commonService}/stats/courseStats/${userId}`
      );
      let { overallStats, elearningCourseStats, ilCourseStats } =
        data?.data || {};
      setAllCourseStats(overallStats);
      setILCourseStats(ilCourseStats);
      setELCourseStats(elearningCourseStats);
    } catch (error) {
      try {
        setAlert && setAlert(error?.response?.data?.message);
      } catch (e) {
        setAlert && setAlert("Something went wrong, Please try again.");
      } finally {
        setAlertType && setAlertType("error");
      }
    }
  };

  const getStatsTiles = (filterType) => {
    let allStatsList = config.userCourseInsights.overAll.map((val, i) => {
      let insightHtml = null;
      if (val?.secondaryKey && val?.insightHtml) {
        insightHtml = `<span>${allCourseStats[val.secondaryKey]}%</span> ${
          val.insightHtml
        }`;
      }
      return (
        <StatsTile
          label={val.label}
          value={allCourseStats && allCourseStats[val?.key]}
          insight={insightHtml}
          key={`all-${i}`}
          data-testid="stats-tile"
        />
      );
    });

    if (filterType === "IL") {
      let ilStatsList = config.userCourseInsights.IL.map((val, i) => {
        let insightHtml = null;
        if (val?.secondaryKey && val?.insightHtml) {
          insightHtml = `<span>${ILCourseStats[val.secondaryKey]}%</span> ${
            val.insightHtml
          }`;
        }
        return (
          <StatsTile
            label={val.label}
            value={ILCourseStats && ILCourseStats[val?.key]}
            insight={insightHtml}
            key={`il-${i}`}
            courseType={"IL"}
            data-testid="stats-tile"
          />
        );
      });
      return allStatsList.concat(ilStatsList);
    }
    if (filterType === "EL") {
      let elStatslist = config.userCourseInsights.EL.map((val, i) => {
        let insightHtml = null;
        if (val?.secondaryKey && val?.insightHtml) {
          insightHtml = `<span>${ELCourseStats[val.secondaryKey]}%</span> ${
            val.insightHtml
          }`;
        }
        return (
          <StatsTile
            label={val.label}
            value={ELCourseStats && ELCourseStats[val?.key]}
            insight={insightHtml}
            key={`el-${i}`}
            courseType={"EL"}
            data-testid="stats-tile"
          />
        );
      });
      return allStatsList.concat(elStatslist);
    }
  };

  return (
    <Row className="tile-grid-style" data-testid="tile-grid">
      {courseType && getStatsTiles(courseType)}
    </Row>
  );
}
