import InfiniteScroll from "react-infinite-scroll-component";
import { Row, Col, Alert, Breadcrumb, Tabs, Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons"
import config from "../../../config";
import { CourseFilter } from "../../../component-library";
import "./AntdSelectCourses.scss";
import GetMyTeamModal from "../viewYourRecommendation/AntdGetMyTeamModal";
import TabbedFilter from "../../../component-library/antDesignTabbedFilter/antDesignTabbedFilter";
import useCourseRecommendation from "./useCourseRecommendation";
import React from "react";

const crumbs = [
  {
    title: (
      <a href="/Manager" style={{ color: "#00A0F0" }}>
        Manager Dashboard
      </a>
    ),
  },
  {
    title: (
      <a href="/Manager/ViewYourRecommendation" style={{ color: "#00A0F0" }}>
        View Your Recommendation
      </a>
    ),
  },
  {
    title: "Select Courses",
  },
];


const NavigationAndSearch = ({ filters, onPageFilterChange, handleSearch, handleClick, }) => {
  return <Row gutter={64} align={"middle"}>
    <Col span={7}></Col>
    <Col span={10}>
      <Tabs
        onChange={(e) => onPageFilterChange(e)}
        className={"selectcourses-tabs"}
        items={filters}
      />
    </Col>
    <Col span={7}>
      <Row align={"middle"} >
        <Col span={12}>
          <Input
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search"
            suffix={<SearchOutlined />}
            className={"notificationSearch"}
            style={{
              height: "2.7rem",
              width: "10rem",
              fontSize: "1rem",
              fontFamily: "RakutenRoundRegular",
              borderRadius: "4px",
            }}
          />
        </Col>
        <Col span={12}>
          <Button
            type="button"
            className="recommendto-button"
            onClick={handleClick}
            style={{ marginTop: '0px!important' }}
          >
            Recommend To
          </Button>
        </Col>
      </Row>
    </Col>
  </Row>
}

const Filters = ({ currentTab, handleFilter, page, parentCatagoryList }) => {
  return <Row style={{ width: '100%' }}>
    <Col span={24}>
      {currentTab.key === "3" ?
        <Row style={{ width: '100%' }}>
          <Col style={{ margin: '2.375rem 0 0.538rem 0' }} span={24}>
            <TabbedFilter
              handleCatagoryChange={(key) => handleFilter({ learningPathCategory: key })}
              catagories={parentCatagoryList}
              defaultTab={page.subFilters?.learningPathCategory}
              tabBarGutter={92}
            />
          </Col>
        </Row>
        :
        <CourseFilter
          upliftFilters={handleFilter}
          filters={page.subFilters}
          reset={false}
          isInstuctorLed={currentTab.key === "1"}
          isRecommendation={true}
        />
      }
    </Col>
  </Row>
}
const CourseList = ({ currentTab, courseList, checkedState, handleCardClick, handleNext, page }) => {
  return <Row style={{ width: '100%' }}>
    <Col span={24}>
      <InfiniteScroll
        dataLength={courseList.length}
        next={handleNext}
        hasMore={page?.hasMore}
        style={{ width: '100%' }}
      >
        <Row gutter={[0, 24]}>
          {courseList && courseList.length ? (
            courseList.map((current, index) => {
              const Component = current.courseProvider === "udemy" ? currentTab.component2 : currentTab.component;
              return currentTab.key === "3" ?
                <Col span={12}>
                  <Component
                    dataElement={current}
                    index={index}
                    coursePath={currentTab.coursePath(current.id)}
                    tileType={currentTab.tileType}
                    checkedILState={checkedState}
                    checkedState={checkedState}
                    cardClicked={handleCardClick}
                    currentLearningPath={current}
                    color="#FFFFFF"
                    showCheckIcon={true}
                    isNewTab={true}
                    checkedLPState={checkedState}
                  />
                </Col>
                :
                <Col span={6}>
                  <Component
                    dataElement={current}
                    index={index}
                    coursePath={currentTab.coursePath(current.id)}
                    tileType={currentTab.tileType}
                    checkedILState={checkedState}
                    checkedState={checkedState}
                    cardClicked={handleCardClick}
                    currentLearningPath={current}
                    color="#FFFFFF"
                    showCheckIcon={true}
                    isNewTab={true}
                    checkedLPState={checkedState}
                  />
                </Col>
            })
          ) : (
            <div>
              No course available in your region for the category selected.
            </div>
          )}
        </Row>
      </InfiniteScroll>
    </Col>
  </Row >
}
const LoadMoreText = ({ hasMore }) => {
  return <div
    style={{
      fontFamily: "RakutenRoundRegular",
      fontSize: 24,
      margin: "1rem",
      textAlign: "center",
    }}
  >
    <b>
      {!hasMore ? `You've reached the end of the list. All the courses are listed
        above.` : `Loading...`}
    </b>
  </div>
}

const SelectCourses = () => {

  const { alertType, alert, setAlert, filters, onPageFilterChange, handleSearch, handleClick, currentTab,
    handleFilter, page, parentCatagoryList, courseList, checkedState, showSelectUserModal, recommendedData, teamData, handleNext,
    handelCardClick,
    setAlertType, isMobile, handleCloseAddUserModal, } = useCourseRecommendation();

  return (
    <div className="selectcourses-container">
      <Alert
        message={alert}
        type={alertType}
        onCloseAlert={() => setAlert("")}
        style={{ marginTop: "2rem" }}
      />
      <Row style={{ width: '82.5rem' }}>
        <Col span={24}>
          <Breadcrumb className="selectcourses-crumbs" items={crumbs} />
          <NavigationAndSearch filters={filters} onPageFilterChange={onPageFilterChange} handleSearch={handleSearch} handleClick={handleClick} />
          <Filters currentTab={currentTab} handleFilter={handleFilter} page={page} parentCatagoryList={parentCatagoryList} />
          {courseList && courseList.length ? <CourseList currentTab={currentTab} courseList={courseList} page={page} checkedState={checkedState} handleCardClick={handelCardClick} handleNext={handleNext} /> : <React.Fragment> </React.Fragment>}
          <LoadMoreText hasMore={page.hasMore} isMobile={isMobile} />
        </Col>
      </Row>
      {
        showSelectUserModal && (
          <GetMyTeamModal
            showModal={showSelectUserModal}
            handleClose={handleCloseAddUserModal}
            recommendedData={recommendedData}
            teamData={teamData}
            setAlert={setAlert}
            setAlertType={setAlertType}
          />
        )
      }
    </div >
  );
}
export default SelectCourses;
