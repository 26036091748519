import { useState, useContext } from "react";
import Card from "react-bootstrap/Card";
import elearningVideoWhite from "./../../../../assets/icons/elearningVideoWhite.svg";
import { ReactComponent as CourseProvider } from "./../../../../assets/icons/courseProviderIcon.svg";
import { Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { decodeHtmlEntities } from "../../../../utils/commonUtility";
import BookmarkIcon from "../../../../assets/icons/bookmark_icon_white.png";
import WhiteBookmarkIcon from "../../../../assets/icons/bookmark_icon_white_filled.png";
import { UserContext } from "../../../../context/UserContext";
import config from "../../../../config";
import axios from "axios";
import TimeFormaters from "../../../../utils/timeFormatter";
import { getEllipsis } from "../../../../utils/stringFormatter";
import "./elearningCourseTile.scss";
import SelectedCourseIcon from "../../../../components/common/SelectedCourseIcon";
import AddCourseIcon from "../../../../components/common/AddCourseIcon";

function ElearningCourseTile({
  dataElement,
  index,
  coursePath,
  tileType,
  checkedState,
  cardClicked,
  isSearchResultPage,
  isFavorite,
  reload,
}) {
  let {
    name,
    courseProvider,
    courseProviderDetails,
    id,
    courseId,
    isFavourite: isFav,
    duration,
    languages,
    usersCompleted,
    usersStarted,
    userCourseData,
    courseStatus,
    expectedCompletionTimestamp,
  } = dataElement || {};
  const { userId, userConfig } = useContext(UserContext);
  const [isFavourite, setIsFavourite] = useState(isFav || isFavorite);
  usersStarted = usersStarted ? usersStarted : 0;
  usersCompleted = usersCompleted ? usersCompleted : 0;
  const totalUsers = usersStarted + usersCompleted;
  const { secondary } = userConfig;

  let languagesSearchResult = isSearchResultPage
    ? languages?.map((language) => language.name)
    : [];

  let ElLanguages = "";
  if (isSearchResultPage) {
    ElLanguages =
      languagesSearchResult &&
      getEllipsis(languagesSearchResult?.join(","), 20);
  } else {
    ElLanguages = languages && getEllipsis(languages?.join(","), 20);
  }

  let imageUrl = !dataElement.image
    ? !dataElement.imageUrl
      ? "/default.png"
      : dataElement.imageUrl
    : dataElement.image;

  let showCourseStatus = userCourseData?.courseStatus
    ? userCourseData.courseStatus.toLowerCase()
    : courseStatus
      ? courseStatus
      : "";
  let roundProgressBarColor = "#D1D1D1";
  if (showCourseStatus === "completed") {
    showCourseStatus = "Completed";
    roundProgressBarColor = "#00B900";
  } else if (showCourseStatus === "started") {
    showCourseStatus = "In Progress";
    roundProgressBarColor = secondary;
  } else {
    showCourseStatus = "Get Started";
    roundProgressBarColor = "#D1D1D1";
  }

  const addFavourite = async (userId, courseId, type) => {
    const endPoint = `${config.endPoint.commonService}/favourite`;
    await axios
      .post(endPoint, [
        {
          userId: userId,
          entityType: type,
          entityId: courseId,
        },
      ])
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        if (reload) reload();
      });
  };
  const deleteFavourite = async (userId, type, courseId) => {
    const endPoint = `${config.endPoint.commonService}/favourite?userId=${userId}&courseType=${type}&courseId=${courseId}`;
    await axios
      .delete(endPoint)
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        if (reload) reload();
      });
  };
  const changeStatus = (type) => {
    if (isFavourite) {
      setIsFavourite(false);
      deleteFavourite(userId, type, id || courseId);
    } else {
      setIsFavourite(true);
      addFavourite(userId, id || courseId, type);
    }
  };

  return (
    <div>
      {tileType && tileType === "learningPath" ? (
        <div>
          <Card className={"cardLP"} key={index}>
            <div className={"imageDiv"}>
              <Card.Img variant="top" src={imageUrl} className={"image"} />
              <Card.ImgOverlay className={"elearningOverlay"}>
                <div className={"elearningCardOverlayText"}>
                  <div className={"elearningOverlayText"}>
                    <img alt="video" src={elearningVideoWhite} />
                    COURSE
                  </div>
                  <div className={"rightContent"}>
                    {userCourseData?.expectedCompletionTime ||
                      expectedCompletionTimestamp ? (
                      <span className={"assignedStatus"}> Assigned </span>
                    ) : (
                      ""
                    )}
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        changeStatus(config.elearningCourseList.alias);
                      }}
                      className={`${"wishlistImageEL"} ${userCourseData?.expectedCompletionTime ||
                          expectedCompletionTimestamp
                          ? ""
                          : "extraMargin"
                        }`}
                    >
                      <img
                        src={isFavourite ? WhiteBookmarkIcon : BookmarkIcon}
                        alt={isFavourite ? "Bookmarked" : "Not Bookmarked"}
                      />
                    </span>
                  </div>
                </div>
              </Card.ImgOverlay>
            </div>
            <Card.Body className={"body"}>
              <Row>
                <div className={"cardTitle"}>{decodeHtmlEntities(name)}</div>
              </Row>

              <Row className={"courseDetailsStyle"}>
                <span className={"spanData"}>
                  <div className={"textData"}>
                    {duration &&
                      TimeFormaters.decimalHrsToHrsMin(
                        duration,
                        "detailed"
                      )}{" "}
                    /{" "}
                    {duration % 1 !== 0 ||
                      duration >= 100 ||
                      ElLanguages.length > 13
                      ? `${ElLanguages.substring(0, 13)}...`
                      : ElLanguages}
                  </div>
                  <div className={"duration"}> {totalUsers} learners</div>
                  <div className={"courseProvider"}>
                    <span>
                      <CourseProvider className={"courseProviderIcon"} />
                    </span>
                    <span>
                      {courseProviderDetails?.CourseProviderName?.toUpperCase() ||
                        courseProvider?.toUpperCase()}
                    </span>
                  </div>
                </span>

                <div className={`${"circleProgressBar"}`}>
                  <div className={"addSelectCourse"}>
                    {checkedState &&
                    checkedState?.length &&
                    checkedState?.includes(dataElement.id) ? (
                      <span
                        className="plusCircleStyle"
                        onClick={() => cardClicked(dataElement.id)}
                      >
                        <SelectedCourseIcon
                          strokeColor={userConfig.primary}
                          fillColor={userConfig.primary}
                        />
                      </span>
                    ) : (
                      <span
                        onClick={() => cardClicked(dataElement.id)}
                        className="plusCircleStyle"
                      >
                        <AddCourseIcon
                          strokeColor={userConfig.primary}
                          fillColor={userConfig.primary}
                        />
                      </span>
                    )}
                  </div>
                </div>
              </Row>
            </Card.Body>
          </Card>
        </div>
      ) : (
        <Link to={coursePath}>
          <Card className={"card elearning-card"} key={index}>
            <div className={"imageDiv"}>
              <Card.Img variant="top" src={imageUrl} className={"image"} />
              <Card.ImgOverlay className={"elearningOverlay"}>
                <div className={"elearningCardOverlayText"}>
                  <div className={"elearningOverlayText"}>
                    <img alt="video" src={elearningVideoWhite} /> COURSE
                  </div>
                  <div className={"rightContent"}>
                    {userCourseData?.expectedCompletionTime ||
                      expectedCompletionTimestamp ? (
                      <span className={"assignedStatus"}> Assigned </span>
                    ) : (
                      ""
                    )}
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        changeStatus(config.elearningCourseList.alias);
                      }}
                      className={`${"wishlistImageEL"} ${userCourseData?.expectedCompletionTime ||
                          expectedCompletionTimestamp
                          ? ""
                          : "extraMargin"
                        }`}
                    >
                      <img
                        src={isFavourite ? WhiteBookmarkIcon : BookmarkIcon}
                        alt={isFavourite ? "Bookmarked" : "Not Bookmarked"}
                      />
                    </span>
                  </div>
                </div>
              </Card.ImgOverlay>
            </div>
            <Card.Body className={"body"}>
              <Row>
                <div className={"cardTitle"}>{decodeHtmlEntities(name)}</div>
              </Row>
              <Row className={"courseDetailsStyle"}>
                <span
                  className={"spanData"}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div className={"textData"}>
                    {duration &&
                      TimeFormaters.decimalHrsToHrsMin(
                        duration,
                        "detailed"
                      )}{" "}
                    /{" "}
                    {duration % 1 !== 0 ||
                      duration >= 100 ||
                      ElLanguages?.length > 13
                      ? `${ElLanguages.substring(0, 13)}...`
                      : ElLanguages}
                  </div>
                  <div className={"duration"}>
                    {totalUsers > 0 ? `${totalUsers} learners` : ""}
                  </div>
                  <div className={"courseProvider"}>
                    <span className="el-course-provider-icon">
                      <CourseProvider className={"courseProviderIcon"} />
                    </span>
                    <span>
                      {courseProviderDetails?.CourseProviderName?.toUpperCase() ||
                        courseProvider?.CourseProviderName?.toUpperCase() ||
                        courseProvider?.toUpperCase() ||
                        "N/A"}
                    </span>
                  </div>
                </span>

                <div className={`${"circleProgressBar"}`}>
                  <CircularProgressbarWithChildren
                    key="1"
                    value="100"
                    styles={buildStyles({
                      pathColor: roundProgressBarColor,
                    })}
                    strokeWidth={5}
                  >
                    <div className={"textInside"}>{showCourseStatus}</div>
                  </CircularProgressbarWithChildren>
                </div>
              </Row>
            </Card.Body>
          </Card>
        </Link>
      )}
    </div>
  );
}

ElearningCourseTile.propTypes = {
  dataElement: PropTypes.any.isRequired,
  index: PropTypes.number,
  coursePath: PropTypes.string,
};

export default ElearningCourseTile;