import axios from "axios";
import { useEffect, useState, useContext, useRef } from "react";
import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'
import { useHistory, useLocation } from "react-router-dom";
import config from "../../../config";
import ElearningCourseTile from "../../user/home/elearningCourseTile/ElearningCourseTile";
import "./AntdSelectCourses.scss";
import { UserContext } from "../../../context/UserContext";
import { CourseTile } from "../../../component-library";
import LearningPathCard from "../../../component-library/antDesignLearningPathCard/LearningPathCard";
import { CourseContext } from "../../../context/CourseContext";
import UdemyCourseTile from "../../user/home/udemyCourseTile/UdemyCourseTile";
import buildURL from "../../../utils/buildUrl";
import { LoadingContext } from "../../LoadingContext";
const useCourseRecommendation = () => {
    const { elearningCourseList, instructorLed } = config;
    const history = useHistory();
    const location = useLocation();
    const [page, setPage] = useState({ pageNumber: 1, currentTab: "1", hasMore: true, subFilters: {} })
    const source = axios.CancelToken.source();
    const { setPageLoader } = useContext(LoadingContext)
    const filters = [
        {
            label: 'Instructor Led',
            key: "1",
            headers: {},
            apiEndPoint: `/course/list?page_size=${instructorLed.pagination.pageSize}&page_number=${page.pageNumber}`,
            component: CourseTile,
            coursePath: (id) => `/course-details/${id}`,
            tileType: 'recommendation',
            courseType: 'IL',
            searchParamsMapping: {
                "parentCategoryId": "parent_category_id",
                "categoryId": "category_id",
                "subCategoryId": "subcategory_id",
                "sortBy": "sort_by",
                "languageId": "language",
                "courseProviderId": "courseProvider",
                "proficiencyId": "proficiency",
                "pageNumber": "page_number",
                "pageSize": "page_size",
                "searchString": "searchString",
            }
        },
        {
            label: 'E-Learning',
            key: "2",
            headers: {},
            apiEndPoint: `${config.endPoint.elearning}/course?pageSize=${elearningCourseList.pagination.pageSize}&pageNumber=${page.pageNumber}&courseStatus=active`,
            component: ElearningCourseTile,
            component2: UdemyCourseTile,
            coursePath: (id) => `/e-learning-courses/${id}`,
            tileType: 'learningPath',
            courseType: 'EL',
            searchParamsMapping: {
                "parentCategoryId": "parentCategoryId",
                "categoryId": "categoryId",
                "subCategoryId": "subcategoryId",
                "sortBy": "sort_by",
                "languageId": "language",
                "courseProviderId": "courseProviderId",
                "proficiencyId": "proficiency",
                "page_number": "page_number",
                "page_size": "page_size",
                "courseStatus": "courseStatus",
                "searchString": "courseName",
            }
        },
        {
            label: 'Learning path',
            key: "3",
            apiEndPoint: `${config.endPoint.commonService}/learningpath?learningPathStatus=active&pageNumber=${page.pageNumber}&pageSize=20&sortBy=updatedTimeStamp&sort=desc`,
            headers: { isadminpage: false },
            component: LearningPathCard,
            coursePath: (id) => `/e-learning-courses/${id}`,
            tileType: 'learningpath',
            courseType: 'EL',
            searchParamsMapping: {
                "learningPathCategory": "parentCategoryId",
                "searchString": "searchString"
            }
        }]
    const cancelTokensRef = useRef([]);
    const [data, setData] = useState(filters.reduce((acc, curr) => acc[curr.key] = [], {}));
    const currentTab = filters.find((filter) => filter.key === page.currentTab)
    const courseList = data?.[currentTab.key] || [];
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState("");
    const [pageLoading, setPageLoading] = useState(true);
    const [checkedState, setCheckedState] = useState([]);
    const [showSelectUserModal, setShowSelectUserModal] = useState(false);
    const [recommendedData, setRecommendedData] = useState([]);
    const { parentCatagoryList } = useContext(CourseContext);
    const { userId } = useContext(UserContext);
    const [teamData, setTeamData] = useState([]);
    const isMobile = window.innerWidth < 786;

    const handelCardClick = (cardIndex) => {
        setAlert("");
        setAlertType("");
        if (checkedState && checkedState.length) {
            if (checkedState.includes(cardIndex)) {
                const checkedArray = [...checkedState];
                checkedArray.splice(checkedArray.indexOf(cardIndex), 1);
                setCheckedState(checkedArray);
            } else {
                setCheckedState([...checkedState, cardIndex]);
            }
        } else {
            setCheckedState([cardIndex]);
        }
    };

    const prepareRecommendedData = () => {
        let data = [];
        if (checkedState?.length) {
            data =
                checkedState &&
                checkedState?.map((id) => {
                    return {
                        courseId: id,
                        courseType: currentTab.courseType,
                        courseProvider: courseList.find((course) => course.id == id)?.courseProvider,
                        recommendedBy: userId,
                    }
                });
        }
        setRecommendedData(data);
    };


    const getEndPoint = () => {
        let updatedSubFilters = {}
        Object.keys(page.subFilters).forEach(subFilter => {
            if (page.subFilters[subFilter] && page.subFilters[subFilter] !== "all") updatedSubFilters[currentTab.searchParamsMapping[subFilter]] = page.subFilters[subFilter]
        })
        return buildURL(currentTab.apiEndPoint, updatedSubFilters)
    }
    const cancelAllRequests = () => {
        cancelTokensRef.current.forEach(source => source.cancel('Operation canceled by the user.'));
        cancelTokensRef.current = []; // Clear the ref after canceling
    };
    const fetchCourseDetails = () => {
        if (page.pageNumber === 1) cancelAllRequests();
        setPageLoading(true);
        if (page.pageNumber === 1) setPageLoader(true);
        const source = axios.CancelToken.source();
        cancelTokensRef.current.push(source);
        const endPoint = getEndPoint();
        axios
            .get(endPoint, { headers: currentTab.headers, cancelToken: source.token })
            .then((response) => {
                if (page.pageNumber > 1) {
                    setData((data) => ({ ...data, [currentTab.key]: [...data?.[currentTab.key], ...response.data.data] }));
                } else {
                    setData((data) => ({ ...data, [currentTab.key]: response.data.data }));
                }
                if (!response.data.data.length) setPage((page) => ({ ...page, hasMore: false }))
                else setPage((page) => ({ ...page, hasMore: true }))
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                }
                else {
                    setAlertType("error");
                    setAlert(
                        error?.data?.response || "Something went wrong, please try again"
                    );
                }
            }).finally(() => {
                setPageLoader(false);
                setPageLoading(false)
            })
    };


    useEffect(() => {
        prepareRecommendedData();
        // eslint-disable-next-line
    }, [checkedState]);

    const useDeepCompareEffect = (callback, dependencies) => {
        const currentDependenciesRef = useRef();

        if (!isEqual(currentDependenciesRef.current, dependencies)) {
            currentDependenciesRef.current = dependencies;
        }

        useEffect(callback, [currentDependenciesRef.current]);
    };

    useDeepCompareEffect(() => {
        fetchCourseDetails();
        return () => {
            cancelAllRequests();
        };
    }, [page.pageNumber, page.currentTab, page.subFilters]);


    const handleClick = () => {
        if (checkedState?.length > 0) {
            getAllTeamMembers();
            setShowSelectUserModal(true);
        } else {
            setAlertType("error");
            setAlert("Please select at least one course to recommend");
        }
    };

    const handleCloseAddUserModal = () => {
        setShowSelectUserModal(false);
    };
    const onPageFilterChange = (value) => {
        setData([])
        setPage(page => ({ ...page, currentTab: value, pageNumber: 1, subFilters: {} }))
    }

    useEffect(() => {
        if (pageLoading) {
            if (
                location.state &&
                location.state.alertType &&
                location.state.alertMessage
            ) {
                setAlert(location.state.alertMessage);
                setAlertType(location.state.alertType);
                setPageLoading(false);
                setPageLoader(false);
                history.push({ state: {} });
            }
        }
        // eslint-disable-next-line
    }, [pageLoading]);

    const getAllTeamMembers = () => {
        axios
            .get(`${config.endPoint.userService}/api/user/getMyTeam?isRecommendation=true`)
            .then((response) => {
                setTeamData(response.data.data);
            })
            .catch((error) => {
                setAlert(
                    error.response.data.message ||
                    "Something went wrong, Please try again."
                );

                setAlertType("error");
            });
    };

    const handleNext = () => {
        setPage(page => ({ ...page, pageNumber: page.pageNumber + 1 }))
    }

    const handleFilter = (filter) => {
        setPage(page => ({ ...page, pageNumber: 1, subFilters: filter }))
    }

    const handleSearch = debounce((value) => {
        setPage(page => ({ ...page, pageNumber: 1, subFilters: { ...page.subFilters, searchString: value } }))
    }, 1000)
    return {
        alertType, alert, setAlert, filters, onPageFilterChange, handleSearch, handleClick, currentTab,
        handleFilter, page, parentCatagoryList, courseList, checkedState, showSelectUserModal, recommendedData, teamData, handleNext,
        handelCardClick,
        setAlertType, isMobile, handleCloseAddUserModal,
        pageLoading
    }
}

export default useCourseRecommendation;