import { Col, Progress, Row, Typography } from "antd";
import React from "react";
import "./emptyClassroomSchedules.scss";

function EmptyClassroomSchedules() {
  const renderTitle = () => (
    <div className="flex-center" data-testid="title-container">
      <Typography
        className="classroom-schedule"
        data-testid="classroom-schedule-title"
      >
        Classroom Schedule
      </Typography>
    </div>
  );

  return (
    <Col data-testid="empty-classroom-schedules">
      <Row className="flex-center">{renderTitle()}</Row>
      <Row
        className="flex-center no-sessions-container"
        data-testid="no-sessions-container"
      >
        <Col span={18} className="flex-center">
          <Typography data-testid="no-upcoming-sessions">
            *No Upcoming Sessions
          </Typography>
        </Col>

        <Col span={6}>
          <div
            className="seats-container"
            style={{ margin: 8, padding: 8 }}
            data-testid="seats-container"
          >
            <Row className="flex-center">
              <Typography data-testid="seats-info">{`0 / 0`}</Typography>
            </Row>
            <Row className="flex-center">
              <Progress
                percent={0}
                showInfo={false}
                style={{ margin: 0, padding: 0 }}
                data-testid="progress-bar"
              />
            </Row>
          </div>
        </Col>
      </Row>
      <Row className="empty-session-phrase" data-testid="empty-session-phrase">
        <Typography
          className="empty-session-phrase-text"
          data-testid="empty-session-phrase-text"
        >
          *There is no session currently available for this course. They will
          appear once created by the LMS admin team
        </Typography>
      </Row>
    </Col>
  );
}

export default EmptyClassroomSchedules;
