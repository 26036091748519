import { createContext, useEffect } from "react";
import useThirdPartyApiKeyManagement from "../pages/admin/thirdPartyApiKeysManagement/useThirdPartyApiKeysManagement";

export const UdemyContext = createContext();



const UdemyContextProvider = ({ children }) => {
    const { udemyApiKeys, alert, alertType, updateApiKeys, setUdemyApiKeys, setAlert, } = useThirdPartyApiKeyManagement()
    return <UdemyContext.Provider value={{ udemyApiKeys, alert, alertType, updateApiKeys, setUdemyApiKeys, setAlert, isEnabled: udemyApiKeys.enabled }}>
        {children}
    </UdemyContext.Provider>

}

export default UdemyContextProvider;