/* eslint-disable no-lone-blocks */
import React, { useContext, useEffect, useState } from "react";
import style from "./attendanceDetailList.module.css";
import axios from "axios";
import { CSVLink } from "react-csv";
import {
  AntDesignModal,
  FileUploader,
  Validation,
} from "../../../component-library";
import UtilityFunctions from "../../../utils/utility";
import TimeFormaters from "../../../utils/timeFormatter";
import config from "../../../config";
import { CourseContext } from "../../../context/CourseContext";

export default function AttendanceUpdateModal({
  courseId,
  sessionId,
  showUpdateAttendanceModel,
  modules,
  courseName,
  sessionName,
  setAlert,
  setAlertType,
  modalCloseHandler,
  successCallback,
}) {
  const { userDateFormat } = useContext(CourseContext);
  const [sampleCsvHeaders, setSampleCsvHeaders] = useState([]);
  const [sampleCsvData, setSampleCsvData] = useState([]);
  const [sampleCsvHeadersWithoutFinalStatus, setSampleCsvHeadersWithoutFinalStatus] =
    useState([]);
  const [attendanceFile, setAttendanceFile] = useState("");
  const [errorMessage, setErrorMessage] = useState({});

  useEffect(() => {
    const headers = [
      { label: "Employee Email", key: "email" },
      { label: "Valid Rakuten Employee-ID's only", key: "id" },
    ];

    if (modules) {
      modules.forEach((module) => {
        headers.push({
          label: `${TimeFormaters.FormatTableTime(
            module.start_date,
            null,
            userDateFormat
          )} - (${module.id})`,
          key: module.id.toString(),
        });
      });
    }
    headers.push({ label: "Status", key: "status" });
    setSampleCsvHeaders(headers);
    const headersWithoutFinalStatus = [...headers];
    headersWithoutFinalStatus.pop();
    setSampleCsvHeadersWithoutFinalStatus(headersWithoutFinalStatus);
  }, [modules, userDateFormat]);

  useEffect(() => {
    const statusMap = new Map([
      [0, "Partial"],
      [1, "Excused"],
      [2, "No Show"],
      [3, "Completed"],
    ]);
    const getUsersData = (courseId, sessionId) => {
      axios
        .get(
          `${config.endPoint.instructorLedNew}/attendance/${courseId}/${sessionId}`
        )
        .then((response) => {
          const users = response?.data?.data?.enrolledUsers;
          let csvData = [];
          if (users.length) {
            csvData = users.map((user) => {
              const result = {
                email: user.employeeEmail,
                id: user.employeeId,
                status: statusMap.get(user.attendanceStatus),
              };
              user.schedule.forEach((sch) => {
                result[sch.scheduleId] = statusMap.get(sch.attendance);
              });
              return result;
            });
          }
          setSampleCsvData(csvData);
        });
    };
    if (courseId && sessionId) {
      getUsersData(courseId, sessionId);
    }
  }, [courseId, sessionId]);

  const submitHandler = () => {
    if (sessionId) {
      const formData = new FormData();
      formData.append("courseId", courseId);
      formData.append("batchId", sessionId);
      formData.append("attendanceFile", attendanceFile);
      const errors = {};
      if (!attendanceFile) {
        errors.attendanceFile = "Please select the attendance file. ";
      }
      if (errors) {
        setErrorMessage({ ...errorMessage, ...errors });
      }
      const endPoint = `${config.endPoint.instructorLedNew}/attendance/edit/uploadFile`;
      if (!errors || !Object.keys(errors).length) {
        axios
          .put(endPoint, formData)
          .then((response) => {
            const filename = "attendance-update.csv";
            UtilityFunctions.downloadCSVFile(response, filename);
            successCallback();
            setAlertType("success");
            setAlert("File uploaded successfully");
          })
          .catch((error) => {
            setAlert(error?.response?.data?.message);
            setAlertType("danger");
          });
        modalCloseHandler();
      }
    }
  };

  function disclaimerMessage() {
    return (
      <div className={style.disclaimerMessage}>
        *Users specified in the uploaded file will have their attendance marked
        as per the data provided in the file
      </div>
    );
  }

  const hint = (
    <div>
      Hint : Drag and Drop here to upload. Download &nbsp;
      <CSVLink
        headers={sampleCsvHeaders}
        data={sampleCsvData}
        filename="attendance.csv"
      >
        Spreadsheet
      </CSVLink>{" "}
      and update the values along with final status.
      <br />
      Download &nbsp;
      <CSVLink
        headers={sampleCsvHeadersWithoutFinalStatus}
        data={sampleCsvData}
        filename="attendance.csv"
      >
        Spreadsheet
      </CSVLink>{" "}
      and update the values without final status (Final status will be
      calculated automatically).
      <br />
      <span style={{ color: "red" }}>Do not modify the headers.</span>
    </div>
  );

  const content = (
    <>
      <div className={style.grayBox}>
        <div className={style.content}>
          <b>{courseName}</b> - <b>{sessionName}</b>
        </div>
        <div className={style.importExcel}>
          <p>Import an Excel/CSV file</p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <FileUploader
            fName=""
            upliftFileDetails={({ fileData, error }) => {
              if (error) {
                setErrorMessage({ attendanceFile: error });
              } else {
                setErrorMessage({ attendanceFile: "" });
                setAttendanceFile(fileData);
              }
            }}
            hint={hint}
          />
        </div>
        {(errorMessage.attendanceFile && (
          <Validation
            message={errorMessage.attendanceFile}
            st={{ display: "flex", justifyContent: "center", marginTop: 0 }}
          />
        )) ||
          ""}
      </div>
      {disclaimerMessage()}
    </>
  );

  return (
    <div style={{ marginBottom: "2rem" }}>
      <AntDesignModal
        title="Update enrolled Users Data for a "
        titleBold="Completed Session"
        show={showUpdateAttendanceModel}
        okButtonText="SUBMIT"
        canceButtonText="CANCEL"
        onCancel={modalCloseHandler}
        onOk={submitHandler}
        description={content}
        centered={true}
      />
    </div>
  );
}
