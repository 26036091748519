import React from "react";  
import InstructorLedCourseWhite from "../../assets/icons/InstructorLedCourseWhite.svg";
import elearningVideoWhite from "../../assets/icons/elearningVideoWhite.svg";

import config from "../../config";

//import style
import style from "./CourseIcon.module.css";
const INSTRUCTOR_LED = config.INSTRUCTOR_LED;
const UDEMY = config.UDEMY;

function CourseIcon({ courseType }) {
  return (
    <div className={style.course}>
      <img
        className={style.imageWidth}
        src={
          courseType === INSTRUCTOR_LED ? InstructorLedCourseWhite : elearningVideoWhite
        }
      ></img>
      <span
        className={`${style.course} ${style.white}`}
      >
        {courseType === UDEMY ? `${UDEMY} COURSE` : "COURSE"}
      </span>
    </div>
  );

}

export default CourseIcon;
