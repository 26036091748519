import axios from "axios";
import config from "../config";

export const getCertificateBlob = async (userId, courseId, courseType) => {
  const downloadCertificateEndpoint = `${config.endPoint.commonService}/pdf/download?userId=${userId}&courseId=${courseId}&courseType=${courseType}`;

  try {
    const response = await axios.get(downloadCertificateEndpoint, {
      responseType: "blob", // Important for binary data
    });


    const contentDisposition = response.headers['content-disposition'];
    let fileName = 'certificate.pdf';

    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
      if (fileNameMatch && fileNameMatch.length === 2) {
        fileName = fileNameMatch[1];
      }
    }

    // Create a URL for the Blob object
    const url = window.URL.createObjectURL(new Blob([response.data]));

    // Create a temporary <a> element to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName); // Set file name

    // Append link to the DOM and trigger the click
    document.body.appendChild(link);
    link.click();

    // Cleanup: Remove the link and release the Blob URL
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading certificate:", error);
    alert("Failed to download the certificate. Please try again.");
  }
};
