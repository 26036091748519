import React, { useState, useEffect } from "react";
import "./slick.scss";
import axios from "axios";
// import CalenderIcon from "./../../../../assets/icons/calendar_icon.svg";
import { ReactComponent as CalenderIcon } from "./../../../../assets/icons/calendar_icon.svg";

import Slider from "react-slick";
import { FillingFastSession } from "../../../../component-library";
import { Link } from "react-router-dom";
import "./fastFilling.scss";
import config from "../../../../config";

function FillingFast({ setAlert, setAlertType, userConfig }) {
  const [pageLoading, setPageLoading] = useState(true);
  const [items, setItems] = useState([]);
  const isMobile = window.innerWidth < 786;

  async function fetchFillingFastSessions() {
    try {
      const result = await axios.get(
        `${config.endPoint.instructorLedNew}/batch/filling-fast?max_results=8&max_available_seats=200`
      );
      if (result.data.success) {
        setPageLoading(false);
        result.data.data.forEach((element, index) => {
          const item = (
            <FillingFastSession
              session={element}
              userConfig={userConfig}
              data-testid={`session-${index + 1}`}
            />
          );
          setItems((items) => [...items, item]);
        });
      }
    } catch (error) {
      setAlert(`${error}`);
      setAlertType("danger");
    }
  }

  useEffect(() => {
    if (pageLoading) {
      // To avoid duplicate network call
      fetchFillingFastSessions();
    }
    // eslint-disable-next-line
  }, [pageLoading]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    rows: 2, //  mobile screen
    slidesPerRow: 1,
    dotsClass: "slickDots",
    autoplay: true,
  };

  return (
    <>
      <div className={"headerRow"} data-testid="header-row">
        <div className={"headerText"}>Hurry up! Enroll now!!</div>
        <div>
          <Link to="/Calendar">
            <CalenderIcon
              fill={userConfig.primary}
              stroke={userConfig.primary}
              data-testid="calendar-icon"
            />
          </Link>
        </div>
      </div>
      {window.innerWidth > 768 ? (
        <div>
          <div className={"fillingFast-slider"} data-testid="desktop-slider">
            {items.length > 0 && <Slider {...settings}>{items}</Slider>}
            {!items.length && (
              <div className={"placeholderText"} data-testid="no-sessions">
                No upcoming sessions
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          className="custom-mobile-slider"
          style={{
            display: "flex",
            flexFlow: "row",
            overflow: "scroll",
            marginTop: isMobile ? 10 : 40,
          }}
          data-testid="mobile-slider"
        >
          {items.length > 0 ? (
            items.map((m) => (
              <div className="item" style={{ marginRight: 8 }} key={m.key}>
                {m}
              </div>
            ))
          ) : (
            <div className={"placeholderText"} data-testid="no-sessions">
              No upcoming sessions
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default FillingFast;
