import { useContext, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import ilIcon from "../../../assets/icons/InstructorLedCourse.svg";
import elIcon from "../../../assets/icons/myLearningElIcon.svg";
import lpIcon from "../../../assets/icons/learning_path.svg";
import BookmarkedIcon from "../../../components/common/icons/bookmarkedIcon";
import axios from "axios";
import "./myLearningsNew.scss";
import config from "../../../config";
import TimeFormaters from "../../../utils/timeFormatter";
import { UserContext } from "../../../context/UserContext";
import { Button } from "antd";
import { MdOutlineFileDownload } from "react-icons/md";
import { getCertificateBlob } from "../../../api/downloadCertificate";

const MyLearningsCourseCard = ({
  courseDetail,
  bookmark,
  setAlert,
  setAlertType,
  setShowAlert,
  icon,
  filterName,
}) => {
  const { userId, userConfig } = useContext(UserContext);

  const {
    duration,
    image,
    isPrivate,
    language,
    name,
    type,
    subEntityType,
    waitListCount,
    completionTimestamp,
    expectedCompletionDate,
    sessionStartTime,
    sessionEndTime,
    sessionTimeOffset,
    ilCourseCount,
    elCourseCount,
    id,
    isFavorite,
    isFavourite,
  } = courseDetail;
  const [isBookmarked, setIsBookmarked] = useState(isFavourite || isFavorite);
  const courseIcon = (type) => {
    switch (type) {
      case "EL":
      case "UDEMY":
        return elIcon;
      case "LP":
        return lpIcon;
      default:
        return ilIcon;
    }
  };

  const courseLink = () => {
    if (subEntityType === "udemy")
      return `e-learning-courses/udemy/${id}`;
    else
      switch (type) {
        case "EL":
          return `e-learning-courses/${id}`;
        case "LP":
          return `learning-paths/${id} `;
        default:
          return `course-details/${id} `;
      }
  };

  const handleBookmarkStatus = (event) => {
    event.preventDefault();

    const LEARNING_PATH_FAVORITE_API_ENDPOINT =
      config.endPoint.commonService + "/favourite";

    if (!isBookmarked) {
      axios
        .post(LEARNING_PATH_FAVORITE_API_ENDPOINT, [
          {
            userId: userId,
            entityType: type,
            entityId: id,
            subEntityType: subEntityType || type
          },
        ])
        .then((response) => {
          if (response?.data?.success) {
            setIsBookmarked(true);
          }
        })
        .catch((error) => {
          setShowAlert(true);
          setAlert(
            error?.response?.data || "Something went wrong, please try again"
          );
          setAlertType("error");
        });
    } else {
      axios
        .delete(LEARNING_PATH_FAVORITE_API_ENDPOINT, {
          params: {
            userId: userId,
            courseType: type,
            courseId: id,
          },
        })
        .then((response) => {
          if (response?.data?.success) {
            setIsBookmarked(false);
          }
          bookmark();
        })
        .catch((error) => {
          setShowAlert(true);
          setAlert(
            error?.response?.data || "Something went wrong, please try again"
          );
          setAlertType("warning");
        });
    }
  };

  return (
    <Link
      className="myLearnings-courseTile"
      to={courseLink()}
      target="_blank"
      data-testid="course-link"
    >
      <div className="myLearnings-subheading" data-testid="course-subheading">
        <span>
          <img
            className="myLearnings-subheadingImage"
            src={courseIcon(type)}
            alt="Intructor Led"
            data-testid="course-icon"
          />
          <span
            className="myLearnings-subheadingText"
            data-testid="course-type"
          >
            {subEntityType === "udemy" ? "UDEMY" : ""} COURSE
          </span>
        </span>
        <span style={{ display: "flex" }}>
          {filterName !== "completed" && (
            <div onClick={handleBookmarkStatus} data-testid="bookmark-icon">
              {isBookmarked ? (
                <BookmarkedIcon
                  height={23}
                  width={23}
                  fillColor={
                    !isPrivate ? userConfig?.primary : userConfig?.secondary
                  }
                  color={
                    !isPrivate ? userConfig?.primary : userConfig?.secondary
                  }
                />
              ) : (
                <BookmarkedIcon
                  height={23}
                  width={23}
                  fillColor={!isPrivate ? "#ffffff" : "#ffffff"}
                  color={
                    !isPrivate ? userConfig?.primary : userConfig?.secondary
                  }
                />
              )}
            </div>
          )}
          {filterName !== "bookmarked" && (
            <span className="my-learnings-card-icon" data-testid="custom-icon">
              {icon}
            </span>
          )}
        </span>
      </div>
      <div className="myLearnings-courseName" data-testid="course-name">
        {name}
      </div>
      {type === "IL" && waitListCount ? (
        <div className="myLearnings-waitlist" data-testid="waitlist-count">
          Your Waitlist # - {waitListCount}
        </div>
      ) : (
        filterName !== "completed" &&
        sessionStartTime && (
          <div className="myLearnings-sessionStart" data-testid="session-start">
            {TimeFormaters.FormatDate(
              new Date(sessionStartTime),
              sessionTimeOffset
            )}
            {" - "}
            {TimeFormaters.FormatDate(
              new Date(sessionEndTime),
              sessionTimeOffset
            )}
          </div>
        )
      )}
      {type === "EL" && expectedCompletionDate && (
        <div className="myLearnings-deadline" data-testid="completion-deadline">
          Completion Deadline:
          <div className="myLearnings-deadlineDate">
            {TimeFormaters.FormatDate(
              new Date(expectedCompletionDate),
              sessionTimeOffset
            )}
          </div>
        </div>
      )}
      {type == "LP" && filterName !== "completed" && (
        <div className="myLearnings-deadline" data-testid="lp-course-count">
          <span className="myLearnings-lp-ilcount">{ilCourseCount}</span>IL
          Courses
          <span className="myLearnings-lp-elcount">{elCourseCount}</span>EL
          Courses
        </div>
      )}

      {(filterName === "completed" && subEntityType !== "udemy") && (
        <div
          data-testid="completion-date"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <Button
            style={{
              color: userConfig.primary,
              borderColor: userConfig.primary,
              margin: 8,
              marginLeft: 16,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "bold",
              width: "40%",
              height: "2.5rem",
              marginTop: 16,
            }}
            // TODO: Need to add link to download certificate
            onClick={async (event) => {
              event.preventDefault();
              console.log("Downloading certificate...");
              const certificateResponse = await getCertificateBlob(
                userId,
                id,
                type
              );
              console.log(certificateResponse);
            }}
          >
            <span style={{ paddingRight: 4 }}>
              <MdOutlineFileDownload size={20} />
            </span>
            Certificate
          </Button>
          <div
            style={{ display: "flex", flexDirection: "column", marginTop: 12 }}
          >
            <span style={{ fontSize: 14, color: "gray" }}>Completed On</span>
            <span style={{ fontSize: 16, color: "black", fontWeight: "bold" }}>
              {type == "IL"
                ? TimeFormaters.FormatDate(
                  new Date(sessionEndTime),
                  sessionTimeOffset
                )
                : completionTimestamp
                  ? TimeFormaters.FormatDate(
                    new Date(completionTimestamp),
                    sessionTimeOffset
                  )
                  : null}
            </span>
          </div>
        </div>
      )}

      {filterName !== "completed" && completionTimestamp && (
        <div className={"myLearnings-completed"}>
          Completed On :
          <div className={"myLearnings-deadlineDate"}>
            {TimeFormaters.FormatDate(
              new Date(completionTimestamp),
              sessionTimeOffset
            )}
          </div>
        </div>
      )}

      {filterName !== "completed" && completionTimestamp == null && (
        <div data-testid="course-duration" className={"myLearnings-duration"}>
          {TimeFormaters.decimalHrsToHrsMin(duration, "detailed")}
          {language &&
            `/${typeof language === "string" ? language : language.name} `}
        </div>
      )}
      <div
        className="myLearnings-courseImage"
        style={{
          backgroundImage: `url(${image || `${process.env.PUBLIC_URL}/default.png`
            })`,
        }}
        data-testid="course-image"
      ></div>
    </Link>
  );
};

export default MyLearningsCourseCard;
