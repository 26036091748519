import { Row, Col } from "antd";
import { useContext } from "react";
import { Link } from "react-router-dom";
import "./eLearningPlatform.scss";
import { UserContext } from "../../../../context/UserContext";

export default function ELearningPlatform() {
  const { userConfig } = useContext(UserContext);
  const learningPlatforms = userConfig.learningPlatforms || [];

  const findIcon = (name) => {
    let icon;
    switch (name) {
      case "udemy":
        icon = "https://storage.googleapis.com/rlms-public-assets/udemy.png";
        break;
      case "pluralsight":
        icon =
          "https://storage.googleapis.com/rlms-public-assets/PluralSight.png";
        break;
      case "coursera":
        icon =
          "https://storage.googleapis.com/rlms-public-assets/coursera-min.png";
        break;
      case "linkedinLearning":
        icon =
          "https://storage.googleapis.com/rlms-public-assets/linkedinlearning-min.png";
      default:
        break;
    }
    return icon;
  };

  return (
    <>
      <div
        className={"eLearning"}
        style={{ marginBottom: 16 }}
        data-testid="elearning-platform"
      >
        {window.innerWidth > 768 ? (
          <div>
            <Row className="justify-content-md-center">
              <Col className={"topTextUp"}>Leverage the full power of a</Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col className={"topTextDown"}>Self Paced e-learning Course</Col>
            </Row>
          </div>
        ) : (
          <div className="mobile-content-phrase">
            <span>Leverage the full power of a</span>
            <span>Self Paced e-learning Course</span>
          </div>
        )}

        <div
          style={{
            display: "grid",
            gridTemplateColumns:
              window.innerWidth < 768
                ? "repeat(1, 1fr)"
                : learningPlatforms?.length > 1
                ? "repeat(2, 1fr)"
                : "repeat(1, 1fr)",
            width: "100%",
            margin: "4rem 0rem",
          }}
          data-testid="platforms-grid"
        >
          {learningPlatforms.map((platform) => {
            const { name, url } = platform;

            const icon = findIcon(name);
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "2rem",
                }}
                data-testid={`platform-${name}`}
              >
                <Link
                  to={{ pathname: url }}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <div className={"eLearningBox"}>
                    <img
                      src={icon}
                      alt="udemy-icon"
                      className={"udemyImage"}
                      width={200}
                      // style={{ objectFit: "contain" }}
                    />
                  </div>
                </Link>
              </div>
            );
          })}
        </div>

        {/* </Row> */}
      </div>
    </>
  );
}
