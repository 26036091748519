import axios from "axios";
import parse from "html-react-parser";
import React, { useEffect, useState, useContext } from "react";
import { Breadcrumb, Col, Layout, Row, Tooltip } from "antd";
import { ReactHeight } from "react-height";
import { useHistory } from "react-router-dom";
import courseProviderIcon from "../../../../assets/icons/courseProviderIcon.svg";
import courseIcon from "../../../../assets/icons/elearningVideo.svg";

import {
  Alert,
  PrimaryButton,
  PrimaryOutlineButton,
  Quotation,
} from "../../../../component-library";
import PrimaryFilledOutlineButton from "../../../../component-library/primaryFilledOutlineButton/PrimaryFilledOutlineButton";
import config from "../../../../config";
import TimeFormatter from "../../../../utils/timeFormatter";
import "./ElearningCourseDetail.scss";
import { UserContext } from "../../../../context/UserContext";
import { CourseContext } from "../../../../context/CourseContext";
import { truncate } from "lodash";
import { FaRegBookmark } from "react-icons/fa6";
import { FaBookmark } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import openLinkInNewTab from "../../../../utils/openLink";
import { LoadingContext } from "../../../LoadingContext";

export default function ElearningCourseDetail(props) {
  const { userDateFormat } = useContext(CourseContext);

  const [course, setCourse] = useState({ courseId: props.match.params.courseId, courseType: props.match.params.courseType });
  const { setPageLoader } = useContext(LoadingContext)
  const history = useHistory();
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [courseDetail, setCourseDetail] = useState({});
  const [courseImage, setCourseImage] = useState("");
  const [userCount, setUserCount] = useState(0);
  const [userCompletedCount, setUserCompletedCount] = useState(0);
  const [userStartedCount, setUserStartedCount] = useState(0);
  const [userDetails, setUserDetails] = useState({});
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [heightDiv, setHeightDiv] = useState(200);
  const { userId, userConfig } = useContext(UserContext);
  const [isFavourite, setIsFavourite] = useState(false);
  const isMobile = window.innerWidth < 786;

  const crumbs = [
    {
      title: <a href="/">Home</a>,
    },
    {
      title: <a href="/e-learning-courses-list/all/all">E-learning Courses</a>,
    },
    {
      title: (
        <Tooltip
          placement="bottomLeft"
          color="#9c9c9c"
          overlayStyle={{
            fontFamily: "RakutenRoundRegular",
            maxWidth: "12rem",
          }}
          title={
            courseDetail && courseDetail.name
              ? courseDetail.name
              : `Course Detail`
          }
        >
          {courseDetail && courseDetail.name
            ? courseDetail.name.substring(0, 18) + "..."
            : `Course Detail`}
        </Tooltip>
      ),
    },
  ];

  useEffect(() => {
    document.title =
      courseDetail && courseDetail.name
        ? `${courseDetail.name} | LMS`
        : `Course Information | LMS`;
  });

  useEffect(() => {
    getCourseDetails();
    // eslint-disable-next-line
  }, [course.courseId, course.courseType]);

  useEffect(() => {
    setCourse({ courseId: props.match.params.courseId, courseType: props.match.params.courseType })
  }, [props.match.params.courseId, props.match.params.courseType]);

  const getCourseDetails = () => {
    setPageLoader(true);
    axios
      .get(`${config.endPoint.elearning}/course/${course.courseId}?courseProvider=${course.courseType}`)
      .then((response) => {
        if (response.data.success) {
          const {
            userCount,
            userStartedCount,
            userCompletedCount,
            course,
            user,
          } = response.data?.data || {};
          setUserCount(userCount || 0);
          setUserStartedCount(userStartedCount || 0);
          setUserCompletedCount(userCompletedCount || 0);
          setCourseDetail(course || {});
          setUserDetails(user || {});
          const imageURL =
            course?.imageUrl || `${process.env.PUBLIC_URL}/default.png`;
          setCourseImage(imageURL);
          setIsFavourite(course?.isFavourite);
        }
      })
      .catch((error) => {
        setAlert(
          error?.response?.data?.message || "Error while updating user role"
        );
        setAlertType("danger");
      })
      .finally(() => {
        setPageLoader(false);
      })
  };

  const launchCourse = () => {
    if (course.courseType === "udemy") {
      openLinkInNewTab(courseDetail.url)
    }
    else {
      if (!buttonDisabled) {
        history.push(`/e-learning-courses/${course.courseId}/launch`);
      }
    }
  };

  const [buttonText, setButtonText] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [statusMessage, setStatusMessage] = useState();






  useEffect(() => {
    if (!course.courseType) {
      if (
        courseDetail.courseStatus === "active" &&
        courseDetail.zipFileName !== ""
      ) {
        setButtonDisabled(false);
        if (courseDetail.countries.indexOf(userDetails.userCountry) < 0) {
          setButtonDisabled(true);
          setButtonText(config.ElearningButtonText.start);
          setStatusMessage(
            <p>
              The course is currently <b>NOT AVAILABLE</b> in your region.
            </p>
          );
        } else if (userDetails.expectedCompletionTimestamp) {
          const date = Math.floor(Date.now() / 1000);
          const expectedCompletion = userDetails.expectedCompletionTimestamp;
          if (date < expectedCompletion) {
            if (userDetails.courseStatus === "completed") {
              setButtonText(config.ElearningButtonText.start);
              setStatusMessage(
                <p>
                  Our records indicate that you have <b>COMPLETED</b> this course.
                </p>
              );
            } else if (userDetails.courseStatus === "started") {
              setButtonText(config.ElearningButtonText.resume);
              setStatusMessage(
                <p>
                  You must complete this <b>ASSIGNED</b> course by{" "}
                  <b className={"timeStamp"}>
                    {TimeFormatter.FormatDate(
                      expectedCompletion,
                      null,
                      userDateFormat
                    )}
                  </b>
                  .
                </p>
              );
            } else {
              setButtonText(config.ElearningButtonText.start);
              setStatusMessage(
                <p>
                  You must complete this <b>ASSIGNED</b> course by{" "}
                  <b className={"timeStamp"}>
                    {TimeFormatter.FormatDate(
                      expectedCompletion,
                      null,
                      userDateFormat
                    )}
                  </b>
                  .
                </p>
              );
            }
          } else {
            if (userDetails.courseStatus === "completed") {
              setButtonText(config.ElearningButtonText.start);
              setStatusMessage(
                <p>
                  Our records indicate that you have <b>COMPLETED</b> this course.
                </p>
              );
            } else if (userDetails.courseStatus === "started") {
              setButtonText(config.ElearningButtonText.resume);
              setStatusMessage(
                <p>
                  Our records indicate that you have <b>NOT COMPLETED</b> this
                  course.
                </p>
              );
            } else {
              setButtonText(config.ElearningButtonText.start);
              setStatusMessage(
                <p>
                  Our records indicate that you have <b>NOT COMPLETED</b> this
                  course.
                </p>
              );
            }
          }
        } else {
          if (userDetails.courseStatus === "completed") {
            setButtonText(config.ElearningButtonText.start);
            setStatusMessage(
              <p>
                Our records indicate that you have <b>COMPLETED</b> this course.
              </p>
            );
          } else if (userDetails.courseStatus === "started") {
            setButtonText(config.ElearningButtonText.resume);
            setStatusMessage(
              <p>
                Our records indicate that you have <b>NOT COMPLETED</b> this
                course.
              </p>
            );
          } else {
            setButtonText(config.ElearningButtonText.start);
            setStatusMessage(
              <p>
                <b>{userCompletedCount}</b> already Completed
              </p>
            );
          }
        }
      } else {
        setButtonDisabled(true);
        setButtonText(config.ElearningButtonText.start);
        setStatusMessage(
          <p>
            Course currently not <b>ACTIVE</b>
          </p>
        );
      }
    }
    // eslint-disable-next-line
  }, [userDetails]);
  useEffect(() => {
    if (course.courseType) {
      setButtonText(userDetails.courseStatus === "started" ? config.ElearningButtonText.resume : userDetails.courseStatus === "completed" ? config.ElearningButtonText.completed : config.ElearningButtonText.start);
      setButtonDisabled(userDetails.courseStatus === "completed")
    }
  }, [])

  const addFavourite = async (userId, courseId) => {
    const endPoint = `${config.endPoint.commonService}/favourite`;
    await axios
      .post(endPoint, [
        {
          userId: userId,
          entityType: "EL",
          entityId: courseId,
          subEntityType: course.courseType === "udemy" ? "udemy" : 'inHouse'
        },
      ])
      .catch((error) => {
        setAlert(
          error?.response?.data || "Something went wrong, please try again"
        );
        setAlertType("warning");
      });
  };

  const deleteFavourite = async (userId, courseId) => {
    const endPoint = `${config.endPoint.commonService}/favourite?userId=${userId}&courseType=EL&courseId=${courseId}`;
    await axios.delete(endPoint).catch((error) => {
      setAlert(
        error?.response?.data || "Something went wrong, please try again"
      );
      setAlertType("warning");
    });
  };

  const changeStatus = () => {
    if (isFavourite) {
      setIsFavourite(false);
      deleteFavourite(userId, Number(course.courseId));
    } else {
      setIsFavourite(true);
      addFavourite(userId, Number(course.courseId));
    }
  };

  return (
    <React.Fragment>
      <div style={{ overflowX: "hidden" }}>
        <Layout
          className={`${"el-details-alertContainer"} ${"el-details-innerContainer"}`}
        >
          <Alert
            message={alert}
            type={alertType}
            onCloseAlert={() => setAlert("")}
          />
        </Layout>
        <Layout fluid="true" className={"el-details-mainContainer"}>
          <Layout className={"el-details-publicContainer"} fluid={true}>
            <Layout className={"el-details-innerContainer"}>
              <Row>
                <Breadcrumb items={crumbs} className="course-detail-breadcrumb" />
              </Row>

              <Row>
                <Col lg={14} sm={24} className="el-details-coursePageIconLabel">
                  <Row>
                    <span className="el-details-coursePageLabelIcon">
                      <img
                        src={courseIcon}
                        alt="courseIcon"
                        style={{ height: "1.296rem" }}
                      />
                    </span>
                    <span className={"el-details-coursePageLabel"}>
                      E-Learning Course
                    </span>
                  </Row>
                  <Row>
                    <Tooltip title={courseDetail.name} placement="topLeft">
                      <p className={"el-details-courseName"}>
                        {courseDetail.name}
                      </p>
                    </Tooltip>
                  </Row>
                  <Row className={"el-details-courseTimeLanguageAlignment"}>
                    <div className={"el-details-courseTimeLanguage"}>
                      {TimeFormatter.decimalHrsToHrsMin(
                        courseDetail.duration,
                        "detailed"
                      )}{" "}
                      /
                      <Tooltip title={courseDetail?.languages?.join(", ")}>
                        {isMobile
                          ? truncate(courseDetail?.languages?.join(", ")).slice(
                            0,
                            3
                          ) + "..."
                          : truncate(courseDetail?.languages?.join(", ")).slice(
                            0,
                            10
                          ) + "..."}
                      </Tooltip>
                    </div>
                    <div className={"el-details-courseProviderIconName"}>
                      <div>
                        <img
                          src={courseProviderIcon}
                          alt="course provider icon"
                          width={14}
                          height={14}
                        />
                      </div>
                      <span className={"el-details-courseProvider"}>
                        <Tooltip
                          title={
                            courseDetail.courseProvider
                              ? courseDetail?.courseProvider?.CourseProviderName
                              : courseDetail?.oldCourseProvider
                          }
                        >
                          {courseDetail.courseProvider
                            ? courseDetail?.courseProvider?.CourseProviderName
                            : courseDetail?.oldCourseProvider}
                        </Tooltip>
                      </span>
                    </div>
                  </Row>

                  <Row className={"el-details-enrollcontainer"}>
                    <Row className={"el-details-buttonContainer"}>
                      <Col
                        sm={8}
                        lg={14}
                        className="el-details-primaryOutlineButton"
                      >
                        <PrimaryButton
                          isDisabled={buttonDisabled}
                          label={buttonText}
                          otherStyle={
                            "el-details-publicButton el-details-primaryButton"
                          }
                          handleClick={launchCourse}
                        />
                      </Col>
                      <Col
                        sm={3}
                        lg={10}
                        className="el-details-primaryFilledOutlineButton"
                      >
                        <PrimaryFilledOutlineButton
                          opacity={true}
                          icon={
                            !isFavourite ? (
                              <FaRegBookmark
                                color={userConfig?.primary}
                                size={20}
                                style={{ marginLeft: 8 }}
                              />
                            ) : (
                              <FaBookmark
                                color={userConfig?.primary}
                                size={20}
                                style={{ marginLeft: 8 }}
                              />
                            )
                          }
                          label={!isFavourite ? "ADD BOOKMARK" : "BOOKMARKED"}
                          handleClick={() => changeStatus()}
                          otherStyle={"el-details-primaryFilledOutlineButton"}
                          iconAsImage={false}
                        />
                      </Col>
                    </Row>
                  </Row>
                  <Row className={"el-details-statusContainer"}>
                    <label className={"el-details-enrollStatus"}>
                      {statusMessage}
                    </label>
                  </Row>
                </Col>

                <Col lg={10} sm={24}>
                  <Row>
                    <img
                      className={"el-details-courseImage"}
                      src={courseImage}
                      alt="course"
                    />
                  </Row>
                </Col>
              </Row>
            </Layout>
          </Layout>
          <Layout
            className={`${"el-details-textualSection"} ${"el-details-innerContainer"}`}
          >
            {window.innerWidth < 786 && (
              <label className={"el-details-enrollStatus"}>{statusMessage}</label>
            )}
            <Row>
              <Col>
                <Row
                // style={{ fontSize: 26, marginTop: 21 }}
                >
                  <div className="el-details-courseDescription">
                    Course Description
                  </div>
                </Row>
                {courseDetail.description && (
                  <Row>
                    <Col>
                      <Row
                        className={"el-details-contentPadding"}
                        style={{
                          marginright: "1rem",
                        }}
                      >
                        {showFullDescription ? (
                          <div className={"el-details-showMore"}>
                            <ReactHeight
                              onHeightReady={(height) => setHeightDiv(height)}
                            >
                              <div>{parse(courseDetail.description)}</div>
                            </ReactHeight>
                          </div>
                        ) : (
                          <div className={"el-details-showLess"}>
                            <ReactHeight
                              onHeightReady={(height) => setHeightDiv(height)}
                            >
                              <div>{parse(courseDetail.description)}</div>
                            </ReactHeight>
                          </div>
                        )}
                      </Row>
                      <Row>
                        {heightDiv >= 160 && (
                          <button
                            className={`${"el-details-descriptionButton"}`}
                            onClick={() =>
                              setShowFullDescription(!showFullDescription)
                            }
                          >
                            {showFullDescription ? (
                              <>
                                SHOW LESS{" "}
                                <IoIosArrowUp style={{ marginLeft: 8 }} />
                              </>
                            ) : (
                              <>
                                SHOW MORE
                                <IoIosArrowDown style={{ marginLeft: 8 }} />
                              </>
                            )}
                          </button>
                        )}
                      </Row>
                    </Col>
                  </Row>
                )}
                {courseDetail.courseMaterials && (
                  <Row>
                    <a
                      href={courseDetail.courseMaterials || ""}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PrimaryOutlineButton label="Course Materials" />
                    </a>
                  </Row>
                )}
              </Col>
            </Row>
          </Layout>
          <Layout className={"el-details-otherContentContainer"} fluid>
            <Layout className={"el-details-innerContainer"}>
              {window.innerWidth > 786 ? (
                <Row>
                  <Col lg={12} sm={24}>
                    <Row>
                      <div className={"el-details-detailLabel"}>Objectives</div>
                    </Row>
                    <Row>
                      <div
                        className={"el-details-contentPadding"}
                        dangerouslySetInnerHTML={{
                          __html: courseDetail.objectives,
                        }}
                      />
                    </Row>
                    <Row>
                      <div className={"el-details-detailLabel"}>Topics</div>
                    </Row>
                    <Row>
                      <div
                        className={"el-details-contentPadding"}
                        dangerouslySetInnerHTML={{
                          __html: courseDetail.topics,
                        }}
                      />
                    </Row>
                  </Col>
                  <Col lg={12} sm={24}>
                    <Row>
                      <div className={"el-details-detailLabel"}>
                        Targeted Audience
                      </div>
                    </Row>
                    <Row>
                      <div
                        className={"el-details-contentPadding"}
                        dangerouslySetInnerHTML={{
                          __html: courseDetail.targetedAudience,
                        }}
                      />
                    </Row>
                    <Row>
                      <div className={"el-details-detailLabel"}>
                        Prerequisites
                      </div>
                    </Row>
                    <Row>
                      <div
                        className={"el-details-contentPadding"}
                        dangerouslySetInnerHTML={{
                          __html: courseDetail.prerequisites,
                        }}
                      />
                    </Row>
                    <Row>
                      <div className={"el-details-detailLabel"}>
                        Additional Resources
                      </div>
                    </Row>
                    <Row>
                      <div
                        className={"el-details-contentPadding"}
                        dangerouslySetInnerHTML={{
                          __html: courseDetail.recommendations,
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className={"el-details-detailLabel"}
                    style={{
                      marginTop: "1rem",
                      marginLeft: "1rem",
                      fontFamily: "RakutenRoundRegular",
                      fontSize: "1.5rem",
                    }}
                  >
                    Objectives
                  </div>
                  <div
                    className={"el-details-contentPadding"}
                    dangerouslySetInnerHTML={{
                      __html: courseDetail.objectives,
                    }}
                    style={{
                      marginLeft: "1rem",
                      fontFamily: "RakutenRoundRegular",
                    }}
                  />
                  <div
                    className={"el-details-detailLabel"}
                    style={{
                      marginTop: "1rem",
                      fontFamily: "RakutenRoundRegular",
                      marginLeft: "1rem",
                      fontSize: "1.5rem",
                    }}
                  >
                    Topics
                  </div>
                  <div
                    className={"el-details-contentPadding"}
                    style={{
                      marginLeft: "1rem",
                      fontFamily: "RakutenRoundRegular",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: courseDetail.topics,
                    }}
                  />

                  <div
                    className={"el-details-detailLabel"}
                    style={{
                      marginTop: "1rem",
                      fontFamily: "RakutenRoundRegular",
                      marginLeft: "1rem",
                      fontSize: "1.5rem",
                    }}
                  >
                    Targeted Audience
                  </div>

                  <div
                    className={"el-details-contentPadding"}
                    style={{
                      marginLeft: "1rem",
                      fontFamily: "RakutenRoundRegular",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: courseDetail.targetedAudience,
                    }}
                  />

                  <div
                    className={"el-details-detailLabel"}
                    style={{
                      marginTop: "1rem",
                      fontFamily: "RakutenRoundRegular",
                      marginLeft: "1rem",
                      fontSize: "1.5rem",
                    }}
                  >
                    Prerequisites
                  </div>

                  <div
                    className={"el-details-contentPadding"}
                    style={{
                      marginLeft: "1rem",
                      fontFamily: "RakutenRoundRegular",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: courseDetail.prerequisites,
                    }}
                  />

                  <div
                    className={"el-details-detailLabel"}
                    style={{
                      marginTop: "1rem",
                      fontFamily: "RakutenRoundRegular",
                      marginLeft: "1rem",
                      fontSize: "1.5rem",
                    }}
                  >
                    Additional Resources
                  </div>

                  <div
                    className={"el-details-contentPadding"}
                    style={{
                      marginLeft: "1rem",
                      fontFamily: "RakutenRoundRegular",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: courseDetail.recommendations,
                    }}
                  />
                </div>
              )}
            </Layout>
          </Layout>
          <Layout fluid className={"el-details-filler"}></Layout>

          <Layout className={"el-details-publicContentContainer"} fluid>
            <Layout
              className={"el-details-innerContainer"}
              style={{ marginLeft: 0, display: "flex", alignItems: "center" }}
            >
              <div className={"el-details-bannerContainer"}>
                <div className={"el-details-bannerContent"}>
                  <div className={"el-details-bannerLabel"}>Step 1.</div>
                  <div className={"el-details-bannerDescription"}>
                    Explore and Discover the course
                  </div>
                </div>
                <div className={"el-details-bannerPublicHorizontalLine"}></div>
                <div className={"el-details-bannerContent"}>
                  <div className={"el-details-bannerLabel"}>Step 2.</div>
                  <div className={"el-details-bannerDescription"}>
                    Understand Course Objectives and Goals
                  </div>
                </div>
                <div className={"el-details-bannerPublicHorizontalLine"}></div>
                <div className={"el-details-bannerContent"}>
                  <div className={"el-details-bannerLabel"}>Step 3.</div>
                  <div className={"el-details-bannerDescription"}>
                    Launch and Learn at your pace
                  </div>
                </div>
              </div>
            </Layout>
          </Layout>
        </Layout>

        <div style={{ backgroundColor: "white", paddingTop: "0.00625rem" }}>
          <Layout bsPrefix={`container el-details-container`}>
            <div
              className={"el-details-secondContainer"}
              style={{ margin: "auto" }}
            >
              <Quotation />
            </div>
          </Layout>
        </div>
      </div>

    </React.Fragment>
  );
}
