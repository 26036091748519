import React, { useContext } from "react";
import { Modal, Table, Tooltip } from "antd";
import PropTypes from "prop-types";
import style from "./recommendationDetailModal.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { PrimaryButton } from "../../../component-library";
import TimeFormatter from "../../../utils/timeFormatter";
import { CourseContext } from "../../../context/CourseContext";

function RecommendationDetailModal({
  show,
  handleClose,
  rightButton,
  rightButtonClick,
  recommendationDetail,
}) {
  const { userDateFormat } = useContext(CourseContext);
  const tableColumns = [
    {
      title: "User Name",
      dataIndex: "userDetails",
      width: 100,
      ellipsis: true,
      showTitle: false,
      render: (cell, row) => (
        <>
          <Tooltip
            placement="topLeft"
            title={row.userDetails?.firstName + " " + row.userDetails?.lastName}
          >
            <span
              title={row.userDetails?.firstName}
            >{`${row.userDetails?.firstName} ${row.userDetails?.lastName}`}</span>
          </Tooltip>
        </>
      ),
    },
    {
      title: "Recommended At",
      dataIndex: "createdAt",
      key: "age",
      width: 100,

      render: (cell, row) => {
        return <> {TimeFormatter.formatDateTime(cell, null, userDateFormat)}</>;
      },
    },
    {
      title: "Status",
      dataIndex: "address",
      width: 100,

      render: (cell, row) => {
        const { courseDetails, courseType } = recommendationDetail;
        const userDetails = courseDetails?.userDetails;

        return (
          <>
            {userDetails?.courseStatus === "started"
              ? "Viewed"
              : userDetails?.courseStatus
              ? userDetails?.courseStatus
              : "NA"}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        animation={false}
        contentClassName={style.modal}
        centered
        onOk={rightButtonClick}
        open={show}
        onCancel={handleClose}
        footer={[
          <PrimaryButton
            label={rightButton}
            handleClick={rightButtonClick}
            otherStyle={style.buttonStyle}
          />,
        ]}
      >
        {recommendationDetail?.courseType} -{" "}
        {recommendationDetail?.courseDetails?.name}
        <div className={style.modalContent}>
          <Table
            columns={tableColumns}
            dataSource={recommendationDetail.recommendedToDetails}
            pagination={{
              showQuickJumper: true,
            }}
          />
        </div>
      </Modal>
    </>
  );
}

RecommendationDetailModal.propTypes = {
  show: PropTypes.bool,
  leftButton: PropTypes.string,
  rightButton: PropTypes.string,
  handleClose: PropTypes.func,
  leftButtonClick: PropTypes.func,
  rightButtonClick: PropTypes.func,
};

export default RecommendationDetailModal;
