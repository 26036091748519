import React from "react";
import { Progress } from "antd";

export const AntDesignRoundProgressBar = ({
  isPrivate,
  maxCount,
  actualCount,
}) => {
  let strokeColor = "#D1D1D1";

  if (isPrivate === false) {
    strokeColor = "#00A0F0";
  } else if (isPrivate === true) {
    strokeColor = "#F59600";
  }

  return (
    <Progress
      type="circle"
      percent={(actualCount / maxCount || 1) * 100}
      size={48}
      strokeColor={strokeColor}
      format={() => `${actualCount}${maxCount ? `/${maxCount}` : ""}`}
    />
  );
};

export default AntDesignRoundProgressBar;
