import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import Avatar from "react-avatar";
import { Col, Container, Navbar, NavDropdown, Row } from "react-bootstrap";
import { useLocation, NavLink } from "react-router-dom";

import growIcon from "../../assets/icons/RLMS_LOGO.png";

import { UserContext } from "../../context/UserContext";
import DropdownSubmenu from "../customDropdownSubmenu/CustomSubmenu";
import UserSearchBar from "../userSearchBar/UserSearchBar";

import "./pageHeaderOld.scss";
import style from "./pageHeader.module.css";
import { MobileNavbar } from "./MobileNavbar";

import { LoginContext } from "../../context/LoginContext";
import { CategoryContext } from "../../context/CategoryContext";
import { serializeCategoryResponse } from "../../serializer/categorySerializer";

function PageHeader({ active }) {
  const [records] = useState([]);
  const [showInstructorLedDropdown, setShowInstructorLedDropdown] =
    useState(false);
  const [showElearningDropdown, setShowElearningDropdown] = useState(false);
  const [showAvtarDropdown, setShowAvtarDropdown] = useState(false);
  const { logout } = useContext(LoginContext);

  const { userName, userCountry, userEmail, hasRole, userConfig } =
    useContext(UserContext);

  const isAdmin = hasRole(["ILAdmin", "ELAdmin", "superAdmin"]);
  const isManager = hasRole(["Manager"]);
  const [navbar, setNavbar] = useState(false);
  const [homePageCheck, setHomePageCheck] = useState(false);
  const showLogos = true;
  const { categoryResponse } = useContext(CategoryContext);

  const location = useLocation();

  useEffect(() => {
    if (document.location.pathname === "/home") {
      setHomePageCheck(true);
    } else {
      setHomePageCheck(false);
    }
  }, [location]);

  useEffect(() => {
    if (categoryResponse && records.length === 0) {
      const serializedCategoryResponse =
        serializeCategoryResponse(categoryResponse);
      serializedCategoryResponse.map((item) => {
        let record = records.find((rec) => {
          return rec.parentName === item.parent;
        });

        if (record) {
          // If parent category already exist in list, Add category to the sub list
          return record.childs.push({ id: item.id, name: item.name });
        } else {
          record = {
            childs: [],
            parentId: item.parent_id,
            parentName: item.parent,
          };
          record.childs.push({ id: item.id, name: item.name });
          return records.push(record);
        }
      });
    }
  }, [records, categoryResponse]);

  const activateInstructorLedDropdown = (e) => {
    setShowInstructorLedDropdown(!showInstructorLedDropdown);
  };
  const hideInstructorLedDropdown = (e) => {
    setShowInstructorLedDropdown(false);
  };

  const activateElearningDropdown = (e) => {
    setShowElearningDropdown(!showElearningDropdown);
  };
  const hideElearningDropdown = (e) => {
    setShowElearningDropdown(false);
  };

  const activateAvtarDropdown = (e) => {
    setShowAvtarDropdown(!showAvtarDropdown);
  };
  const hideAvtarDropdown = (e) => {
    setShowAvtarDropdown(false);
  };
  const changeBackground = () => {
    if (homePageCheck) {
      if (window.scrollY >= 80) setNavbar(true);
      else setNavbar(false);
    } else setNavbar(true);
  };

  window.addEventListener("scroll", changeBackground);
  // TODO Changes made for current commit should be rechecked its temp fix
  const createMenuPanel = (itemParentId, children, elearning) =>
    children?.map((children, index) => (
      <NavDropdown.Item
        key={index}
        className={style.navDropDownLink}
        href={`/${
          elearning ? "e-learning-courses-list" : "Classroom_training"
        }/${itemParentId}/${children.id}`}
      >
        <p>{children.name}</p>
      </NavDropdown.Item>
    ));

  const getSubmenu = (itemParentId, childArr, elearning = false) => (
    <Row>
      <Col className={style.submenuPanelContainer}>
        {childArr &&
          childArr.map((children, index) => (
            <div className={style.submenuPanel} key={index}>
              {createMenuPanel(itemParentId, children, elearning)}
            </div>
          ))}
      </Col>
    </Row>
  );

  return (
    <>
      {window.location.pathname.startsWith("/knowledge-base")
        ? ""
        : active && (
            <div
              className={
                window.innerWidth > 768
                  ? homePageCheck
                    ? navbar
                      ? style.pageHeaderScrolled
                      : style.pageHeaderScrolled
                    : style.pageHeaderScrolled
                  : ""
              }
            >
              {window.innerWidth > 768 ? (
                <Container fluid className={style.containerStyle}>
                  <Row className={style.growLogoStyle}>
                    <Col lg={6}>
                      <Row>
                        <Col lg={2}>
                          <a href="/">
                            <img
                              className={style.growLogo}
                              src={
                                homePageCheck
                                  ? navbar
                                    ? growIcon
                                    : growIcon
                                  : growIcon
                              }
                              alt="Grow Logo"
                            />
                          </a>
                        </Col>
                        <Col lg={3} className={style.navbarStyle}>
                          <Navbar
                            bg="light"
                            expand="lg"
                            className="instructorLedDropdown"
                          >
                            <NavDropdown
                              title="INSTRUCTOR-LED"
                              id="menu-instructor-led"
                              bsPrefix={
                                homePageCheck
                                  ? navbar
                                    ? style.menutitles
                                    : style.menutitles
                                  : style.menutitles
                              }
                              show={showInstructorLedDropdown}
                              onMouseEnter={activateInstructorLedDropdown}
                              onMouseLeave={hideInstructorLedDropdown}
                              className={[
                                "instructorLedDropdownItem",
                                style.calendarStyle,
                              ]}
                              style={{ marginLeft: "-1rem" }}
                            >
                              <a href="/Classroom_training/all/all">
                                <label className={style.allCourses}>
                                  ALL COURSES
                                </label>
                              </a>
                              <NavDropdown.Divider />
                              {records &&
                                records.map((item, index) => {
                                  if (showInstructorLedDropdown) {
                                    const childArr = [];
                                    let childLength = item?.childs?.length;
                                    const panelCount =
                                      childLength % 6 === 0
                                        ? childLength / 6
                                        : Math.floor(childLength / 6) + 1;
                                    if (item && item.childs) {
                                      for (let i = 0; i < panelCount; i++) {
                                        const start = i * 6;
                                        const end =
                                          start + 6 <= childLength
                                            ? start + 6
                                            : childLength;
                                        childArr.push(
                                          item.childs.slice(start, end)
                                        );
                                      }
                                    }
                                    const dropdownURL = `/Classroom_training/${item?.parentId}/all`;
                                    return (
                                      <div key={index}>
                                        <DropdownSubmenu
                                          href={dropdownURL}
                                          title={item.parentName}
                                          className="submenu"
                                        >
                                          <div
                                            className={style.marginTopSubmenu}
                                          >
                                            {" "}
                                            <label
                                              className={style.lableInDropdown}
                                            >
                                              COURSE CATEGORIES
                                            </label>
                                            {item?.childs &&
                                              item?.parentId &&
                                              getSubmenu(
                                                item?.parentId,
                                                childArr
                                              )}
                                          </div>
                                        </DropdownSubmenu>
                                      </div>
                                    );
                                  } else {
                                    return "";
                                  }
                                })}
                            </NavDropdown>
                          </Navbar>
                        </Col>
                        <Col lg={3}>
                          <Navbar
                            bg="light"
                            expand="lg"
                            className="eLearningDropdown"
                          >
                            <NavDropdown
                              title="E-LEARNING"
                              id="menu-e-learning"
                              bsPrefix={
                                homePageCheck
                                  ? navbar
                                    ? style.menutitles
                                    : style.menutitles
                                  : style.menutitles
                              }
                              show={showElearningDropdown}
                              onMouseEnter={activateElearningDropdown}
                              onMouseLeave={hideElearningDropdown}
                              className={[
                                "eLearningDropdownItem",
                                style.calendarStyle,
                              ]}
                              style={{ marginLeft: "-1rem" }}
                            >
                              <a href="/e-learning-courses-list/all/all">
                                <label className={style.allCourses}>
                                  ALL COURSES
                                </label>
                              </a>
                              <NavDropdown.Divider />
                              {records &&
                                records.map((item, index) => {
                                  if (showElearningDropdown) {
                                    const childArr = [];
                                    let childLength = item?.childs?.length;
                                    const panelCount =
                                      childLength % 6 === 0
                                        ? childLength / 6
                                        : Math.floor(childLength / 6) + 1;
                                    if (item && item.childs) {
                                      for (let i = 0; i < panelCount; i++) {
                                        const start = i * 6;
                                        const end =
                                          start + 6 <= childLength
                                            ? start + 6
                                            : childLength;
                                        childArr.push(
                                          item.childs.slice(start, end)
                                        );
                                      }
                                    }
                                    const dropdownURL = `/e-learning-courses-list/${item?.parentId}/all`;
                                    return (
                                      <div key={index}>
                                        <DropdownSubmenu
                                          href={dropdownURL}
                                          title={item.parentName}
                                          className="submenu"
                                        >
                                          <div
                                            className={style.marginTopSubmenu}
                                          >
                                            {" "}
                                            <label
                                              className={style.lableInDropdown}
                                            >
                                              COURSE CATEGORIES
                                            </label>
                                            {item?.childs &&
                                              item?.parentId &&
                                              getSubmenu(
                                                item?.parentId,
                                                childArr,
                                                true
                                              )}
                                          </div>
                                        </DropdownSubmenu>
                                      </div>
                                    );
                                  } else {
                                    return "";
                                  }
                                })}
                            </NavDropdown>
                          </Navbar>
                        </Col>
                        <Col lg={2}>
                          <Navbar
                            bg="light"
                            expand="lg"
                            className={[
                              homePageCheck
                                ? navbar
                                  ? style.headermenutitle
                                  : style.headermenutitleHome
                                : style.headermenutitle,
                              style.learningPathStyle,
                            ]}
                            style={{ marginLeft: "-2rem" }}
                          >
                            <a
                              style={{
                                color: homePageCheck
                                  ? navbar
                                    ? "black"
                                    : "black"
                                  : "black",
                              }}
                              href="/LearningPath"
                            >
                              LEARNING PATH
                            </a>
                          </Navbar>
                        </Col>
                        <Col lg={1}>
                          <Navbar
                            bg="light"
                            expand="lg"
                            className={[
                              homePageCheck
                                ? navbar
                                  ? style.headermenutitle
                                  : style.headermenutitleHome
                                : style.headermenutitle,
                              style.calendarStyle,
                            ]}
                          >
                            <a
                              style={{
                                color: homePageCheck
                                  ? navbar
                                    ? "black"
                                    : "black"
                                  : "black",
                              }}
                              href="/Calendar"
                            >
                              CALENDAR
                            </a>
                          </Navbar>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6}>
                      <Row className={style.iconSet}>
                        <Col lg={5}></Col>
                        <Col lg={6}>
                          <UserSearchBar
                            id="searchBar"
                            hasImage={
                              homePageCheck ? (navbar ? false : true) : true
                            }
                          />
                        </Col>
                        <Col lg={1}>
                          <div className={style.avatrar}>
                            <Avatar
                              size={40}
                              textSizeRatio={2}
                              color={userConfig?.primary}
                              name={userName}
                              round={true}
                              alt="U"
                              className={style.profileAvtar}
                              onClick={activateAvtarDropdown}
                            />
                            <Navbar
                              bg="light"
                              expand="lg"
                              className="avtarDropdown"
                            >
                              <NavDropdown
                                show={showAvtarDropdown}
                                className="avtarDropdownItem"
                                onMouseLeave={hideAvtarDropdown}
                              >
                                <NavDropdown.Item
                                  href="/Profile"
                                  className="avtarDropdownItem"
                                >
                                  <Row>
                                    <Col lg={3}>
                                      <Row>
                                        <Avatar
                                          size={40}
                                          textSizeRatio={2}
                                          color={userConfig?.primary}
                                          name={userName}
                                          round={true}
                                          alt="U"
                                          className={style.profileAvtar}
                                        />
                                      </Row>
                                    </Col>
                                    <Col lg={9}>
                                      <Row className={style.rowStyle}>
                                        {userName}
                                      </Row>
                                      <Row className={style.rowStyle}>
                                        <div className={style.wrapText}>
                                          {userEmail}
                                        </div>
                                      </Row>
                                    </Col>
                                  </Row>
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item
                                  as={NavLink}
                                  to="/myLearnings"
                                >
                                  My Learnings
                                </NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to="/Profile">
                                  My Profile
                                </NavDropdown.Item>
                                {isAdmin && (
                                  <NavDropdown.Item as={NavLink} to="/Admin">
                                    Admin
                                  </NavDropdown.Item>
                                )}
                                {isManager && (
                                  <NavDropdown.Item as={NavLink} to="/Manager">
                                    Manager
                                  </NavDropdown.Item>
                                )}
                                <NavDropdown.Item href="" onClick={logout}>
                                  Logout
                                </NavDropdown.Item>
                              </NavDropdown>
                            </Navbar>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              ) : (
                <MobileNavbar categories={records} />
              )}
            </div>
          )}
    </>
  );
}

PageHeader.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default PageHeader;