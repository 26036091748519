import React from "react";
import { Divider, Typography } from "antd";
import DOMPurify from "dompurify";
import "./classroomScheduleDetails.css";
import TimeFormatters from "../../../../utils/timeFormatter";
import moment from "moment";
import { useContext } from "react";
import { CourseContext } from "../../../../context/CourseContext";

const ClassroomScheduleDetails = ({ schedule }) => {
  const { start_time, end_time, schedule: session } = schedule;
  const { agenda, location, trainer_details = [] } = session[0] || {};
  const { trainer_name } = trainer_details[0] || {};
  const { userDateFormat } = useContext(CourseContext);

  const offset = moment().utcOffset() / 60;

  const renderDateTime = () => (
    <span className="schedule-details-title" data-testid="date-time">
      {(TimeFormatters.FormatTableTime(start_time, offset) ===
        TimeFormatters.FormatTableTime(end_time, offset) &&
        TimeFormatters.FormatTableTime(start_time, offset, userDateFormat)) ||
        `${TimeFormatters.FormatTableTime(start_time, offset, userDateFormat)}
            - ${TimeFormatters.FormatTableTime(
              end_time,
              offset,
              userDateFormat
            )}`}
    </span>
  );

  return (
    <>
      <div
        style={{
          height: "1px",
          backgroundColor: "#B6B6B6",
          width: "21.5rem",
          marginBottom: "1rem",
        }}
        data-testid="divider"
      ></div>
      <Typography className="schedule-details-title" data-testid="agenda-title">
        AGENDA
      </Typography>
      {agenda ? (
        <>
          <Typography className="schedule-details-value" data-testid="date">
            Date: {renderDateTime()}
          </Typography>
          <Typography className="trainer-details" data-testid="trainer-details">
            Trainer:{" "}
            <span className="schedule-details-title" data-testid="trainer-name">
              {trainer_name}
            </span>
            , {location}
          </Typography>
          <div
            className="schedule-details-value"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(agenda) }}
            data-testid="agenda-content"
          ></div>
        </>
      ) : (
        <Typography className="schedule-details-value" data-testid="no-modules">
          No Modules Available
        </Typography>
      )}
    </>
  );
};

export default ClassroomScheduleDetails;
