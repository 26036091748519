import { Col, Row, Typography } from "antd";
import DOMPurify from "dompurify";
import "./courseAdditionalDetails.css";

const CourseAdditionalDetails = ({
  course = {},
  overrideHeadingStyle = {},
  overrideParentContainer = "",
}) => {
  const {
    objectives,
    targetedAudience,
    prerequisites,
    topics,
    recommendations,
  } = course;
  const parentContainerClass = `course-additional-details ${overrideParentContainer}`;
  const isMobile = window.innerWidth < 786;

  const renderObjectives = () => {
    return (
      <Col data-testid="objectives-col">
        <Typography
          style={{ ...overrideHeadingStyle }}
          className="course-details-heading"
          data-testid="objectives-heading"
        >
          Objectives
        </Typography>
        {objectives ? (
          <div
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(objectives) }}
            data-testid="objectives-content"
          />
        ) : (
          <div data-testid="objectives-content">N/A</div>
        )}
      </Col>
    );
  };

  const renderTargetedAudience = () => {
    return (
      <Col data-testid="targeted-audience-col">
        <Typography
          style={{
            ...overrideHeadingStyle,
          }}
          className="course-details-heading"
          data-testid="targeted-audience-heading"
        >
          Targeted Audience
        </Typography>
        {targetedAudience ? (
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(targetedAudience),
            }}
            data-testid="targeted-audience-content"
          />
        ) : (
          <div data-testid="targeted-audience-content">N/A</div>
        )}
      </Col>
    );
  };

  const renderPrerequisites = () => {
    return (
      <Col data-testid="prerequisites-col">
        <Typography
          style={{
            ...overrideHeadingStyle,
          }}
          className="course-details-heading"
          data-testid="prerequisites-heading"
        >
          Prerequisites
        </Typography>
        {prerequisites ? (
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(prerequisites),
            }}
            data-testid="prerequisites-content"
          />
        ) : (
          <div data-testid="prerequisites-content">N/A</div>
        )}
      </Col>
    );
  };

  return (
    <Row
      className={parentContainerClass}
      data-testid="course-additional-details"
    >
      <Col
        span={isMobile ? 24 : 12}
        className="course-details-child-container-lp"
        data-testid="objectives-container"
      >
        {renderObjectives()}
      </Col>
      <Col
        span={isMobile ? 24 : 12}
        className="course-details-child-container-lp"
        data-testid="audience-prerequisites-container"
      >
        <Row
          span={isMobile ? 24 : 12}
          className="course-details-audience"
          data-testid="targeted-audience-container"
        >
          {renderTargetedAudience()}
        </Row>
        <Row span={isMobile ? 24 : 12} data-testid="prerequisites-container">
          {renderPrerequisites()}
        </Row>
      </Col>
    </Row>
  );
};

export default CourseAdditionalDetails;
