import React, { useState, useEffect } from "react";
import InstructorLedCourse from "../../../../assets/icons/InstructorLedCourse.svg";
import style from "./CoursesInProgress.module.css";
import TimeFormatter from "./../../../../utils/timeFormatter";
import { Link } from "react-router-dom";
import "./courseInProgress.scss";

function CoursesInProgress({ courseList = [], userConfig }) {
  const [upcomingSessions, setUpcomingSessions] = useState([]);
  const { primary } = userConfig;

  useEffect(() => {
    if (courseList.length === 0) {
    } else {
      courseList.forEach((course, index) => {
        const courseImage =
          course.image || `${process.env.PUBLIC_URL}/default.png`;
        const courseDetailUrl = `course-details/${course.id}`;
        const courseItem = (
          <Link
            className={style.courseTile}
            to={courseDetailUrl}
            data-testid={`course-item-${index + 1}`}
          >
            <div className={style.subheading} data-testid="course-subheading">
              <img
                className={style.subheadingImage}
                src={InstructorLedCourse}
                alt="Intructor Led"
                data-testid="course-image"
              />
              <span className={style.subheadingText} data-testid="course-type">
                COURSE
              </span>
            </div>
            <div className={"courseName"} data-testid="course-name">
              {course.courseName}
            </div>
            <div className={style.seesionStart} data-testid="course-start">
              Starts at {TimeFormatter.getLongDateTime(course.sessionStart)}
            </div>
            <div
              className={style.courseImage}
              style={{
                backgroundImage: `url(${courseImage})`,
              }}
              data-testid="course-image-background"
            ></div>
          </Link>
        );
        if (upcomingSessions.length < 2) {
          setUpcomingSessions([...upcomingSessions, courseItem]);
        }
      });
    }
    // eslint-disable-next-line
  }, [courseList]);

  return (
    <div data-testid="courses-in-progress">
      {courseList.length === 0 && (
        <div className="parent-container" data-testid="no-courses">
          <div className="enrollment-container">
            <p className="enrollment-status" data-testid="enrollment-status">
              You are not enrolled to any course yet!
            </p>
            <p
              className="enrollment-sub-heading"
              data-testid="enrollment-sub-heading"
            >
              Choose from the most popular industry trends to keep yourself upto
              date with the ever changing landscape. Learning Management System
              platform will help you connect the dots with the best in className
              instructors.
            </p>
          </div>
          <div>
            <div
              className="placeholderFlowContainer"
              data-testid="placeholder-flow"
            >
              <div className="placeholderFlowFirstCircle">
                <span
                  className="placeholderFlowFirstText"
                  style={{ color: primary }}
                  data-testid="flow-explore-enroll"
                >
                  Explore & Enroll
                </span>
              </div>
              <div className="placeholderFlowSecondCircle">
                <span
                  className="placeholderFlowSecondText"
                  style={{ color: primary }}
                  data-testid="flow-confirmation"
                >
                  Get Confirmation via Email
                </span>
              </div>
              <div className="placeholderFlowSecondCircle">
                <span
                  className="placeholderFlowThirdText"
                  style={{ color: primary }}
                  data-testid="flow-attend"
                >
                  Attend the Course and Learn
                </span>
              </div>
              <div className="placeholderLineContainer">
                <hr className="placeholderLineHr" />
                <span
                  className="placeholderLineText"
                  data-testid="learn-engage-explore"
                >
                  LEARN. ENGAGE. EXPLORE.
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {courseList.length > 0 && (
        <div className={"headerContainer1"} data-testid="courses-list">
          <div
            className={"headerContainer"}
            style={{ display: "flex", justifyContent: "space-between" }}
            data-testid="courses-taking-header-container"
          >
            <div
              className={"courses-taking-header"}
              data-testid="courses-taking-header"
            >
              Courses You’re Taking!
            </div>
            <a
              className={"myLearning"}
              href="/MyLearnings"
              data-testid="my-learnings-link"
            >
              MY LEARNINGS
            </a>
          </div>
          <div
            className={"mobileScroll upcoming-session-lg-screen"}
            data-testid="upcoming-sessions"
          >
            {upcomingSessions}
          </div>
        </div>
      )}
    </div>
  );
}

export default CoursesInProgress;
