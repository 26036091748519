import RolesRoute from "./Router/RolesRoute";
import UserRoute from "./Router/UserRoute";
import Dashboard from "../pages/admin/dashboard/AntdDashboard";
import CourseList from "../pages/admin/courseList/CourseList";
import CourseAddEdit from "../pages/admin/courseAddEdit/AntdCourseAddEdit";
import CategoryManagement from "../pages/admin/categoryManagement/AntdCategoryManagement";
import SessionList from "../pages/admin/sessionList/SessionList";
import ModuleList from "../pages/admin/moduleList/ModuleList";
import WaitlistUsersList from "../pages/admin/waitlistUsersList/WaitlistUsersList";
import AntdSessionUsersList from "../pages/admin/sessionUsersList/AntdSessionUserList";
import NotificationList from "../pages/admin/notification/NotificationList";
import ModuleAddEdit from "./../pages/admin/moduleAddEdit/moduleAddEdit";
import SessionAddEdit from "../pages/admin/sessionAddEdit/AntdSessionAddEdit";
import Profile from "../pages/user/profile/Profile";
import AttendanceHome from "../pages/admin/attendanceHome/AttendanceHome";
import AttendanceDetailList from "../pages/admin/attendanceDetailList/AttendanceDetailList";
import CourseImageList from "./../pages/admin/courseImageList/CourseImageList";
import Home from "./../pages/user/home/Home";
import Calendar from "./../pages/user/calendar/Calendar";
import NewCalendar from "./../pages/user/calendar/NewCalendar";
import CourseDetail from "../pages/user/courseDetail/CourseDetail";
import CourseImageAddEdit from "../pages/admin/courseImageAddEdit/CourseImageAddEdit";
import InstructorLed from "../pages/user/instructorLed/InstructorLed";
import Report from "../pages/admin/report/AntdReport";
import ElearningCourseFileUpload from "../pages/admin/eLearning/courseFileUpload/ElearningCourseFileUpload";
import ElearningCourseAddEdit from "../pages/admin/eLearning/elearningCourseAddEdit/ElearningCourseAddEdit";
import ScormPlayer from "../pages/user/eLearning/scormPlayer/ScormPlayer";
import ElearningUserCourseList from "../pages/user/elearningUserCourseList/ElearningUserCourseList";
import ElearningCourseDetail from "../pages/user/eLearning/elearningCourseDetail/ElearningCourseDetail";
import ApprovalList from "../pages/user/approval/ApprovalList";
import PastSessionAddEdit from "../pages/admin/pastSessionAddEdit/PastSessionAddEdit";
import UserReport from "../pages/admin/userReport/AntduserReport";
import AdminPromotionalBanner from "../pages/admin/adminPromotionalBanner/AntdAdminPromotionalBanner";
import BannerAddEdit from "../pages/admin/adminPromotionalBanner/AntdBannerAddEdit";
import SearchResultDetails from "../pages/user/courseSearch/SearchResultDetails";
import LearningPathsList from "../pages/admin/learningPaths/learningPathsList/LearningPathsList";
import LearningPathsAddEdit from "../pages/admin/learningPaths/learningPathsAddEdit/antdLearningPathAddEdit";
import LearningPathDetail from "../pages/user/learningPath/learningPathDetail/AntdLearningPathDetail";
import LearningPathListing from "../pages/user/learningPath/learningPathListing/antdLearningPathListing";
import ManagerDashboard from "../pages/manager/Dashboard/AntdManagerDashboard";
import CourseDetails from "../pages/manager/CourseDetail/AntdCourseDetail";
import ManagerSessionList from "../pages/manager/CourseDetail/ManagerSessionList";
import MyTeam from "../pages/manager/myTeam/AntdMyTeam";
import MyRecommendations from "../pages/manager/viewYourRecommendation/AntdviewYourRecommendation";
import SelectRecommendedCourses from "../pages/manager/viewYourRecommendation/AntdSelectCourses";
import MyApprovals from "../pages/manager/MyApprovals/AntdMyApproval";
import TeamReport from "../pages/manager/TeamReport/AntdTeamReport";
import LearnerHistory from "../pages/admin/learnerHistory/learnerHistory";
import AntdElearningCourseList from "../pages/admin/eLearning/courseList/AntdElearningCourseList";
import AntdCompletedUsers from "../pages/admin/eLearning/completedUsers/AntdCompletedUsers";
import AntdInCompletedUsers from "../pages/admin/eLearning/incompleteUsers/AntdInCompletedUsers";
import AntdAssignedUsers from "../pages/admin/eLearning/assignedUsers/AntdAssignedUsers";
import AntdAdminPrivileges from "../pages/admin/adminPrivileges/AntdAdminPrivileges";
import Settings from "../pages/admin/settings/Settings";
import EmployeeManagement from "../pages/admin/employeeManagement/EmployeeManagement";
import ApiKeys from '../pages/admin/thirdPartyApiKeysManagement/ApiKeyManagement';
import MyLearningsNew from "../pages/user/myLearnings/MyLearningsNew";

const superAdminOnly = ["superAdmin"];
const ELAdmin = ["ELAdmin", "superAdmin"];
const ILAdmin = ["ILAdmin", "superAdmin"];
const Manager = ["Manager"];
const allAdminRoles = ["ELAdmin", "ILAdmin", "superAdmin"];
const isMobileScreen = window.innerWidth < 786;

export default [
  <UserRoute path="/home" component={Home} title="Home | LMS" />,
  <UserRoute
    path="/Calendar"
    component={isMobileScreen ? NewCalendar : Calendar}
    title="Course Calendar | LMS"
  />,
  <UserRoute
    path="/MyLearnings"
    component={MyLearningsNew}
    title="My Learnings | LMS"
  />,
  <UserRoute path="/Profile" component={Profile} title="My Profile | LMS" />,
  <UserRoute
    path="/course-details/:course_id"
    component={CourseDetail}
    title="Course Information | LMS"
  />,
  <UserRoute
    path="/Classroom_training/:parentCategoryId/:categoryId"
    component={InstructorLed}
    title="Instructor-Led Courses | LMS"
  />,
  <UserRoute
    path="/my-approval"
    component={ApprovalList}
    title="Approval List | LMS"
  />,
  <UserRoute
    path="/LearningPath"
    component={LearningPathListing}
    title="Learning Path | LMS"
  />,
  <UserRoute
    path="/e-learning-courses/:courseId/launch"
    component={ScormPlayer}
    title="Course Launch | LMS"
  />,
  <UserRoute
    path="/e-learning-courses/:courseType/:courseId"
    component={ElearningCourseDetail}
    title="E-Learning Course Details | LMS"
  />,
  <UserRoute
    path="/e-learning-courses/:courseId"
    component={ElearningCourseDetail}
    title="E-Learning Course Details | LMS"
  />,
  <UserRoute
    path="/Search"
    component={SearchResultDetails}
    title="Search | LMS"
  />,
  <UserRoute
    exact
    path="/e-learning-courses-list/:parentCategoryId/:categoryId"
    component={ElearningUserCourseList}
    title="E-Learning Courses | LMS"
  />,
  <UserRoute
    path="/learning-paths/:pathId"
    component={LearningPathDetail}
    title="Learning Path Details | LMS"
  />,
  <RolesRoute
    path="/privileges"
    roles={superAdminOnly}
    component={AntdAdminPrivileges}
    title="Admin User Privileges | LMS"
  />,
  <RolesRoute
    path="/Admin"
    roles={allAdminRoles}
    exact
    component={Dashboard}
    title="Admin Dashboard | LMS"
  />,
  <RolesRoute
    path="/Admin/report"
    roles={allAdminRoles}
    exact
    component={UserReport}
    title="Admin Report | LMS"
  />,
  <RolesRoute
    path="/Admin/Category"
    roles={allAdminRoles}
    component={CategoryManagement}
    title="Admin Category Management | LMS"
  />,
  <RolesRoute
    path="/Admin/Courses"
    roles={ILAdmin}
    component={CourseList}
    title="Admin Course List | LMS"
  />,
  <RolesRoute
    path="/Admin/AddEditCourse"
    roles={ILAdmin}
    component={CourseAddEdit}
    title="Admin Add/Edit Course | LMS"
  />,
  <RolesRoute
    path="/promotionalBanner"
    roles={allAdminRoles}
    component={AdminPromotionalBanner}
    title="Admin User Promotional Banner | LMS"
  />,
  <RolesRoute
    path="/Admin/AddEditBanner"
    roles={allAdminRoles}
    component={BannerAddEdit}
    title="Admin Add/Edit Promotional Banner | LMS"
  />,
  <RolesRoute
    path="/dashboard"
    roles={allAdminRoles}
    title="Dashboard | LMS"
    component={Report}
  />,
  <RolesRoute
    path="/Admin/Notification"
    roles={allAdminRoles}
    component={NotificationList}
    title="Admin Notifications | LMS"
  />,
  <RolesRoute
    path="/Admin/SessionList/:course_id"
    roles={ILAdmin}
    component={SessionList}
    title="Admin Session List | LMS"
  />,
  <RolesRoute
    path="/Admin/Add_batch/:course_id"
    roles={ILAdmin}
    title="Admin Add/Edit Session | LMS"
  />,
  <RolesRoute
    path="/Admin/ModuleList/:batch_id"
    roles={ILAdmin}
    component={ModuleList}
    title="Admin Module List | LMS"
  />,
  <RolesRoute
    path="/Admin/AddEditModule/:batch_id"
    roles={ILAdmin}
    component={ModuleAddEdit}
    title="Admin Add/Edit Module | LMS"
  />,
  <RolesRoute
    path="/Admin/waitingList/:course_id"
    roles={ILAdmin}
    component={WaitlistUsersList}
    title="Waitlisted User list | LMS"
  />,
  <RolesRoute
    path="/Admin/enrolled/:batch_id"
    roles={ILAdmin}
    component={AntdSessionUsersList}
    title="Enrolled User List | LMS"
  />,
  <RolesRoute
    path="/Admin/Attendance"
    roles={allAdminRoles}
    exact
    component={AttendanceHome}
    title="Admin Attendance | LMS"
  />,
  <RolesRoute
    path="/Admin/Attendance/:course_id/:batch_id"
    roles={ILAdmin}
    component={AttendanceDetailList}
    title="Admin Attendance | LMS"
  />,
  <RolesRoute
    path="/Admin/AddEditSession"
    roles={ILAdmin}
    component={SessionAddEdit}
    title="Admin Add/Edit Session | LMS"
  />,
  <RolesRoute
    path="/Admin/AddPastSession/:courseId"
    roles={ILAdmin}
    component={PastSessionAddEdit}
    title="Admin Add Past Session | LMS"
  />,
  <RolesRoute
    path="/Admin/Course/ImageList"
    roles={allAdminRoles}
    component={CourseImageList}
    title="Admin Course Images | LMS"
  />,
  <RolesRoute
    path="/Admin/Course/AddEditCourseImage"
    roles={allAdminRoles}
    component={CourseImageAddEdit}
    title="Admin Add/Edit Course Image | LMS"
  />,
  <RolesRoute
    path="/Admin/e-learning/courses"
    roles={ELAdmin}
    component={AntdElearningCourseList}
    title="Admin E-Learning Course List | LMS"
  />,
  <RolesRoute
    path="/Admin/e-learning/AddEditCourse"
    roles={ELAdmin}
    component={ElearningCourseAddEdit}
    title="Admin Add/Edit E-Learning Course | LMS"
  />,
  <RolesRoute
    path="/Admin/e-learning/CourseFileUpload/:courseId"
    roles={ELAdmin}
    component={ElearningCourseFileUpload}
    title="Admin E-Learning File upload | LMS"
  />,
  <RolesRoute
    path="/Admin/e-learning/CompletedUsers/:courseId"
    roles={ELAdmin}
    component={AntdCompletedUsers}
    title="Complete User List | LMS"
  />,
  <RolesRoute
    path="/Admin/e-learning/IncompletedUsers/:courseId"
    roles={ELAdmin}
    component={AntdInCompletedUsers}
    title="Incomplete User List | LMS"
  />,
  <RolesRoute
    path="/Admin/e-learning/AssignedUsers/:courseId"
    roles={ELAdmin}
    component={AntdAssignedUsers}
    title="Assigned User List | LMS"
  />,
  <RolesRoute
    path="/Admin/learning-path/paths"
    roles={allAdminRoles}
    component={LearningPathsList}
    title="Learning Paths | LMS"
  />,
  <RolesRoute
    path="/Admin/learner-history"
    roles={allAdminRoles}
    component={LearnerHistory}
    title="Learner History | LMS"
  />,
  <RolesRoute
    path="/Admin/learningPath/AddEditLearningPath"
    roles={allAdminRoles}
    component={LearningPathsAddEdit}
    title="Admin Add/Edit Learning Paths | LMS"
  />,
  <RolesRoute
    path="/Admin/settings"
    roles={superAdminOnly}
    component={Settings}
    title="Settings | LMS"
  />,
  <RolesRoute
    path="/Admin/employee-management"
    roles={superAdminOnly}
    component={EmployeeManagement}
    title="Employee Management | LMS"
  />,
  <RolesRoute
    path="/Admin/api-keys"
    roles={superAdminOnly}
    component={ApiKeys}
    title="Api Keys | LMS"
  />,
  <RolesRoute
    path="/Manager"
    roles={Manager}
    exact
    component={ManagerDashboard}
    title="Manager Dashboard | LMS"
  />,
  <RolesRoute
    path="/Manager/MyTeam"
    exact
    roles={Manager}
    component={MyTeam}
    title="Manager My Team | LMS"
  />,
  <RolesRoute
    path="/Manager/ViewYourRecommendation"
    exact
    roles={Manager}
    component={MyRecommendations}
    title="Manager My Recommendations | LMS"
  />,
  <RolesRoute
    path="/Manager/ViewYourRecommendation/SelectCourses"
    exact
    roles={Manager}
    component={SelectRecommendedCourses}
    title="Manager My Recommendations | LMS"
  />,
  <RolesRoute
    path="/Manager/MyTeam/CourseDetails/:userId"
    roles={Manager}
    exact
    component={CourseDetails}
    title="Manager My Team Course Details | LMS"
  />,
  <RolesRoute
    path="/Manager/MyTeam/CourseDetails/:userId"
    roles={Manager}
    exact
    component={CourseDetails}
    title="Manager My Team Course Details | LMS"
  />,
  <RolesRoute
    path="/Manager/SessionList/:batchId"
    roles={Manager}
    component={ManagerSessionList}
    title="Manager SessionList | LMS"
  />,
  <RolesRoute
    path="/Manager/MyApprovals"
    roles={Manager}
    component={MyApprovals}
    title="Manager My Approvals | LMS"
  />,
  <RolesRoute
    path="/Manager/TeamReports"
    roles={Manager}
    component={TeamReport}
    title="Manager Team Reports | LMS"
  />,
].map((route, index) => ({ ...route, key: index }));
