import { useContext, useEffect, useRef, useState } from 'react';
import axios from "axios";
import isEqual from 'lodash/isEqual'
import config from '../../../config';
import { useParams } from 'react-router-dom'


import { UserContext } from "../../../context/UserContext";
import buildURL from "../../../utils/buildUrl";

import './ElearningUserCourseList.scss'
const useElearningUserCourseList = () => {

    const { parentCategoryId, categoryId } = useParams();
    const abortControllers = useRef([]);
    const { userConfig } = useContext(UserContext);
    const { primary } = userConfig;
    const isMobile = window.innerWidth < 786;
    const mobileStyles =
        isMobile
            ? {
                justifyContent: "center",
                alignItems: "center",
                marginLeft: ".8rem",
                marginTop: "1rem",
                display: "flex",
                flexWrap: "wrap",
                width: "",
            }
            : {};

    const [alert, setAlert] = useState("")
    const [courseList, setCourseList] = useState([])
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState({ filters: {}, pageNumber: 1, areFiltersInitialized: false })
    const [reset, setReset] = useState(false);
    const [hasMoreCourses, setHasMoreCourses] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const constants = {
        endOfList: "You've reached the end of the list. All the courses are listed above.",
        courseUnavailable: "No course available in your region for the category selected.",
        totalCount: (count) => `Showing ${count} total results for E - Learning Courses`,
        loading: 'Loading...'
    }

    const filterQueryKeyMapping = {
        parentCategoryId: "parentCategoryId",
        categoryId: "categoryId",
        subCategoryId: "subcategoryId",
        courseProviderId: "courseProviderId",
        sortBy: "sort",
    }

    const filterQueryValueMapping = {
        "oldest": "asc",
        "newest": "desc",
        "udemy": "udemy",
        "all": "all"
    }
    const abortAllRequests = () => {
        abortControllers.current.forEach((controller) => controller.abort());
        abortControllers.current = [];
    };
    const fetchCourseDetails = () => {

        if (page.pageNumber === 1 && abortControllers.current.length > 0) {
            abortAllRequests()
        }
        const abortController = new AbortController()
        abortControllers.current.push(abortController)
        setLoading(true)
        const endPoint = `${config.endPoint.elearning}/course?pageSize=${config.elearningCourseList.pagination.pageSize}&pageNumber=${page.pageNumber}&courseStatus=active`;
        const params = {}
        if (page.filters && Object.keys(page.filters).length > 0)
            for (const key of Object.keys(page.filters)) {
                if (filterQueryValueMapping[page.filters[key]] || page.filters[key])
                    params[filterQueryKeyMapping[key] || key] = filterQueryValueMapping[page.filters[key]] || page.filters[key]
            }
        const url = buildURL(endPoint, params)
        axios
            .get(url.toString(), { signal: abortController.signal })
            .then((response) => {
                if (page.pageNumber === 1) setCourseList(response.data.data)
                else setCourseList((current) => [...current, ...response.data.data]);
                if (!response.data.data.length) setHasMoreCourses(false);
            })
            .catch((error) => {
                setAlert(error?.data?.response || "Something went wrong, please try again");
            }).finally(() => {
                setLoading(false)
            });
    };

    const useDeepCompareEffect = (callback, dependencies) => {
        const currentDependenciesRef = useRef();

        if (!isEqual(currentDependenciesRef.current, dependencies)) {
            currentDependenciesRef.current = dependencies;
        }

        useEffect(callback, [currentDependenciesRef.current]);
    };

    useDeepCompareEffect(() => {
        if (page.areFiltersInitialized) {
            (async () => {
                await fetchCourseDetails()
            })()
        }
        return () => {
            abortAllRequests();
        }
    }, [page.pageNumber, page.filters])

    useEffect(() => {
        let tempFilters = {}
        if (parentCategoryId !== "all") tempFilters["parentCategoryId"] = parentCategoryId
        if (categoryId !== "all") tempFilters["categoryId"] = categoryId;
        setPage(page => ({ ...page, filters: tempFilters, areFiltersInitialized: true }))
    }, [parentCategoryId, categoryId])

    const handleFilter = (current) => {
        setHasMoreCourses(true);
        setCourseList([])
        setPage(page => ({ ...page, pageNumber: 1, filters: current }))
    };

    const handleNext = () => {
        setPage(page => ({ ...page, pageNumber: page.pageNumber + 1 }))
    }

    const updateSuperCatergory = (id) => {
        setReset(true);
        setPage(page => ({ ...page, filters: { ...page.filters, parentCategoryId: id, sortBy: 'newest' } }))
    };

    return { handleNext, handleFilter, loading, parentCategoryId, constants, reset, isModalVisible, setAlert, courseList, isMobile, page, mobileStyles, hasMoreCourses, setIsModalVisible, updateSuperCatergory }
}

export default useElearningUserCourseList;