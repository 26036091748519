import { Col, Collapse, Row, Timeline, Tooltip, Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";

import { Button } from "react-bootstrap";
import PathCourseDetails from "./PathCourseDetails";
import "./pathCourses.css";
import "./pathCourses.scss";

import { useHistory } from "react-router-dom";
import { useState, useContext } from "react";

import { ReactComponent as InstructorLedCourse } from "./../../../../assets/icons/InstructorLedCourse.svg";
import { ReactComponent as CourseProvider } from "./../../../../assets/icons/courseProviderIcon.svg";
import { ReactComponent as elearningVideo } from "./../../../../assets/icons/elearningVideo.svg";
import {
  APPROVAL_PENDING,
  APPROVAL_PENDING_LABEL,
  BACKGROUND_COLOR_EL,
  BACKGROUND_COLOR_IL,
  BACKGROUND_COLOR_PRIVATE,
  COMPLETED,
  COMPLETED_LABEL,
  EL,
  ENROLLED,
  ENROLLED_LABEL,
  ENROLL_LABEL,
  IL,
  INTERESTED,
  INTEREST_LABEL,
  NOT_INTERESTED_LABEL,
  PENDING,
  PENDING_LABEL,
  PRIVATE_HIGHLIGHT_COLOR,
  PUBLIC_HIGHLIGHT_COLOR,
  STARTED,
  STARTED_LABEL,
  START_LABEL,
  UNKNOWN_LABEL,
  WAITLIST,
  WAITLIST_LABEL,
} from "../../../../constants";
import { getsessionDetails } from "../../../../api/learningPath/pathCourses";
import { truncate } from "lodash";
import Title from "antd/es/skeleton/Title";
import DOMPurify from "dompurify";
import { UserContext } from "../../../../context/UserContext";

const { Panel } = Collapse;

const PathCourses = ({ pathDetail }) => {
  const history = useHistory();
  const { userConfig } = useContext(UserContext);
  const { primary, secondary } = userConfig;

  const {
    courses,
    id: pathId,
    title,
    isSequential,
    countries,
    userCountry,
    learningpathStatus,
  } = pathDetail;
  const [sessionDetails, setSessionDetails] = useState([]);

  const getBackgroundColor = (courseType, isPrivate, courseProvider) => {
    if (courseProvider === "udemy") {
      return "rgba(234, 82, 82, 0.384)"
    }
    if (isPrivate) {
      return userConfig.secondary + "20";
    }

    if (courseType === IL) {
      return userConfig.primary + "20";
    } else if (courseType === EL) {
      return BACKGROUND_COLOR_EL;
    }
  };

  const renderDotComponent = (dotConfig) => {
    const { status, index } = dotConfig;

    const dotClasses = () => {
      let classname = "dot-component";
      if (status === STARTED) {
        classname += " in-progress-timeline-dot";
      } else if (status === COMPLETED) {
        classname += " completed-timeline-dot";
      }
      return classname;
    };

    const innerDotComponent =
      status === COMPLETED ? (
        <span className="completed-timeline-dot-tick">
          <i class="fa fa-check check-icon"></i>
        </span>
      ) : (
        <span>{index + 1}</span>
      );

    return (
      <div className={dotClasses()} style={{ zIndex: 999 }}>
        {innerDotComponent}
      </div>
    );
  };

  const launchCourse = (courseType, courseId, pathId, pathName, courseName, courseProviderType, url) => {
    if (courseProviderType === "udemy") window.open(url, "_blank")
    else {
      const pathname =
        courseType === EL
          ? `/e-learning-courses/${courseId}/launch`
          : `/course-details/${courseId}`;

      history.push({
        pathname,
        state: {
          learningPath: pathId,
          learningPathName: pathName,
          courseName: courseName,
        },
      });
    }
  };

  const getButtonText = (courseType, userCourseDetails, isPrivate) => {
    let buttonText;

    const defaultLabelForIL =
      isPrivate && courseType === IL ? INTEREST_LABEL : ENROLL_LABEL;

    const defaultLabelForEL =
      isPrivate && courseType === EL ? INTEREST_LABEL : START_LABEL;

    switch (courseType) {
      case IL:
        {
          const { status_text } = userCourseDetails || {};
          switch (status_text) {
            case WAITLIST:
              buttonText = WAITLIST_LABEL;
              break;
            case PENDING:
              buttonText = PENDING_LABEL;
              break;
            case COMPLETED:
              buttonText = COMPLETED_LABEL;
              break;
            case ENROLLED:
              buttonText = ENROLLED_LABEL;
              break;
            case APPROVAL_PENDING:
              buttonText = APPROVAL_PENDING_LABEL;
              break;
            case INTERESTED:
              buttonText = NOT_INTERESTED_LABEL;
              break;
            default:
              buttonText = defaultLabelForIL;
              break;
          }
        }
        break;
      case EL:
        {
          const { courseStatus } = userCourseDetails || {};

          switch (courseStatus) {
            case STARTED:
              buttonText = STARTED_LABEL;
              break;
            case COMPLETED:
              buttonText = COMPLETED_LABEL;
              break;
            default:
              buttonText = defaultLabelForEL;
              break;
          }
        }
        break;
      default:
        buttonText = UNKNOWN_LABEL;
        break;
    }

    return buttonText;
  };

  // TODO Need to add the serialise for course and improve the performance
  const launchButtonStatus = (currentIndex, courseType, userCourseDetails) => {
    // if the course is in completed status even though the previous courses are not completed
    if (
      userCourseDetails?.courseStatus === COMPLETED ||
      userCourseDetails?.status_text === COMPLETED
    ) {
      return true;
    }

    if (currentIndex === 0) {
      // for first course, launch course button should be shown
      return true;
    }

    // iterate all previous items
    for (let index = 0; index < currentIndex; index++) {
      const {
        userDetails,
        user_data,
        courseType: currentCourseType,
      } = courses[index];

      const userCourseDetails =
        currentCourseType === IL ? user_data : userDetails;

      const courseStatus =
        currentCourseType === IL
          ? userCourseDetails?.status_text
          : userCourseDetails?.courseStatus;

      if (courseStatus !== COMPLETED) {
        return false;
      }
    }

    return true;
  };

  const renderAccordionHeader = (course, index) => {
    const {
      id: courseId,
      course_name,
      name,
      duration,
      courseStatus,
      course_provider,
      courseProvider,
      oldCourseProvider,
      image,
      private: isPrivate,
      courseType,
      userDetails,
      user_data,
      language,
      languages,
      url,
      type
    } = course;

    const courseLanguages =
      courseType === IL
        ? language
        : languages?.map((lang) => lang?.name).toString();

    const isLearningPathActive = learningpathStatus === "active";
    const isCourseAvailableInUserRegion = countries?.includes(userCountry);

    const CourseIcon = courseType === IL ? InstructorLedCourse : elearningVideo;
    const isNonActiveElCourse = courseType === EL && courseStatus !== "active";

    const courseStatusButtonColor = isPrivate ? secondary : primary;

    const userCourseDetails = courseType === IL ? user_data : userDetails;
    const buttonText = getButtonText(courseType, userCourseDetails, isPrivate);

    const shouldShowLaunchButton = launchButtonStatus(
      index,
      courseType,
      userCourseDetails
    );

    return (
      <>
        <Row>
          <Col span={4} className="accordion-header-image">
            <img
              src={image || "/default.png"}
              alt=""
              className="image-container"
            />
          </Col>
          <Col span={20} className="accordion-header-content">
            <Typography className="course-index-container">
              <span className="course-icon">
                <CourseIcon className="accordion-icon" />
              </span>
              COURSE #{index + 1}
            </Typography>
            <Row style={{ display: "flex", alignItems: "center" }}>
              <Col span={16}>
                <div
                  className="course-name"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(course_name || name),
                  }}
                ></div>
              </Col>
              {(shouldShowLaunchButton || !isSequential) && (
                <Col span={8} className="course-launch-button">
                  <Row className="course-button-container">
                    <Button
                      className="course-launch-button-container"
                      style={{
                        background: courseStatusButtonColor,
                        borderColor: courseStatusButtonColor,
                      }}
                      disabled={
                        isNonActiveElCourse ||
                        !isCourseAvailableInUserRegion ||
                        !isLearningPathActive
                      }
                      onClick={(event) => {
                        event.stopPropagation();
                        launchCourse(
                          courseType,
                          courseId,
                          pathId,
                          title,
                          course_name || name,
                          type,
                          url
                        );
                      }}
                    >
                      {buttonText}
                    </Button>
                    {isNonActiveElCourse && (
                      <div>
                        <Typography className="inactive-course-text">
                          * Course is currently not active
                        </Typography>
                      </div>
                    )}
                  </Row>
                </Col>
              )}
            </Row>
            <Row className="hours-row-container">
              <Col>
                <Typography className="accoridon-hours-container">
                  {Number(duration).toFixed(2)} HRS /{" "}
                  <Tooltip title={courseLanguages}>
                    {truncate(courseLanguages || "NA", {
                      length: 24,
                    }).toUpperCase()}
                  </Tooltip>
                </Typography>
              </Col>
              <Col>
                <span className="course-provider-icon">
                  <CourseProvider />
                </span>
              </Col>
              <Col>
                <Typography
                  className="course-provider-text"
                  style={{ marginLeft: 4 }}
                >
                  {course_provider || courseProvider
                    ? courseType === "EL"
                      ? courseProvider?.CourseProviderName
                      : courseProvider?.name
                    : oldCourseProvider}
                </Typography>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  const renderMobileAccordionHeader = (course, index) => {
    const {
      id: courseId,
      course_name,
      name,
      duration,
      courseStatus,
      course_provider,
      courseProvider,
      image,
      private: isPrivate,
      courseType,
      userDetails,
      user_data,
      language,
      languages,
    } = course;

    const courseLanguages =
      courseType === IL
        ? language
        : languages?.map((lang) => lang?.name).toString();

    const isLearningPathActive = learningpathStatus === "active";
    const isCourseAvailableInUserRegion = countries?.includes(userCountry);

    const CourseIcon = courseType === IL ? InstructorLedCourse : elearningVideo;
    const isNonActiveElCourse = courseType === EL && courseStatus !== "active";

    const courseStatusButtonColor = isPrivate ? secondary : primary;

    const userCourseDetails = courseType === IL ? user_data : userDetails;
    const buttonText = getButtonText(courseType, userCourseDetails, isPrivate);

    const shouldShowLaunchButton = launchButtonStatus(
      index,
      courseType,
      userCourseDetails
    );
    return (
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "30%",
          }}
        >
          <img
            src={image || "/default.png"}
            alt=""
            className="image-container"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "70%",
            marginLeft: 8,
          }}
        >
          <Typography className="course-index-container">
            <span className="course-icon">
              <CourseIcon className="accordion-icon" />
            </span>
            COURSE #{index + 1}
          </Typography>
          <Typography className="course-name">
            {truncate(course_name, { length: 15 }) ||
              truncate(name, { length: 15 })}
          </Typography>
          <Typography className="accoridon-hours-container">
            {Number(duration)} HRS / {(courseLanguages || "NA").toUpperCase()}
          </Typography>
        </div>
      </div>
    );
  };

  const renderTimelineItem = (course, index) => {
    const {
      courseType,
      id: courseId,
      private: isPrivate,
      userDetails,
      courseProvider
    } = course;

    const { courseStatus } = userDetails || {};
    const backgroundColor = getBackgroundColor(courseType, isPrivate, courseProvider);
    console.log(backgroundColor, "....backgroundColor");
    return (
      <Timeline.Item
        className="custom-timeline-item Itemwidth"
        dot={renderDotComponent({ status: courseStatus, index })}
        // style={{ width: 1232 }}
        onClick={() => {
          if (window.innerWidth < 786) {
            // redirect to course page
            let endpoint = "";
            if (courseType === "IL") {
              endpoint += "/course-details/" + courseId;
            } else {
              endpoint += "/e-learning-courses/" + courseId;
            }
            history.push(endpoint);
          }
        }}
      >
        <Collapse
          accordion
          bordered={false}
          onChange={async (event) => {
            if (event) {
              if (courseType === "IL") {
                try {
                  const sessionDetails = await getsessionDetails(courseId);
                  setSessionDetails(sessionDetails);
                }
                catch (err) {
                  console.log("Session details fetch error:", err);
                  setSessionDetails([])
                }
              }
            }
          }}
          expandIconPosition="end"
          expandIcon={({ isActive }) => (
            <RightOutlined rotate={isActive ? 270 : 90} />
          )}
          className="collapse-container"
          style={{ background: backgroundColor }}
        >
          <Panel
            showArrow={window.innerWidth > 786 ? true : false}
            header={
              window.innerWidth > 786
                ? renderAccordionHeader(course, index)
                : renderMobileAccordionHeader(course, index)
            }
            key={courseId}
            collapsible={window.innerWidth < 786 && "disabled"}
          >
            <PathCourseDetails
              course={course}
              sessionDetails={sessionDetails}
            />
          </Panel>
        </Collapse>
      </Timeline.Item>
    );
  };

  return (
    <Col>
      <Row className="courses-list-container">
        <Typography className="courses-list-text">
          Courses in this path
        </Typography>
      </Row>
      <Row>
        <Timeline>
          {courses?.map((course, index) => renderTimelineItem(course, index))}
        </Timeline>
      </Row>
      <Row></Row>
    </Col>
  );
};

export default PathCourses;
