import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import "./adminDataTableAttendance.css";
import filterFactory from "react-bootstrap-table2-filter";
import { DownloadCSVButton, AdminSearchBar } from "../../component-library";

const AdminDataTableAttendance = React.forwardRef(
	(
		{
			data,
			columns,
			keyField,
			otherElements,
			rowStyle,
			searchBarStyle,
			downloadButtonStyle,
			expandRow,
			selectRow,
			defaultSorted,
			exportCSV,
		},
		ref
	) => {

		return (
			<>
				<ToolkitProvider
					keyField={keyField}
					data={data}
					columns={columns}
					search
					exportCSV={exportCSV}>
					{(props) => (
						<>
							{otherElements}
							<AdminSearchBar
								otherStyle={searchBarStyle}
								{...props.searchProps}
							/>
							<DownloadCSVButton
								otherStyle={downloadButtonStyle}
								{...props.csvProps}
							/>
							<BootstrapTable
								classes="admin-attendance-list"
								{...props.baseProps}
								bootstrap4
								data={data}
								columns={columns}
								keyField={keyField}
								bordered={false}
								rowStyle={rowStyle}
								filter={filterFactory()}
								noDataIndication="No Data Available"
								expandRow={expandRow}
								selectRow={selectRow}
								defaultSorted={defaultSorted}
								ref={ref}
							/>
						</>
					)}
				</ToolkitProvider>
			</>
		);
	}
);

export default AdminDataTableAttendance;
