import React, { useCallback } from 'react';
import { Input } from 'antd';
import { debounce } from 'lodash';
import { SearchOutlined } from '@ant-design/icons';

const CustomSearch = ({ handleSearch }) => {
    const debouncedSearch = useCallback(
        debounce((value) => handleSearch(value), 300),
        []
    );

    const onChange = (e) => {
        debouncedSearch(e.target.value);
    };

    return (
        <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            onChange={onChange}
            style={{ width: 200 }}
            size='large'
        />
    );
};

export default CustomSearch;