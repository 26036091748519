import React from "react";
import style from "./draggableList.module.css";
import elearningVideo from "./../../assets/icons/elearningVideo.svg";
import { ReactComponent as CourseProvider } from "./../../assets/icons/courseProviderIcon.svg";
import TimeFormatter from "../../utils/timeFormatter";
import config from "../../config";
import { getEllipsis } from "../../utils/stringFormatter";
import InstructorLedCourse from "./../../assets/icons/InstructorLedCourse.svg";
import { Row, Tooltip } from "antd";
export default function DraggableItem({ item }) {
  let languages;
  let name;
  if (item?.languages) {
    languages = item?.languages
      .map((language) => language?.name || language)
      .join(", ");
  } else {
    languages = item?.language;
  }

  if (item?.name) {
    name = item?.name;
  } else if (item?.course_name) {
    name = item?.course_name;
  }
  return (
    <div className={style.DraggableItemWrapper}>
      <div className={style.courseRow}>
        <img
          alt="video"
          src={
            item?.courseType === config.instructorLed.alias
              ? InstructorLedCourse
              : elearningVideo
          }
          className={style.courseTypeIndicator}
        />
        <div className={style.courseTitle}> Course #{item.id}</div>
      </div>
      <Tooltip className={style.courseHeading} title={name}>
        {name}
      </Tooltip>
      <div className={style.courseDetails}>
        <Row>
          <div className={style.courseDuration}>
            {item.duration ? `${TimeFormatter.decimalHrsToHrsMin(item.duration, "detailed")} /${getEllipsis(languages, 20)}` : `${getEllipsis(languages, 20)}`}
          </div>
        </Row>
        <Row>
          <div className={style.courseGear}>
            <CourseProvider className={style.courseProviderIcon} />
            {item?.courseType == "EL" &&
              (item?.courseProvider?.CourseProviderName ||
                item?.courseProvider ||
                item?.oldCourseProvider)}
            {item?.courseType == "IL" &&
              (item?.courseProvider?.name || item?.course_provider)}
          </div>
        </Row>
      </div>
    </div>
  );
}
