import { Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import "./employeeManagementTable.scss";

const addKeysToTableData = (tableData) => {
  return tableData.map((data, idx) => ({
    ...data,
    key: `${data.id}-${idx}`,
  }));
};

function EmployeeManagementTable(props) {
  const {
    tableData,
    tableColumns,
    setDataToDownload,
    currentPage,
    setCurrentPage,
  } = props;
  const [selectedRow, setSelectedRows] = useState([]);
  const [localTableData, setLocalTableData] = useState(tableData);

  useEffect(() => {
    setLocalTableData(tableData);
    setSelectedRows([]);
  }, [tableData]);

  useEffect(() => {
    if (selectedRow.length > 0) {
      setDataToDownload(selectedRow);
    } else {
      setDataToDownload([]);
    }
  }, [selectedRow]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
  };

  function CustomRow(props) {
    console.log(props, ".....props");
    return (
      <Tooltip title="Click para ver el detalle del pedido">
        <tr {...props} />
      </Tooltip>
    );
  }

  return (
    <div>
      <Table
        rowSelection={rowSelection}
        dataSource={addKeysToTableData([...localTableData])}
        columns={tableColumns}
        pagination={{
          onChange: (pageNumber) => setCurrentPage(pageNumber),
          current: currentPage,
          position: ["bottomCenter"],
          showQuickJumper: true,
          showSizeChanger: true,
        }}
        rowClassName={(record, index) => {
          return record?.issues?.length > 0 ? "row-color" : null;
        }}
        // TODO: Below code is for future reference
        // components={{
        //   body: {
        //     row: (props, something, someee) => {
        //       return CustomRow(props);
        //     },
        //   },
        // }}
        // onRow={(record, rowIndex) => {
        //   if (record?.issues?.length > 0) {
        //     return {
        //       onMouseEnter: (event) => {},
        //       onMouseLeave: (event) => {},
        //     };
        //   } else {
        //     return null;
        //   }
        // }}
      />
    </div>
  );
}

export default EmployeeManagementTable;
