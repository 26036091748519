import { useEffect, useState, useRef } from "react";
import axios from 'axios'
import config from "../../../../config";
const useSelectCourseFromList = ({ handleClose, onELCourseSelect, onILCourseSelect, selectedELCourseNos, selectedILCourseNos, selectedContries, onlyPublicCourses, pathId }) => {

    const INSTRUCTOR_LED = config.INSTRUCTOR_LED;
    const E_LEARNING = config.E_LEARNING;
    const PAGESIZE = config.PAGESIZE;
    const filterItems = { "INSTRUCTOR-LED": "INSTRUCTOR-LED", "E-LEARNING": "E-LEARNING", "UDEMY": "UDEMY" }
    const initialRender = useRef(true);
    const [filter, setFilter] = useState(filterItems["INSTRUCTOR-LED"]);
    const initialPage = {
        [filterItems["INSTRUCTOR-LED"]]: {
            data: [],
            totalPages: 0,
            pageNumber: 1,
            checkedPages: selectedILCourseNos || [],
            hasMore: false,
            dataEndPoint: `/course/list_2`,
            params: {
                page_size: PAGESIZE,
                is_global: true,
                onlyPublicCourses,
                countries: String(selectedContries.filter((country) => country !== "selectAll")),
                searchString: ""
            },
        },
        [filterItems["E-LEARNING"]]: {
            data: [],
            totalPages: 0,
            pageNumber: 1,
            checkedPages: selectedELCourseNos.filter((course) => course.courseProvider !== "udemy") || [],
            hasMore: false,
            dataEndPoint: `${config.endPoint.elearning}/course`,
            params: {
                courseStatus: "active",
                country: String(selectedContries.filter((country) => country !== "selectAll")),
                turnOffGlobal: true,
                pageSize: PAGESIZE,
                courseProviderName: "rlms",
                courseName: ""
            },
        },
        [filterItems["UDEMY"]]: {
            data: [],
            totalPages: 0,
            pageNumber: 1,
            checkedPages: selectedELCourseNos.filter((course) => course.courseProvider === "udemy") || [],
            hasMore: false,
            dataEndPoint: `${config.endPoint.elearning}/course`,
            path: (id) => `/course-details/${id}`,
            params: {
                courseStatus: "active",
                country: String(selectedContries.filter((country) => country !== "selectAll")),
                turnOffGlobal: true,
                pageSize: PAGESIZE,
                courseProviderName: "udemy",
                courseName: ""
            },
        }
    }
    const [page, setPage] = useState(initialPage)
    const currentPage = page[filter];
    const source = axios.CancelToken.source();

    const handleFilter = (filter) => {
        setFilter(filter);
    };

    const handleSearch = (text) => {
        setPage(page => ({ ...page, [filter]: { ...page[filter], pageNumber: 1, params: { ...page[filter].params, searchString: text, courseName: text } } }))
    };


    const handleCardClicked = (checkedCourse) => {
        const isChecked = currentPage.checkedPages.some((page) => page.id === checkedCourse.id);

        setPage((page) => {
            const updatedCheckedPages = isChecked
                ? currentPage.checkedPages.filter((page) => page.id !== checkedCourse.id)
                : [...currentPage.checkedPages, checkedCourse];

            return {
                ...page,
                [filter]: {
                    ...currentPage,
                    checkedPages: updatedCheckedPages
                }
            };
        });
    };


    const onAddSelectedCoursesToPath = () => {
        onELCourseSelect([...page[filterItems["E-LEARNING"]].checkedPages, ...page[filterItems["UDEMY"]].checkedPages]);
        onILCourseSelect(page[filterItems["INSTRUCTOR-LED"]].checkedPages);
        handleClose();
    };

    const fetchData = () => {
        const params = { ...currentPage.params, page_number: currentPage.pageNumber, pageNumber: currentPage.pageNumber }
        if (!currentPage.params.searchString) delete params.searchString
        axios.get(currentPage.dataEndPoint,
            { params, cancelToken: source.token })
            .then((response) => {
                setPage(page => ({
                    ...page, [filter]:
                    {
                        ...currentPage, data: currentPage.pageNumber > 1 ? [...currentPage.data, ...response.data.data] : response.data.data,
                        totalPages: response.data.total || 0,
                        hasMore: response.data.data.length > 0
                    }
                }))
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                } else {
                    console.error(error);
                }
            });
    }
    const fetchMore = () => {
        setPage((page) => ({ ...page, [filter]: { ...currentPage, pageNumber: page[filter].pageNumber + 1 } }))
    }
    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
            return;
        }
        fetchData();
    }, [currentPage.pageNumber, currentPage.params.searchString]);

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
            return;
        }
        if (page[filter].data.length === 0) {
            fetchData();
        }
    }, [filter]);

    const crumbs = [
        {
            title: "Admin Dashboard",
            to: "/Admin",
        },
        {
            title: "Learning Paths",
            to: "/admin/learning-path/paths",
        },
        {
            title: `${pathId ? "Edit" : "Add"} Learning Path`,
        },
    ];
    return { INSTRUCTOR_LED, E_LEARNING, onAddSelectedCoursesToPath, page, filter, filterItems, handleFilter, handleSearch, crumbs, currentPage, fetchMore, handleCardClicked, }
}

export default useSelectCourseFromList;