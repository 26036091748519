import quote from "./assets/icons/quote.svg";
import eLearningHeroBanner from "./assets/images/eLearningHeroBanner.png";
import heroBanner from "./assets/images/ilCoursesHeroBanner.png";
import managementLeadership from "./assets/images/managementLeadership.png";
import professional from "./assets/images/professionalWomen.png";
import softSkills from "./assets/images/softSkills.png";
import technicalSkills from "./assets/images/technicalSkills.png";
import LearningPathHeroBanner from "./assets/images/learningPathHeroBanner.png";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  errorType: {
    success: "success",
    warning: "warninig",
    error: "danger",
  },
  instructorLed: {
    title: "Instructor-Led Courses",
    alias: "IL",
    description:
      "Join a Classroom to participate in educative and meaningful conversations!",
    heroBanner: heroBanner,
    pagination: {
      pageNumber: 1,
      pageSize: 12,
    },
    sortBy: [
      {
        label: "Newest",
        value: "newest",
      },
      {
        label: "Oldest",
        value: "oldest",
      },
    ],
  },
  elearningCourseList: {
    title: "E-Learning Courses",
    alias: "EL",
    description:
      "Join a Classroom to participate in educative and meaningful conversations!",
    heroBanner: eLearningHeroBanner,
    pagination: {
      pageNumber: 1,
      pageSize: 12,
    },
  },
  learningPath: {
    title: "Learning Paths",
    description:
      "It can be tough to know where to start learning. Paths combine specific courses and tools into one experience to teach you any given skill from start to finish. Paths are aligned to an individual's knowledge level, to help you to develop the right skills in the right order.",
    heroBanner: LearningPathHeroBanner,
    pagination: {
      pageNumber: 1,
      pageSize: 12,
    },
  },
  quotation: {
    title: `When empowerment is your goal, the best possible business results follow`,
    author: 'Hiroshi "Mickey" Mikitani,',
    designation: "CEO and Founder Rakuten",
    icon: quote,
  },

  learningBySkills: {
    title: "Learning By Skills",
    skills: [
      {
        id: 1,
        image: professional,
        title: "professional",
      },
      {
        id: 2,
        image: softSkills,
        title: "Soft Skills",
      },
      {
        id: 3,
        image: managementLeadership,
        title: "Management & Leadership",
      },
      {
        id: 4,
        image: technicalSkills,
        title: "Technical Skills",
      },
    ],
  },

  instructorLedHomePage: {
    pageSize: 11,
    pageNumber: 1,
  },

  elearningHomeComponent: {
    pageSize: 11,
    pageNumber: 1,
  },

  learningPathHomeComponent: {
    pageSize: 12,
    pageNumber: 1,
  },

  courseType: {
    eLearning: "eLearning",
    instructorLed: "instructorLed",
    learningPath: "learningPath",
  },

  defaultParentCategory: {
    label: "All",
    value: "all",
  },

  defaultCategory: {
    label: "All",
    value: "all",
  },

  defaultLanguage: {
    label: "All",
    value: "all",
  },

  defaultProficiency: {
    label: "All",
    value: "all",
  },

  defaultCourseProvider: {
    label: "All",
    value: "all",
  },

  endPoint: {
    elearning: process.env.REACT_APP_ELEARNING_API_URL,
    instructorLed: process.env.REACT_APP_API_URL,
    userService: process.env.REACT_APP_USER_INFO_URL,
    commonService: process.env.REACT_APP_COMMON_SERVICE,
    instructorLedNew: process.env.REACT_APP_INSTRUCTOR_LED_API_URL,
    authService: process.env.REACT_APP_AUTH_SERVICE_URL,
    configManager: process.env.REACT_APP_CONFIG_MANAGER_URL,
  },
  courseImageBucket: process.env.REACT_APP_COURSE_IMAGE_BUCKET,
  ElearningCourseStatus: {
    active: "active",
    archived: "archived",
    deleted: "deleted",
  },

  ElearningButtonText: {
    start: "START",
    resume: "RESUME",
    completed: "COMPLETED",
  },

  courseStatus: {
    pending: "pending",
    completed: "completed",
  },

  userCourseInsights: {
    overAll: [
      { key: "total", label: "Total Courses Accessed (IL+EL)" },
      { key: "recommended", label: "Recommended Courses (IL +EL)" },
      {
        key: "completed",
        label: "Completed Courses (IL+EL)",
        secondaryKey: "completedPercentage",
        insightHtml: "of total courses accessed",
      },
      {
        key: "recommendedCompletedPercentage",
        label: "Recommended Courses Completed",
        secondaryKey: "recommendedCompletedPercentage",
        insightHtml: "of total completed courses",
      },
    ],
    EL: [
      { key: "total", label: "Total EL Courses Accessed (till date)" },
      { key: "assigned", label: "Assigned (Course Count)" },
      {
        key: "viewed",
        label: "Viewed (Course Count)",
        secondaryKey: "viewedPercentage",
        insightHtml: "of total courses accessed",
      },
      {
        key: "completed",
        label: "Completed (Course Count)",
        secondaryKey: "completedPercentage",
        insightHtml: "of total courses accessed",
      },
    ],
    IL: [
      { key: "total", label: "Total IL Courses Accessed (till date)" },
      { key: "enrolled", label: "Enrolled (Course Count)" },
      { key: "waitlisted", label: "Waitlist (Course Count)" },
      { key: "completed", label: "Completed (Course Count)" },
      { key: "attendance_percentage", label: "Attendance %" },
    ],
  },
  isCorrectEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },

  expectedCompletionTimestamp: {
    halfDay: 12 * 60 * 60,
  },
  keycloak: {
    tokenExpiry: 180000,
    CLIENT_ID: "client_id",
    REFRESH_TOKEN: "refresh_token",
    sessionCookieCheckInterval: 150000,
  },

  expectedTimeOuts: {
    tenSeconds: "10000",
    halfSecond: "500",
  },

  userRoles: {
    1: "User",
    2: "IL Admin",
    3: "EL Admin",
    4: "Manager",
    5: "Trainer",
    99: "Super Admin",
  },

  adminDashboardTiles: {
    instructorLed: "Instructor-Led",
    eLearning: "E-learning",
    notification: "Notification",
    attendance: "Attendance",
    courseImages: "Course Images",
    category: "Category",
    dashboard: "Dashboard",
    report: "Reports",
    privilege: "Privilege",
    promotionalBanner: "Promotional Banner",
    learningPaths: "Learning Paths",
    learnerHistory: "Learner History",
    settings: "Settings",
    employeeManagement: "Employee Management",
    apiKeys: 'Api Keys'

  },
  managerDashboardTiles: {
    recommendations: "My Recommendations",
    team: "My Team",
    approval: "My Approvals",
    reports: "My Team Reports",
  },

  userRoleNames: {
    superAdmin: "superAdmin",
    ILAdmin: "ILAdmin",
    ELAdmin: "ELAdmin",
    manager: "Manager",
    trainer: "Trainer",
    user: "user",
  },
  BACKGROUND_IMAGES: [
    "DSPS_Banner.png",
    "DevOpsBanner.png",
    "ccmp-incubation_banner.png",
    "CUSS_Banner.png",
    "OneCloudBanner.png",
  ],
  LOCALSTORAGE_KEYS: {
    RETRY_COUNT: "retryCount",
    REDIRECT_URL: "redirectUrl",
    REACT_TOKEN: "react-token",
    REACT_REFRESH_TOKEN: "react-refresh-token",
    SESSION_STATE: "session_state",
    STATE: "state",
    CODE: "code",
  },
  INSTRUCTOR_LED: "INSTRUCTOR-LED",
  E_LEARNING: "E-LEARNING",
  UDEMY: "UDEMY",
  LEARNINGPATH: "learningPath",
  RECOMMENDATION: "recommendation",
  PAGESIZE: 20,
};
