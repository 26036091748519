import { Button, Col, Divider, Row, Typography } from "antd";
import DOMPurify from "dompurify";
import ShowMoreText from "react-show-more-text";
import { UpOutlined, DownOutlined } from "@ant-design/icons";

import ClassroomSchedules from "./ClassroomSchedules";
import CourseAdditionalDetails from "./CourseAdditionalDetails";
import "./pathCourseDetails.css";
import {
  ADDITIONAL_RESOURCES,
  READ_LESS,
  READ_MORE,
  TOPICS,
} from "../../../../constants";
import EmptyClassroomSchedules from "./EmptyClassroomSchedules";

const PathCourseDetails = ({ course, sessionDetails }) => {
  const { courseType } = course;

  const courseDescriptionSpanWidth = courseType === "IL" ? 12 : 18;

  const renderCourseDescription = () => {
    return (
      <Col data-testid="course-description-container">
        <Row>
          <Typography
            className="course-description"
            data-testid="course-description-title"
          >
            Course Description
          </Typography>
        </Row>
        <Row>
          <ShowMoreText
            lines={3}
            more={
              <div className="read-more-less-container">
                <span
                  className="show-more-less-clickable"
                  data-testid="read-more"
                >
                  {READ_MORE}
                </span>
                <DownOutlined />
              </div>
            }
            less={
              <div className="read-more-less-container">
                <span
                  className="show-more-less-clickable"
                  data-testid="read-less"
                >
                  {READ_LESS}
                </span>
                <UpOutlined />
              </div>
            }
            expanded={false}
            anchorClass="show-more-less-clickable"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(course?.description),
              }}
              data-testid="course-description-content"
            />
          </ShowMoreText>
        </Row>
        {course?.training_material && (
          <Row style={{ marginTop: 16 }}>
            <Button
              className="course-materials"
              onClick={() => {
                window.open(course?.training_material, "_blank");
                return null;
              }}
              data-testid="course-materials-button"
            >
              Course Materials
            </Button>
          </Row>
        )}
        {course?.courseType === "IL" && (
          <Row>
            <Typography className="timezone-alert" data-testid="timezone-alert">
              *Date & Time is set to automatically adjust to your PC's local
              date & time
            </Typography>
          </Row>
        )}
      </Col>
    );
  };

  const renderCourseDetail = (title, content) => {
    return (
      <Col
        className="render-course-detail"
        data-testid={`course-detail-${title}`}
      >
        <Typography
          className="course-details-heading"
          data-testid={`course-detail-title-${title}`}
        >
          {title}
        </Typography>
        {content ? (
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(content),
            }}
            data-testid={`course-detail-content-${title}`}
          />
        ) : (
          <div data-testid={`course-detail-content-${title}`}>N/A</div>
        )}
      </Col>
    );
  };

  return (
    <div className="path-course-details" data-testid="path-course-details">
      <Row>
        <Col
          span={courseDescriptionSpanWidth}
          className="path-course-details-pane"
          data-testid="course-description-pane"
        >
          {renderCourseDescription()}
        </Col>
        <Col
          span={24 - courseDescriptionSpanWidth}
          className="path-course-details-pane"
          id={course?.id}
          data-testid="session-details-pane"
        >
          {courseType === "IL" &&
            (sessionDetails && sessionDetails.length > 0 ? (
              <ClassroomSchedules
                course={course}
                sessionDetails={sessionDetails}
                data-testid="classroom-schedules"
              />
            ) : (
              <EmptyClassroomSchedules data-testid="empty-classroom-schedules" />
            ))}
        </Col>
      </Row>
      <Divider />
      <CourseAdditionalDetails
        course={course}
        overrideParentContainer="classroom-schedules-footer"
        data-testid="course-additional-details"
      />
      <Row
        gutter={(12, 12)}
        className={"path-course-additional-details"}
        data-testid="path-course-additional-details"
      >
        <Col
          span={12}
          className="course-details-child-container"
          data-testid="course-details-topics"
        >
          {renderCourseDetail(TOPICS, course?.topics)}
        </Col>
        <Col
          span={12}
          className="course-details-child-container"
          data-testid="course-details-additional-resources"
        >
          {renderCourseDetail(ADDITIONAL_RESOURCES, course?.recommendations)}
        </Col>
      </Row>
    </div>
  );
};
export default PathCourseDetails;
