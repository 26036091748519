import React, { useState, useEffect } from "react";
import { Row, Modal } from "react-bootstrap";
import axios from "axios";
import {
  AdminDataTable,
  AdminPageSubTitle,
} from "../../../../component-library";
import style from "./courseDetailSessionUsers.module.css";
import "./courseDetailEnrolledUsers.css";
import config from "../../../../config";

function CourseDetailSessionUsers({
  sessionData,
  showUsersModal,
  handleClose,
}) {
  const [showModal, setShowModal] = useState(showUsersModal);
  const [listData, setListData] = useState([]);
  const isMobile = window.innerWidth < 786;
  const tableColumns = [
    {
      dataField: "userName",
      text: "EMP Name",
      style: {
        paddingLeft: isMobile ? "0rem" : "2rem",
      },
      headerStyle: {
        width: "30%",
        paddingLeft: isMobile ? "0rem" : "2rem",
      },
    },
    {
      dataField: "userEmail",
      text: "Email ID",
      style: {
        paddingLeft: isMobile ? "0rem" : "2rem",
      },
      headerStyle: {
        width: "40%",
        paddingLeft: isMobile ? "0.2rem" : "2rem",
      },
    },
    {
      dataField: "country",
      text: "country",
      style: {
        paddingLeft: isMobile ? "0rem" : "4rem",
      },
      headerStyle: {
        width: "30%",
        paddingLeft: isMobile ? "0rem" : "4rem",
      },
    },
  ];

  const closeModal = () => {
    setShowModal(false);
    setListData([]);
    handleClose();
  };

  useEffect(() => {
    getEnrolledUsersData(sessionData.id);
    // eslint-disable-next-line
  }, [sessionData]);

  async function getEnrolledUsers(sessionId, status) {
    try {
      const result = await axios.get(
        `${config?.endPoint?.instructorLedNew}/admin/view?session_id=${sessionId}&status_type=${status}`
      );
      return result.data.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  const getEnrolledUsersData = async (sessionId) => {
    const enrolledUsers = await getEnrolledUsers(sessionId, "enrolled");
    const approvalPendingUsers = await getEnrolledUsers(
      sessionId,
      "approvalPending"
    );
    const total_users = [...enrolledUsers, ...approvalPendingUsers];

    setListData(total_users);
  };
  return (
    <>
      <Modal
        className="enrolledUsersModal"
        show={showModal}
        onHide={() => {
          closeModal();
        }}
        size="lg"
        dialogClassName={style.modalWidth}
        data-testid="enrolled-users-modal"
      >
        <Modal.Header className={style.header} closeButton>
          <Modal.Title data-testid="modal-title">
            <span className={style.modalTitleBold}>Enrolled</span> Users List
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={style.outerContainer}>
            <Row className={style.sessionTitle} data-testid="session-title">
              <AdminPageSubTitle
                courseId={sessionData.id}
                courseName={sessionData.batch_name}
              />
              <span>
                &ensp;(Enrolled User:{" "}
                <span>
                  {sessionData.counter} / {sessionData.size}
                </span>
                ){" "}
              </span>
            </Row>
            <Row>
              <AdminDataTable
                keyField="userEmail"
                data={listData}
                columns={tableColumns}
                searchBarStyle={{ display: "none" }}
                downloadButtonStyle={{ display: "none" }}
                data-testid="data-table"
              />
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CourseDetailSessionUsers;
