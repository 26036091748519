import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/UserContext";
import config from "../../../config";
import { Breadcrumb, Card, Typography } from "antd";
import { Link } from "react-router-dom";
import "./dashboard.scss";

const crumbs = [
  {
    title: (
      <a href="/home" className="crumb-title">
        Home
      </a>
    ),
  },
  {
    title: "Admin Dashboard",
  },
];

const tileDetails = [
  {
    title: config.adminDashboardTiles.instructorLed,
    description: "Add, View and Modify Courses",
    to: "/Admin/Courses",
    access: [config.userRoleNames.superAdmin, config.userRoleNames.ILAdmin],
  },
  {
    title: config.adminDashboardTiles.eLearning,
    description: "Add, View and Modify e-learning courses",
    to: "/Admin/e-learning/courses",
    access: [config.userRoleNames.superAdmin, config.userRoleNames.ELAdmin],
  },
  {
    title: config.adminDashboardTiles.notification,
    description: "Manage notifications and logs",
    to: "/Admin/Notification",
    access: [
      config.userRoleNames.superAdmin,
      config.userRoleNames.ILAdmin,
      config.userRoleNames.ELAdmin,
    ],
  },
  {
    title: config.adminDashboardTiles.attendance,
    description: "View and Track Course Session Attendance",
    to: "/Admin/Attendance",
    access: [config.userRoleNames.superAdmin, config.userRoleNames.ILAdmin],
  },
  {
    title: config.adminDashboardTiles.courseImages,
    description: "Manage Course Images",
    to: "/Admin/Course/ImageList",
    access: [
      config.userRoleNames.superAdmin,
      config.userRoleNames.ELAdmin,
      config.userRoleNames.ILAdmin,
    ],
  },
  {
    title: config.adminDashboardTiles.category,
    description: "Manage Category and Sub-Category",
    to: "/Admin/Category",
    access: [
      config.userRoleNames.superAdmin,
      config.userRoleNames.ELAdmin,
      config.userRoleNames.ILAdmin,
    ],
  },
  {
    title: config.adminDashboardTiles.dashboard,
    description: "RLMS Platform Metrics",
    to: "/dashboard",
    access: [config.userRoleNames.superAdmin, config.userRoleNames.ILAdmin],
  },
  {
    title: config.adminDashboardTiles.report,
    description: "Generate Report",
    to: "/Admin/report",
    access: [
      config.userRoleNames.superAdmin,
      config.userRoleNames.ELAdmin,
      config.userRoleNames.ILAdmin,
    ],
  },
  {
    title: config.adminDashboardTiles.privilege,
    description: "Manage User Role Assignment",
    to: "/privileges",
    access: [config.userRoleNames.superAdmin],
  },
  {
    title: config.adminDashboardTiles.promotionalBanner,
    description: "Manage Promotional Banner",
    to: "/promotionalBanner",
    access: [
      config.userRoleNames.superAdmin,
      config.userRoleNames.ELAdmin,
      config.userRoleNames.ILAdmin,
    ],
  },
  {
    title: config.adminDashboardTiles.learningPaths,
    description: "Add, View and Modify Learning Paths",
    to: "admin/learning-path/paths",
    access: [config.userRoleNames.superAdmin, config.userRoleNames.ELAdmin],
  },
  {
    title: config.adminDashboardTiles.learnerHistory,
    description: "View and track the learner history",
    to: "admin/learner-history",
    access: [config.userRoleNames.superAdmin, config.userRoleNames.ELAdmin],
  },
  {
    title: config.adminDashboardTiles.settings,
    description: "Personalise the application UI",
    to: "admin/settings",
    access: [config.userRoleNames.superAdmin],
  },
  {
    title: config.adminDashboardTiles.employeeManagement,
    description: "Manage employee data",
    to: "admin/employee-management",
    access: [config.userRoleNames.superAdmin],
  },
  {
    title: config.adminDashboardTiles.apiKeys,
    description: "Manage Api Keys",
    to: "admin/api-keys",
    access: [config.userRoleNames.superAdmin],
  },
];

export default function Dashboard() {
  const [notificationCount, setNotificationCount] = useState(0);
  const { hasRole, userRoles, userConfig } = useContext(UserContext);
  const isSuperAdmin = hasRole([config.userRoleNames.superAdmin]);
  const isILAdmin = hasRole([config.userRoleNames.ILAdmin]);
  const [tiles, setTiles] = useState(tileDetails);

  useEffect(() => {
    const filteredTiles = tileDetails.filter((tile) => {
      if (tile.access.some((item) => userRoles.includes(item))) {
        return tile;
      } else {
        return null;
      }
    });
    setTiles(filteredTiles);
  }, [userRoles]);

  useEffect(() => {
    if (isSuperAdmin || isILAdmin) {
      axios
        .get(`${config.endPoint.instructorLedNew}/notification/adminHomeCount`)
        .then((response) => {
          setNotificationCount(response.data.data.notification);
        })
        .catch((error) => {
          console.log("error while getting notification");
        });
    }
  }, [isILAdmin, isSuperAdmin]);

  const renderAdminCard = (current) => (
    <Link to={current.to} className="admin-dashboard-card">
      <Card style={{ position: "relative" }}>
        {current.title === "Notification" && notificationCount > 0 && (
          <div className={"admin-dashboard-card-notificiation-number"}>
            {notificationCount}
          </div>
        )}
        <Typography className="admin-dashboard-card-title">
          {current.title.toUpperCase()}
        </Typography>
        <Typography className="admin-dashboard-card-description">
          {current.description}
        </Typography>
      </Card>
    </Link>
  );

  return (
    <div className="dashboard-container">
      <Breadcrumb className="breadcumb" items={crumbs} />
      <Typography className="admin-page-title">Admin Dashboard</Typography>
      <div className="dashboard">
        {tiles
          .map((current) => renderAdminCard(current))
          .sort((a, b) => a.index - b.index)}
      </div>
    </div>
  );
}
