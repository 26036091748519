import React from "react";
import { Button, Col, Row, Typography } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { PlusCircleOutlined } from "@ant-design/icons";
import { AntDesignBreadCrumb } from "../../../../component-library";
import AntDesignSearchInput from "../../../../component-library/antDesignSearchInput/antDesignSearchInput";
import AntDesignPrimaryButton from "../../../../component-library/antDesignButton/PrimaryButton/primaryButton";
import CourseFilter from "../../../../component-library/antDesignCourseFilter/CourseFilter";
import CourseCard from "../../../../component-library/antDesignCourseCard/CourseCard";
import ScrollToTop from "../../../../component-library/scrollToTop/ScrollToTop";


import style from "./SelectCourseFromList.module.css";
import "./SelectCourseFromList.scss";
import useSelectCourseFromList from "./useSelectCourseFromList";



function SelectCourseFromList({
  handleClose,
  onELCourseSelect,
  onILCourseSelect,
  selectedELCourseNos,
  selectedILCourseNos,
  selectedContries,
  onlyPublicCourses,
  pathId,
}) {

  const { page, filter, filterItems, currentPage, handleCardClicked, handleFilter,
    handleSearch, crumbs, fetchMore, onAddSelectedCoursesToPath } = useSelectCourseFromList({
      onELCourseSelect, onILCourseSelect,
      selectedELCourseNos, selectedILCourseNos,
      selectedContries, onlyPublicCourses, pathId, handleClose
    })
  const TopNavigationBar = () => {
    return (
      <Row justify={"space-between"} align={"middle"} className={style.selectCourseHeader}>
        <Col>
          <Row align={"bottom"}>
            <Col>
              <Typography.Title
                style={{ fontFamily: "RakutenRoundRegular", display: "inline" }}
              >
                Select Courses &nbsp;
              </Typography.Title>
            </Col>
            <Col>
              <Typography.Text>
                ({page[filter].totalPages}
                courses)
              </Typography.Text>
            </Col>
          </Row>
        </Col>
        <Col>
          <CourseFilter
            filterItems={Object.keys(filterItems).map((item) => ({ label: item, value: item }))}
            handleFilter={handleFilter}
            filter={filter}
          ></CourseFilter>
        </Col>
        <Col>
          <Row gutter={16}>
            <Col>
              <AntDesignSearchInput
                placeHolder="Search"
                onSearch={handleSearch}
                value={currentPage.params.searchString || ""}
              ></AntDesignSearchInput>
            </Col>
            <Col>
              <Row gutter={8}>
                <Col>
                  <AntDesignPrimaryButton
                    text="ADD TO PATH"
                    icon={<PlusCircleOutlined />}
                    onClick={onAddSelectedCoursesToPath}
                    isDisabled={Object.values(page).reduce((acc, val) => (val.checkedPages.length + acc), 0) === 0}
                  ></AntDesignPrimaryButton>
                </Col>
                <Col>
                  <Button
                    type="button"
                    className="sc-cancel-button"
                    onClick={handleClose}
                  >
                    CANCEL
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row >
    )
  }
  return (
    <div className="sc-container">
      <ScrollToTop></ScrollToTop>
      <Row className={style.modal}>
        {/* breadcrumbs row */}
        <Col span={24}>
          <AntDesignBreadCrumb crumbs={crumbs} />
        </Col>
      </Row>
      <TopNavigationBar />

      <InfiniteScroll
        dataLength={currentPage.data?.length}
        next={fetchMore}
        hasMore={currentPage.hasMore}
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start",
        }}
      >
        <Row gutter={[24, 40]} style={{ width: "100%" }}>
          {currentPage.data && currentPage.data.length ? (
            currentPage.data.map((current, index) => {
              return (
                <Col span={6}>
                  <div key={current.id}>
                    <CourseCard
                      dataElement={current}
                      index={index}
                      cardClicked={handleCardClicked}
                      courseType={filter}
                      key={current.id}
                      isChecked={currentPage.checkedPages.filter(page => page.id === current.id).length > 0}
                    />
                  </div>
                </Col>
              );
            })
          ) : (
            <Col>
              No course available in your region for the category selected.
            </Col>
          )}
        </Row>
      </InfiniteScroll>
    </div>
  );
}

export default SelectCourseFromList;
